import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { getClaimsAction, getCopyrightsAction, getStrikesAction } from "../actions/mcnActions"

export const getClaims = createAsyncThunk('mcn/claims/get', getClaimsAction)
export const getStrikes = createAsyncThunk('mcn/strikes/get', getStrikesAction)
export const getCopyrights = createAsyncThunk('mcn/copyrights/get', getCopyrightsAction)

const initialState = {
    claims: {
        data: {},
        pagination: {},
        loading: false,
    },
    strikes: {
        data: {},
        pagination: {},
        loading: false,
    },
    copyrights: {
        data: {},
        pagination: {},
        loading: false,
    },
}

const mcnReducer = createReducer(initialState, {
    [getClaims.pending]: (state) => {
        state.claims.loading = true
    },
    [getClaims.fulfilled]: (state, action) => {
        const { page } = action.meta.arg
        const { claims, pagination } = action.payload.data

        state.claims.data[page] = claims
        state.claims.pagination = pagination
        state.claims.loading = false
    },
    [getClaims.rejected]: (state) => {
        state.claims.loading = false
    },

    [getStrikes.pending]: (state) => {
        state.strikes.loading = true
    },
    [getStrikes.fulfilled]: (state, action) => {
        const { page } = action.meta.arg
        const { strikes, pagination } = action.payload.data

        state.strikes.data[page] = strikes
        state.strikes.pagination = pagination
        state.strikes.loading = false
    },
    [getStrikes.rejected]: (state) => {
        state.strikes.loading = false
    },

    [getCopyrights.pending]: (state) => {
        state.copyrights.loading = true
    },
    [getCopyrights.fulfilled]: (state, action) => {
        const { page } = action.meta.arg
        const { copyrights, pagination } = action.payload.data

        state.copyrights.data[page] = copyrights
        state.copyrights.pagination = pagination
        state.copyrights.loading = false
    },
    [getCopyrights.rejected]: (state) => {
        state.copyrights.loading = false
    },
})

export default mcnReducer