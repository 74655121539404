import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ProgressBar = ({ min, max, extraValue, value, backgroundColor }) => {
    const { t } = useTranslation()
    const today = moment().format('DD MMM')
    return (
        <div
            className='progress-container with-extra-value with-legend'
            style={{ margin: 10 }}
        >
            <div className='progress-extra-value'>
                <div
                    className='progress-extra-value-before'
                    style={{ width: `${extraValue.raw}%` }}
                >
                    {extraValue.raw >= 50 && (
                        <span className='text-xsmall'>
                            {t('gm.progress_target_label', { date: today, amount: extraValue.value })}
                        </span>
                    )}
                </div>
                <div
                    className='progress-extra-value-after'
                    style={{ width: `${100 - extraValue.raw}%` }}
                >
                    {extraValue.raw < 50 && (
                        <span className='text-xsmall'>
                            {t('gm.progress_target_label', { date: today, amount: extraValue.value })}
                        </span>
                    )}
                </div>
            </div>

            <div className='progress z-depth-1'>
                <div
                    className='progress-before text-white'
                    style={{ width: `${Math.min(100,value.raw)}%`, backgroundColor }}
                >
						 {value.raw >= 50 && value.value}
                </div>
                <div
                    className='progress-after'
                    style={{ width: `${100 - value.raw}%` }}
                >
						 {value.raw < 50 && value.value}
                </div>
            </div>
            <div className='progress-legend'>
                <div className='legend-min text-small'>{min}</div>
                <div className='legend-max text-small'>{max}</div>
            </div>
        </div>
    )
}

export default ProgressBar
