import React, { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../UI/Loader'
import { getTasks } from '../../store/reducers/tasksReducer'
import { useTranslation } from 'react-i18next'
import TaskCard from './TaskCard'
import { Container, Row, Col } from '../Layout'
import Note from '../UI/Note'

const TasksView = ({ status }) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const {
        [status]: { data: tasks, loading },
    } = useSelector((state) => state.tasks)

    const load = () => {
        if (!tasks) {
            dispatch(getTasks(status))
        }
    }

    useLayoutEffect(load, [status])

    if (!tasks || loading) {
        return <Loader />
    }

    return (
        <Container>
            <Row spacing={2}>
                {!tasks.length ? (
                    <Col xs={12}>
                        <Note message={t('tasks.list.empty')} />
                    </Col>
                ) : (
                    tasks.map((task, index) => (
							   <Col key={index}  xs={12}>
                            <TaskCard
                                key={index}
                                redirect={true}
                                index={index + 1}
                                dealStatus={status}
                                task={task}
                            />
                        </Col>
                    ))
                )}
            </Row>
        </Container>
    )
}

export default TasksView
