import { API, handleError, handleResponse } from '../../api'

export const getClaimsAction = (payload, thunk) => {
    const { page, channelIds, status } = payload
    const data = { status, channelIds }
    return API.post(`mcn/claims.json?page=${page}`, data)
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
}

export const getStrikesAction = (payload, thunk) => {
    const { page, channelIds, status } = payload
    const data = { status, channelIds }
    return API.post(`mcn/strikes.json?page=${page}`, data)
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
}

export const getCopyrightsAction = (payload, thunk) => {
    const { page, channelIds, status } = payload
    const data = { status, channelIds }
    return API.post(`mcn/copyrights.json?page=${page}`, data)
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
}
