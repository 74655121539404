import React, { useState } from 'react'
import '@brainhubeu/react-carousel/lib/style.css'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import HomeCard from '../HomeCard'
import McnCard from './McnCard'
import CustomCarousel from '../../UI/CustomCarousel'
import { FaExclamationTriangle, FaShieldAlt, FaTimes } from 'react-icons/fa'
import { getTalentActivity } from '../../../helpers/talentHelper'

const McnCarousel = () => {
    const { t } = useTranslation()
    const { mcn } = useSelector((state) => state.badges)
    const [activeKey, setActiveKey] = useState(Object.keys(mcn)[0])

    const labels = {
        claims: t('home.claims'),
        strikes: t('home.strikes'),
        copyrights: t('home.copyrights'),
    }

    const icons = {
        claims: FaExclamationTriangle,
        strikes: FaTimes,
        copyrights: FaShieldAlt,
    }

    const onSlideChange = (index) => {
        setActiveKey(slides.keys[index])
    }

    const slides = {
        keys: [],
        components: [],
    }

    Object.entries(mcn).forEach(([key, data]) => {
        if (getTalentActivity(key)) {
            slides.keys.push(key)
            slides.components.push(<McnCard key={key} type={key} data={data} />)
        }
    })

    return (
        <HomeCard title={labels[activeKey]} icon={icons[activeKey]}>
            <CustomCarousel slides={slides.components} onSlideChange={onSlideChange}/>
        </HomeCard>
    )
}

export default McnCarousel
