import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { getAgendaDataAction } from '../actions/agendaActions'

export const getAgendaData = createAsyncThunk('agenda/get', getAgendaDataAction)

const initialState = {
    data: null,
    loading: false,
}

const agendaReducer = createReducer(initialState, {
    [getAgendaData.pending]: (state, action) => {
        
        state.loading = true
    },
    [getAgendaData.fulfilled]: (state, action) => {
        
        state.data = action.payload.data.days
        state.loading = false
    },
    [getAgendaData.rejected]: (state, action) => {
        
        state.loading = false
    },
})

export default agendaReducer
