import React from 'react'
import { useHistory } from 'react-router'

const CustomEvent = ({event}) => {
    const history = useHistory()

    const handleClick = () => {
        if (event.redirectPath) {
            history.push(event.redirectPath)
        }
    }

    return (
        <div
            onClick={handleClick}
            className={`clickable rbc-event-content ${event.color} lighten-1`}
            style={{ color: 'white', padding: 4, borderRadius: 5, fontSize: 14}}
            title={event.title}
        >
            {event.title}
        </div>
    )
}

export default CustomEvent
