import React, { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH, login } from '../../store/reducers/talentReducer'

import Loader from '../UI/Loader'

const AuthProvider = ({ children }) => {
	const dispatch = useDispatch()
	const { status } = useSelector(state => state.talent)

	const authenticate = () => {
		const storedTalent = JSON.parse(localStorage.getItem('talent'))

		// Fix erreur si l'API version a été modifiée
		const apiVersionIsValid =
			storedTalent?.version ===
			process.env.REACT_APP_API_VERSION.toString()

		if (storedTalent && apiVersionIsValid) {
			dispatch(login(storedTalent))
		}
	}

	useLayoutEffect(authenticate, [])

	if (status === AUTH.AUTHENTICATING) {
		return <Loader />
	}

	return <>{children}</>
}

export default AuthProvider
