import React from 'react'
import Select from 'react-select'

const CustomSelect = ({
    options,
    defaultValue,
    value,
    onChange,
    marginLeft = 'auto',
    fontSize = 14,
    full = false,
    disabled = false,
}) => {
    const padding = 10

    const getValue = (value) => {
        return options.find((item) => item.value === value) ?? options[0]
    }

    // Défini la width du select en fonction de l'item le plus long de la liste
    const getWidth = () => {
        const { label } = options.reduce((a, b) =>
            a.label.length > b.label.length ? a : b
        )
        return `${label.length * 8 + padding * 2 + 36}px`
    }

    return (
        <Select
            styles={{
                menuList: (provided, state) => {
                    return {
                        ...provided,
                        '::-webkit-scrollbar': {
                            width: 8,
                            height: 8,
                            backgroundColor: 'transparent',
                        },
                        '::-webkit-scrollbar-track': {
                            backgroundColor: '#eeeeee',
                        },
                        '::-webkit-scrollbar-thumb': {
                            borderRadius: 8,
                            border: '2px solid #eeeeee',
                            backgroundColor: '#bdbdbd',
                        },
                    }
                },
                container: (styles) => {
                    return {
                        ...styles,
                        zIndex: 1000,
                        marginLeft: full ? 0 : marginLeft,
                        width: full ? 'calc(100% - 16px)' : getWidth(),
                    }
                },
                singleValue: (styles) => {
                    return {
                        fontSize,
                        color: 'rgba(0, 0, 0, 0.71)',
                        ...styles,
                    }
                },
                option: (provided, state) => ({
                    ...provided,
                    fontSize,
                    fontWeight: state.isSelected ? '500' : 'normal',
                }),
            }}
            isDisabled={disabled || options.length <= 1}
            defaultValue={getValue(defaultValue)}
            value={getValue(value)}
            onChange={onChange}
            options={options}
        />
    )
}

export default CustomSelect
