import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Container } from '../components/Layout'
import Header from '../components/layout/Header'
import Thread from '../components/threads/Thread'
import Note from '../components/UI/Note'

const ChatPage = () => {
    const { t } = useTranslation()
    const { thread_id = null } = useSelector(state => state.talent.data)

    return (
        <>
            <Header title={t('pages.chat')} />
            <Container overflow='auto' height='100%'>
                {thread_id ? <Thread id={thread_id} /> : <Note type='error' message={t('error.generic')}/>}
            </Container>
        </>
    )
}

export default ChatPage
