import React, { useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableFooter } from '@material-ui/core'
import styled from 'styled-components'
import Note from './Note'
import { useTranslation } from 'react-i18next'

const align = (index, last) => {
    if (index === 0) return 'left'
    return 'center'
}

const Cell = ({ cell, index, onClick, isExpander, last }) => {
    let content = cell,
        style = {
            padding: '12px 14px',
            width: isExpander ? '1%' : 'auto',
            fontSize: 14,
            borderBottomWidth: 0,
        },
        colSpan = 1

    if (cell instanceof Object && 'value' in cell) {
        content = cell.value
        const cellStyle = cell.style ?? {}
        style = { ...style, ...cellStyle }
        colSpan = cell.colSpan ?? 1
    }

    if (isExpander) {
        style.cursor = 'pointer'
    }

    return (
        <TableCell
            style={style}
            colSpan={colSpan}
            align={align(index, last)}
            onClick={onClick}
        >
            {content}
        </TableCell>
    )
}

const Row = ({ row }) => {
    const { cells, style = {}, onClick, subComponent } = row
    const [open, setOpen] = useState(false)

    const cursor = 'onClick' in row ? 'pointer' : 'default'

    return (
        <>
            <TableRow
                style={{ borderBottom: 'none', cursor, ...style }}
                onClick={onClick}
            >
                {Object.entries(cells).map(([key, cell], index) => {
                    const isExpander = key === 'expander'
                    return (
                        <Cell
                            last={index === Object.keys(cells).length - 1}
                            isExpander={isExpander}
                            key={index}
                            cell={isExpander ? cell(open) : cell}
                            index={index}
                            onClick={isExpander ? () => setOpen(!open) : null}
                        />
                    )
                })}
            </TableRow>
            {open && subComponent}
        </>
    )
}

const TableHeader = ({ header }) => {
    const { backgroundColor = 'blue', title, subtitle, right } = header

    return (
        <div
            className={`${backgroundColor} darken-2`}
            style={{
                padding: 15,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <div>
                <span className='text-white'>{title}</span>
                {subtitle ? (
                    <span
                        className='text-small text-grey text-lighten-3'
                        style={{ marginLeft: 10 }}
                    >
                        {subtitle}
                    </span>
                ) : null}
            </div>
            {right ? (
                <span
                    style={{ fontSize: 18, fontWeight: 500 }}
                    className='text-white'
                >
                    {right}
                </span>
            ) : null}
        </div>
    )
}

const CustomTable = ({
    rows,
    columns = null,
    footer = null,
    canExpand = false,
    header = null,
    extraComponent = null,
}) => {
    const { t } = useTranslation()
    return useMemo(() => {
        if (!rows.length) return <Note message={t('common.no_results')} />
        return (
            <Paper style={{ overflow: 'hidden' }}>
                {header ? <TableHeader header={header} /> : null}
                <TableContainer className='mini-scroll'>
                    {extraComponent}
                    <Table>
                        {columns ? (
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => {
                                        const last =
                                            index === columns.length - 1
                                        return (
                                            <THeadCell
                                                align={align(index, last)}
                                            >
                                                {column}
                                            </THeadCell>
                                        )
                                    })}
                                    {canExpand && <TableCell></TableCell>}
                                </TableRow>
                            </TableHead>
                        ) : null}
                        <TableBody>
                            {rows.map((row, index) => (
                                <Row row={row} key={index} />
                            ))}
                        </TableBody>
                        {footer ? (
                            <TableFooter>
                                <TableRow>
                                    {footer.map((item, index) => {
                                        const last = index === footer.length - 1
                                        return (
                                            <TFootCell
                                                align={align(index, last)}
                                            >
                                                {item}
                                            </TFootCell>
                                        )
                                    })}
                                </TableRow>
                            </TableFooter>
                        ) : null}
                    </Table>
                </TableContainer>
            </Paper>
        )
    }, [rows])
}

export default CustomTable

const THeadCell = styled(TableCell)`
    font-weight: 500;
`

const TFootCell = styled(TableCell)`
    font-size: 14px;
    font-weight: 600;
    color: black;
`
