import React, {useLayoutEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {getFormattedRevenuesDetails} from '../../helpers/revenuesHelper'
import {getRevenuesDetails} from '../../store/reducers/revenuesReducer'
import Loader from '../UI/Loader'
import sanitize from "sanitize-filename";
import { saveAs } from "file-saver";
import {Button} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faCog, faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons'
import {Link} from "react-router-dom";
import {translate} from "../../i18n/i18n";
import moment from "moment";
import {formatCurrencies} from "../../helpers/numbersHelper";


const RevenuesDetails = ({month, id}) => {
	const dispatch = useDispatch()
	const {loading, details} = useSelector(
		(state) => state.revenues.data[month].zoho_accounts[id]
	)
	const {t} = useTranslation()
	const load = () => {
		if (!details) {
			dispatch(getRevenuesDetails({month, id}))
		}
	}

	useLayoutEffect(load, [])

	let content, buttons;

	if (loading || !details) {
		content = <Loader/>
	} else {
		const {zoho_account_id, zoho_account_name, amount, invoice_sent_date, invoice_status, operations = [] } = details;
		console.log(details);

		if (!operations.length ||
			(operations.length <= 1 && operations[0].amount === 0)) {
			content = (
				<div style={{
					padding: 10,
					textAlign: 'center',
					fontStyle: 'italic',
				}}>
					{t('revenues.empty')}
				</div>
			)
		} else {
			content = getFormattedRevenuesDetails(details)
		}

		const invoicePDF = invoice_sent_date ? process.env.REACT_APP_API_URL + 'talents/pdf/' + zoho_account_id + '/' + month : null;
		const invoiceName = sanitize( zoho_account_name + '_' + month );

		let downloadPDFButton = null;
		if ( invoicePDF ) {
			downloadPDFButton = (
				<Button 	variant="contained"
							color="primary"
							onClick={ () => { saveAs(invoicePDF, invoiceName) } }
							startIcon={ <FontAwesomeIcon icon={faFilePdf} /> }
							size="small"
				>
					{t('revenues.download_invoice')}
				</Button>
			);
		}
		let sendInvoiceButton = null;
		if ( invoice_status === 'waiting' ) {
			const subject = '[' + t('deals.invoicing') + '] ' + zoho_account_name + ' - ' + moment(month).format('MMMM YYYY');
			const body = t('revenues.send_invoice_body') + formatCurrencies(amount);

			sendInvoiceButton = (
				<Button 	variant="contained"
							 color="primary"
							 onClick={ () => { window.location.href='mailto:invoicing@wizdeo.com?subject=' + encodeURI( subject ) + '&body=' + encodeURI( body ) } }
							 startIcon={ <FontAwesomeIcon icon={faEnvelopeOpenText} /> }
							 size="small"
				>
					{t('revenues.send_invoice')}
				</Button>
			);
		}


		const route = '/settings/' + zoho_account_id;
		buttons = ( <center>
			{ downloadPDFButton }
			{ " " }
			{ sendInvoiceButton }
			{ " " }
			<Link to={ route }>
				<Button 	variant="contained"
							 startIcon={ <FontAwesomeIcon icon={faCog} size="xs"/> }
							 size="small"
				>
					{t('revenues.bank_settings')}
				</Button>
			</Link>
		</center>);
	}

	return (
		<tr>
			<td colSpan={3}>
				{ content }
				{ buttons }
			</td>
		</tr>
	)
}

export default RevenuesDetails
