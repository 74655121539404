import React from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { formatCurrencies, shorten } from '../../helpers/numbersHelper'
import { useHistory } from 'react-router-dom'
import ChannelCard from 'components/ChannelCard'
import styled from 'styled-components'

const ResultCard = ({ channel, displayCardContent }) => {
	const {
		subscribers,
		average_30d_future_views,
		cpm_min,
		cpm_max,
		sponsored_videos_count_3m,
		sponsored_videos_count,
	} = channel

	const { t } = useTranslation()
	const history = useHistory()

	const cardcontent = displayCardContent ? (
		<Typography component='div'>
			<KpisList>
				<div style={{ width: '50%' }}>
					<dl>
						<dt>{t('channel.kpis.subscribers')}</dt>
						<dd>{shorten(subscribers, 0)}</dd>
					</dl>

					<dl>
						<dt>{t('channel.kpis.sponsored_videos')}</dt>
						<dd style={{ lineHeight: '16px' }}>
							<>
								<KpiSubtitle>
									{t('channel.kpis.sponsored_videos_90d')}
								</KpiSubtitle>{' '}
								{shorten(
									sponsored_videos_count_3m
										? sponsored_videos_count_3m
										: 0,
									0
								)}
							</>
						</dd>
						<dd style={{ lineHeight: '16px' }}>
							<KpiSubtitle>
								{t('channel.kpis.sponsored_videos_total')}
							</KpiSubtitle>{' '}
							<span
								style={{
									fontSize: '0.7rem',
								}}
							>
								{shorten(sponsored_videos_count, 0)}
							</span>
						</dd>
					</dl>
				</div>

				<div style={{ width: '50%' }}>
					<dl>
						<dt>{t('channel.kpis.average_30d_future_views')}</dt>
						<dd>{shorten(average_30d_future_views, 0)}</dd>
					</dl>
					<dl>
						<dt>{t('channel.kpis.average_cpm')}</dt>
						<dd>{formatCurrencies((cpm_min + cpm_max) / 2)}</dd>
					</dl>
				</div>
			</KpisList>
		</Typography>
	) : null

	const handleClick = () => {
		history.push({
			pathname: `/analytics/search/${channel.id}`,
			state: { channel },
		})
	}

	return (
		<ChannelCard
			channel={channel}
			handleClick={handleClick}
			cardcontent={cardcontent}
			subheader={channel.handle ? '@'+channel.handle : null}
		/>
	)
}

const KpisList = styled.div`
	display: flex;
	text-align: right;
	justify-content: space-between;
`
const KpiSubtitle = styled.span`
	text-align: right;
	font-size: 10px;
	color: grey;
	font-style: italic;
`
export default ResultCard
