import React, { useMemo } from 'react'
import i18n, { translate } from '../../i18n/i18n'
import classes from './styles/LangSelect.module.scss'
import { FaCaretDown } from 'react-icons/fa'
import { useCookie } from 'react-use'

const LABELS = {
    fr: translate('lang.fr'),
    en: translate('lang.en'),
}

const LangSelect = () => {
    const [languageCookie, updateLanguageCookie] = useCookie('lang')
    const language = useMemo(() => LABELS[languageCookie], [])

    const handleClick = (language) => {
        updateLanguageCookie(language)
        window.location.reload()
    }

    const options = i18n.languages.map((language) => (
        <span key={language} className={classes.option} onClick={() => handleClick(language)}>
            {LABELS[language]}
        </span>
    ))

    return (
        <div className={classes.container}>
            <span className={classes.label}>
                {language}
            </span>
                <FaCaretDown className={classes.icon} />
            <div className={classes.options_container}>{options}</div>
        </div>
    )
}

export default LangSelect