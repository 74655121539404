import React, { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUnreadThreads } from '../store/reducers/threadsReducer'
import { getRevenues } from '../store/reducers/revenuesReducer'

import UnreadThreads from '../components/home/threads/UnreadThreads'
import ChannelsCarousel from '../components/home/channels/ChannelsCarousel'
import RoyaltiesCarousel from '../components/home/royalties/RoyaltiesCarousel'
import RevenuesCard from '../components/home/RevenuesCard'
import GmCard from '../components/home/GmCard'

import { getTalentActivity, TALENT_ACCESS_TYPE } from '../helpers/talentHelper'
import BrandContentCard from '../components/home/bc/BrandContentCard'
import { Col, Container, Row } from '../components/Layout'
import { Typography } from '@material-ui/core'
import { app_links } from '../helpers/authHelper'
import { useTranslation } from 'react-i18next'

import McnCarousel from '../components/home/mcn/McnCarousel'
import ContactUsCard from 'components/home/ContactUsCard'
import ServicesCarousel from 'components/home/ServicesCarousel'
import { getServices } from 'store/reducers/servicesReducer'

const HomePage = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const { badges } = useSelector(state => state)
	const { data: threads, loading: loadingThreads } = useSelector(
		state => state.threads.unread
	)
	const { data: revenues, loading: loadingRevenues } = useSelector(
		state => state.revenues
	)

	const services = useSelector(state => state.services)

	const {
		talentHasBC,
		talentHasChannels,
		talentHasRoyalties,
		talentHasMcn,
		talentHasGm,
		talentAccessType,
	} = getTalentActivity()

	const load = () => {
		if (!threads && !loadingThreads) {
			dispatch(getUnreadThreads())
		}

		if (!revenues.current && !loadingRevenues) {
			dispatch(getRevenues({ month: 'current' }))
		}

		if (!services.data.length && !services.loading) {
			dispatch(getServices({ lang: i18n.language }))
		}
	}

	useLayoutEffect(load, [])

	return (
		<Container>
			<Row spacing={2}>
				{talentHasBC && (
					<>
						{talentAccessType === TALENT_ACCESS_TYPE.ADVANCED && (
							<>
								<BrandContentCard
									type='sent'
									value={badges.deals.sent}
								/>
								<BrandContentCard
									type='approved'
									value={badges.deals.approved}
								/>
								<BrandContentCard
									type='in_progress'
									value={badges.deals.in_progress}
								/>
								<BrandContentCard
									type='tasks'
									value={badges.tasks.in_progress}
								/>
							</>
						)}
						<Col xs={12}>
							<UnreadThreads
								threads={threads}
								loading={loadingThreads}
							/>
						</Col>
					</>
				)}
				{talentHasChannels && (
					<Col
						xs={12}
						xl={
							talentAccessType === TALENT_ACCESS_TYPE.ADVANCED
								? 8
								: 12
						}
					>
						<ChannelsCarousel />
					</Col>
				)}
				{talentAccessType === TALENT_ACCESS_TYPE.ADVANCED && (
					<>
						{talentHasRoyalties && (
							<Col xs={12} md={6} xl={4}>
								<RoyaltiesCarousel />
							</Col>
						)}
						{talentHasMcn && (
							<Col xs={12} md={6} xl={4}>
								<McnCarousel />
							</Col>
						)}
						{talentHasGm && (
							<Col xs={12} md={6} xl={4}>
								<GmCard />
							</Col>
						)}
						<Col xs={12} md={6} xl={4}>
							<RevenuesCard
								revenues={revenues.current}
								loading={loadingRevenues}
							/>
						</Col>
					</>
				)}
				<Col xs={12}>
					<ServicesCarousel
						services={services.data}
						isLoading={services.loading}
					/>
				</Col>
				<Col xs={12}>
					<ContactUsCard />
				</Col>
				<Col xs={12}>
					<Typography
						variant='caption'
						align='center'
						display='block'
					>
						{t('common.mobile_app_links')}
						<a target='_blank' href={app_links.android}>
							{' '}
							Android
						</a>{' '}
						-
						<a target='_blank' href={app_links.ios}>
							{' '}
							iOS
						</a>
					</Typography>
				</Col>
			</Row>
		</Container>
	)
}

export default HomePage
