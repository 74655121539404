import moment from 'moment'
import { useTranslation } from 'react-i18next'

const DateDisplay = ({ date }) => {
	const { t } = useTranslation()

	let today = moment()
	let displayedDate = null

	if (today.diff(moment(date), 'days') <= 7) {
		displayedDate = moment(date).fromNow()
		displayedDate =
			displayedDate[0].toLocaleUpperCase() + displayedDate.slice(1)
	} else {
		displayedDate = t('common.date_on') + ' ' + moment(date).format('L')
	}

	return displayedDate
}

export default DateDisplay
