import React, { useState } from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Avatar from '../../components/UI/Avatar'
import Loader from '../../components/UI/Loader'
import { getBrandYoutubeDashboard } from '../../store/reducers/brandsReducer'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import BrandYoutubeDashboard from 'components/brands/BrandYoutubeDashboard'
import { Container, Header } from 'components/Layout'
import badgeWizdeo from '../../assets/img/badge_wizdeo.jpg'
import { FaYoutube, FaTwitch } from 'react-icons/fa'
import BrandTwitchDashboard from 'components/brands/BrandTwitchDashboard'

const BrandPage = ({ location }) => {
	const { id, name, logo, wizdeo_sponsored_videos_2y } = location.state.brand
	const [displayedContent, setDisplayedContent] =
		useState('youtube_dashboard')

	const dispatch = useDispatch()
	const { youtube_dashboard } = useSelector(state => state.brands.brands)

	const [headerBgColor, setHeaderBgColor] = useState('#aa181e')

	const { t } = useTranslation()

	const load = () => {
		if (!youtube_dashboard.details[id] && !youtube_dashboard.loading) {
			dispatch(getBrandYoutubeDashboard({ brandId: id }))
		}
	}

	const headerTitle = (
		<OptionWrapper>
			<Avatar size={50} src={logo} />
			<Title $light>
				{name}
				{wizdeo_sponsored_videos_2y ? (
					<sup>
						<Tooltip
							title={t('brand.wizdeo_client')}
							placement='left'
							arrow
						>
							<img
								src={badgeWizdeo}
								style={{ marginLeft: 5 }}
								alt={t('brand.wizdeo_client')}
								height={16}
								width={16}
							/>
						</Tooltip>
					</sup>
				) : null}
			</Title>
		</OptionWrapper>
	)

	const handleTabChange = (color, page) => {
		setHeaderBgColor(color)
		setDisplayedContent(page)
	}

	const handlePageChange = page => {
		setDisplayedContent(page)
	}

	React.useLayoutEffect(load, [id])

	return (
		<>
			<Header title={headerTitle} bgColor={headerBgColor} />
			<Tabs>
				<TabListCustom $bgColor={headerBgColor}>
					<Tab
						value='youtube'
						onClick={() =>
							handleTabChange('#aa181e', 'youtube_dashboard')
						}
					>
						<TabIconTitle>
							<FaYoutube />
							<span>{t('brand.tabs.youtube')}</span>
						</TabIconTitle>
					</Tab>
					<Tab
						value='twitch'
						onClick={() =>
							handleTabChange('#6441a4', 'twitch_dashboard')
						}
					>
						<TabIconTitle>
							<FaTwitch />
							<span>{t('brand.tabs.twitch')}</span>
						</TabIconTitle>
					</Tab>
				</TabListCustom>
				<Container>
					<TabPanel value='youtube'>
						{youtube_dashboard.loading ||
						!youtube_dashboard.details[id] ? (
							<Loader />
						) : (
							<BrandYoutubeDashboard
								brandId={id}
								stats={youtube_dashboard.details[id].stats}
								series={youtube_dashboard.details[id].series}
								channels={
									youtube_dashboard.details[id].channels
								}
								videos={youtube_dashboard.details[id].videos}
								displayedContent={displayedContent}
								handlePageChange={handlePageChange}
							/>
						)}
					</TabPanel>
					<TabPanel value='twitch'>
						<BrandTwitchDashboard
							brandId={id}
							displayedContent={displayedContent}
							handlePageChange={handlePageChange}
						/>
					</TabPanel>
				</Container>
			</Tabs>
		</>
	)
}

export default BrandPage

const Title = styled(Typography)`
	padding: 0 12px;
	font-size: ${props => (props.small ? 18 : 24)}px !important;
	font-weight: 500;
	${props => (props.$light ? 'color: white;' : null)};
`

const OptionWrapper = styled.div`
	display: flex;
	align-items: center;
`

const TabListCustom = styled(TabList)`
	background-color: ${props => (props.$bgColor ? props.$bgColor : '#1976D2')};
	margin: 0;
	padding: 0;
	transition: 0.5s;

	@media (max-width: 450px) {
		display: flex;
		justify-content: space-around;

		& .react-tabs__tab {
			width: 100%;
		}
	}
`
const TabIconTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;

	@media (max-width: 450px) {
		justify-content: center;

		& span {
			display: none;
		}
	}
`
