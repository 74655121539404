import { API, handleError, handleResponse } from "../../api"
import { getDeal } from "../reducers/dealsReducer"
import { getTask, getTasks } from "../reducers/tasksReducer"

export const getTasksAction = (payload, thunk) => {
    const params = {
        tasksStatus: payload
    }

    return API.get('tasks/index.json', {params})
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}

export const getTaskAction = (payload, thunk) => {
    return API.get(`tasks/view/${payload}.json`)
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}

export const manageTaskAction = (payload, thunk) => {
    return API.post(`tasks/taskManagement.json`, payload)
        .then(res => handleResponse(res, thunk))
        .then(() => {
            thunk.dispatch(getTask(payload.taskId))
        })
        .then(() => {
            thunk.dispatch(getDeal({ id: payload.dealId }))
        })
        .then(() => {
            thunk.dispatch(getTasks('in_progress'))
        })
        .then(() => {
            thunk.dispatch(getTasks('checking'))
        })
        .catch(handleError)
}