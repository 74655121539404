import moment from 'moment'
import ClaimStatusBadge from '../components/claims/ClaimStatusBadge'
import Avatar from '../components/UI/Avatar'
import VideoInfos from '../components/UI/VideoInfos'
import { translate } from '../i18n/i18n'
import Strike from '../model/Strike'

const TYPES_LABELS = {
    audio: translate('claims.types.audio'),
    video: translate('claims.types.video'),
    audiovisual: translate('claims.types.audiovisual'),
    composition: translate('claims.types.composition'),
    mixed: translate('claims.types.mixed'),
}

const POLICIES_LABELS = {
    monitor: translate('claims.policies.monitor'),
    monetize: translate('claims.policies.monetize'),
    block: translate('claims.policies.block'),
    share: translate('claims.policies.share'),
}

export const getFormattedCopyrights = (copyrights, channel) => {
    let columns = [
        translate('copyrights.table.video'),
        translate('copyrights.table.reference_name'),
        translate('copyrights.table.yt_reference'),
        translate('copyrights.table.type'),
        translate('copyrights.table.created_at'),
        translate('copyrights.table.pirate_name'),
        translate('copyrights.table.pirate_video'),
    ]

    const rows = copyrights.map(copyright => ({
        cells: {
            video: copyright.video_title,
            ref: copyright.reference_video_type,
            yt_ref: copyright.yt_reference,
            type: copyright.claim_type,
            created_at: copyright.right_date,
            pirate_name: copyright.pirate_name,
            pirate_video: copyright.url_pirate_video,
        }
    }))

    return { columns, rows }
}

export const getFormattedStrikes = (strikes, channel, handleClick) => {
    let rows = []

    let columns = [
        translate('strikes.table.video'),
        translate('strikes.table.type'),
        translate('strikes.table.nature'),
        translate('strikes.table.date'),
        translate('strikes.table.solve_date'),
        translate('strikes.table.solve_type'),
        translate('strikes.table.solve_length'),
        translate('strikes.table.not_negociable'),
        translate('strikes.table.status'),
    ]


    for (let strike of strikes) {
        const _strike = new Strike(strike)
        console.log(`_strike.status`, _strike.status())

        rows.push({
            cells: {
                video: _strike.video,
                type: _strike.type,
                nature: _strike.nature,
                strike_date: _strike.date,
                solve_date: _strike.solve_date,
                solve_type: _strike.solve_type,
                solve_length: _strike.solve_length,
                negociable: _strike.negociable,
                status: _strike.status()
            }
        })
    }
    return { rows, columns }
}

export const getFormattedClaims = (claims, channels, handleClick) => {
    let columns = [
        translate('claims.table.video'),
        translate('claims.table.channel'),
        translate('claims.table.type'),
        translate('claims.table.policy'),
        translate('claims.table.date'),
        translate('claims.table.solve_date'),
        translate('claims.table.solve_length'),
        translate('claims.table.status'),
    ]

    let rows = []

    const backgroundColors = {
        in_progress: '#ffe0b2 ',
        accepted: '#c8e6c9 ',
        rejected: '#f8c1c3 ',
    }

    for (const claim of claims) {
        const {
            action_date,
            video_title,
            channel_id,
            action_status,
            yt_id,
            solve_date,
            type,
            revendication_policy,
            video_thumbnail
        } = claim


        const channel = channels.find((channel) => channel.id === channel_id)

        const solveLength = moment(solve_date).diff(moment(action_date), 'days')
        const solveLengthLabel = `${solveLength} ${translate('common.day')}${
            solveLength > 1 && 's'
        }`
        rows.push({
            style: {
                backgroundColor: backgroundColors[action_status],
                color: 'white',
            },
            cells: {
                video_title: <VideoInfos video={{
                    title: video_title,
                    url: `https://youtu.be/${yt_id}`,
                    thumbnail: video_thumbnail,
                }}/>,
                channel: (
                    <Avatar
                        size='small'
                        src={channel.logo}
                        label={channel.display_name}
                    />
                ),
                type: TYPES_LABELS[type],
                revendication_policy: POLICIES_LABELS[revendication_policy],
                date: moment(action_date).format('LL'),
                solve_date: solve_date ? moment(solve_date).format('LL') : '-',
                solve_length: solve_date ? solveLengthLabel : '-',
                status: <ClaimStatusBadge status={action_status} />,
            },
        })
    }

    return { rows, columns }
}
