import React from 'react'
import {MdMenu} from 'react-icons/md'
import {useHistory} from 'react-router'
import headerLogo from '../../assets/img/header-logo.png'
import styles from './styles/Navbar.module.scss'
import LangSelect from './LangSelect'

const Navbar = ({handleToggle}) => {
	const history = useHistory()

	const handleLogoClick = () => {
		history.push('/home')
	}

	return (
		<nav className={styles.navbar}>
			<img
				alt="WizApp Logo"
				onClick={handleLogoClick}
				src={headerLogo}
				className={styles.logo}
			/>
			<div className={styles.btn_toggle} onClick={handleToggle}>
				<MdMenu size={32} className={styles.icon}/>
			</div>
			<div className={styles.lang_select}>
				<LangSelect/>
			</div>
		</nav>
	)
}

export default Navbar
