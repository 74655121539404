import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useUpdateEffect } from 'react-use'
import { Col, Container, Row } from '../components/Layout'
import Header from '../components/layout/Header'
import RoyaltiesView from '../components/royalties/RoyaltiesView'
import { getRoyaltiesTabLabels } from '../helpers/royaltiesHelper'

const RoyaltiesPage = () => {
    const { royalties } = useSelector((state) => state.badges)
    const {
        state: { index },
    } = useLocation()
    const { t } = useTranslation()
    const [selectedIndex, setSelectedIndex] = useState(index)
    const [statuses, setStatuses] = useState(
        Object.keys(royalties).map((key) => 'all')
    )
    const [pages, setPages] = useState(Object.keys(royalties).map((key) => 1))

    useUpdateEffect(() => {
        setSelectedIndex(index)
    }, [index])

    const tabLabels = getRoyaltiesTabLabels()

    const handleStatusChange = ({ value }) => {
        let newStatuses = [...statuses]
        newStatuses[selectedIndex] = value
        setStatuses(newStatuses)
    }

    const handlePageChange = (event, value) => {
        let newPages = [...pages]
        newPages[selectedIndex] = value
        setPages(newPages)
    }

    const handlePageReset = () => {
        let newPages = [...pages]
        newPages[selectedIndex] = 1
        setPages(newPages)
    }

    return (
        <>
            <Header title={t('pages.royalties')} />
            <Tabs
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndex(index)}
            >
                <TabList>
                    {Object.keys(royalties).map((key) => (
                        <Tab key={key}>{tabLabels[key]}</Tab>
                    ))}
                </TabList>
                <Container>
                    {Object.keys(royalties).map((key) => (
                        <TabPanel key={key}>
                            <Row spacing={3}>
                                <Col xs={12}>
                                    <RoyaltiesView
                                        status={statuses[selectedIndex]}
                                        page={pages[selectedIndex]}
                                        onPageChange={handlePageChange}
                                        onStatusChange={handleStatusChange}
                                        resetPage={handlePageReset}
                                        type={key}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                    ))}
                </Container>
            </Tabs>
        </>
    )
}

export default RoyaltiesPage
