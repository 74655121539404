import React from 'react'
import { FaShareAlt } from 'react-icons/fa'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ShareWithBrand = ({ id, displayName }) => {
	const { t } = useTranslation()

	const handleShare = () => {
		window.location = `mailto:${
			process.env.REACT_APP_WIZDEO_EMAIL
		}?subject=${t('talents.cta.share_with_brand_email_subject')}&body=${
			t('talents.cta.share_with_brand_email_body') +
			' ' +
			displayName +
			' (#' +
			id +
			') ' +
			' :'
		}`
	}

	return <ShareIcon color='white' size='25' onClick={handleShare} />
}

export default ShareWithBrand

const ShareIcon = styled(FaShareAlt)`
	cursor: pointer;
`
