import moment from 'moment'
import {
    FaCheckCircle,
    FaExclamationTriangle,
    FaHourglass,
    FaPlayCircle,
} from 'react-icons/fa'

export const getTaskCardData = (task, dealStatus, redirect) => {
    let dealInProgress = false
    const taskInProgress = task.status === 'in_progress'

    if (dealStatus) {
        dealInProgress = dealStatus === 'in_progress' || dealStatus === 'checking'
    } else {
        dealInProgress = task._matchingData.Deals.status === 'in_progress' || task._matchingData.Deals.status === 'checking'
    }

    const expectedDate = getExpectedDate(task)
    const status = getTaskStatus(task, dealInProgress, redirect)

    return {
        taskInProgress,
        dealInProgress,
        expectedDate,
        status,
    }
}

const getExpectedDate = (task) => {
    if (task.expected_realization_date) {
        return moment(task.expected_realization_date).format('LL')
    }
    return null
}

const getTaskStatus = (task, dealInProgress, redirect) => {
    let label = 'Terminée',
        icon = <FaCheckCircle />,
        color = 'green darken-2 text-white'

    if (dealInProgress) {
        if (task.status === 'in_progress') {
            label = 'En cours'
            color = 'cyan darken-2 text-white'
            icon = <FaPlayCircle />

            if (task.expected_realization_date) {
                const reminderDate = moment(
                    task.expected_realization_date
                ).subtract(task.reminder_days_count, 'days')
                if (moment(task.expected_realization_date).isBefore()) {
                    color = 'red lighten-1 text-white'
                    label = 'En retard'
                    icon = <FaExclamationTriangle />
                } else if (reminderDate.isBefore()) {
                    color = 'orange lighten-1 text-white'
                }
            }
        } else if (task.status === 'checking') {
            label = 'Vérification'
            color = 'green lighten-1 text-white'
            icon = <FaHourglass />
        }
    }

    return { label, icon, color }
}
