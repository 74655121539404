import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const McnCard = ({ data, type }) => {
    const history = useHistory()
    const { t } = useTranslation()

    const handleClick = () => {
        history.push(`/${type}`)
    }

    const LABELS = {
        titles: {
            claims: t('claims.title'),
            strikes: t('strikes.title'),
            copyrights: t('copyrights.title'),
        },
        claims: {
            accepted: t('claims.status.accepted_plural'),
            rejected: t('claims.status.rejected_plural'),
            in_progress: t('claims.status.in_progress_plural'),
        },
        strikes: {
            in_progress: t('strikes.status.in_progress'),
            resolved: t('strikes.status.resolved_plural'),
            received: t('strikes.status.received_plural'),
        },
        copyrights: {
            all: t('copyrights.label'),
        },
    }

    return (
        <Container>
            <Wrapper onClick={handleClick}>
                {Object.entries(LABELS[type]).map(([key, label]) => (
                    <Item>
                        <Label>{label}</Label>
                        <Value>
                            {data instanceof Object ? data[key] || 0 : data}
                        </Value>
                    </Item>
                ))}
            </Wrapper>
        </Container>
    )
}

export default McnCard

const Container = styled.div`
    width: 100%;
    padding: 12px 12px 0;
`

const Wrapper = styled.div`
    cursor: pointer;
`

const Item = styled.dl`
    padding: 8px;
    text-align: right;
`

const Label = styled.dt`
    font-size: 16px;
`

const Value = styled.dd`
    font-size: 18px;
    font-weight: 500;
`
