import React, { useState } from 'react'
import Carousel, { Dots, infinite, autoplay } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import './styles/CustomCarousel.scss'

const CustomCarousel = ({ slides, onSlideChange, auto, infinite }) => {
	const [value, setValue] = useState(0)

	const handleChange = index => {
		if (onSlideChange) onSlideChange(index)
		setValue(index)
	}

	let activePlugins = []
	if (auto) activePlugins.push('autoplay')
	if (infinite) activePlugins.push('infinite')

	return (
		<>
			<Carousel
				plugins={activePlugins}
				slides={slides}
				value={value}
				onChange={handleChange}
			/>
			{slides.length > 1 ? (
				<Dots
					className='dots'
					value={value}
					onChange={handleChange}
					number={slides.length}
				/>
			) : null}
		</>
	)
}

export default CustomCarousel
