import React from 'react'
import { useSelector } from 'react-redux'
import classes from './styles/TalentSection.module.scss'
import emptyImg from 'assets/img/empty-img.png'

const TalentSection = () => {
	const { data: talent } = useSelector(state => state.talent)

	return (
		<div className='sidebar-header'>
			<img
				className={classes.logo}
				src={talent.pic_url}
				alt=''
				onError={e => {
					e.target.onerror = null
					e.target.src = emptyImg
				}}
			/>
			<div className={classes.infos_container}>
				<span className={classes.fullname}>
					{talent.firstname} {talent.lastname}
				</span>
				<span title={talent.email} className={classes.email}>
					{talent.email}
				</span>
			</div>
		</div>
	)
}

export default TalentSection
