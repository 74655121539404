import React from 'react'
import { IconButton, Typography, useMediaQuery } from '@material-ui/core'
import Modal from 'react-modal'
import { Row } from '../Layout'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components'

const CustomModal = ({
    title,
    isOpen,
    onRequestClose,
    description,
    actions,
    children,
}) => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))

    const style = {
        overlay: {
            zIndex: 10000,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
        },
        content: {
            maxWidth: isSmallScreen ? '90%' : '50%',
            alignSelf: 'center',
            padding: 16,
            position: 'relative',
            inset: 0,
            border: 0,
            overflow: 'visible',
        },
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={style}>
            <Row justifyContent='space-between' alignItems='center' flexWrap='nowrap'>
                <Title variant='h5'>{title}</Title>
                <CloseButton onClick={onRequestClose}>
                    <MdClose />
                </CloseButton>
            </Row>
            {Boolean(description) && (
                <Description variant='subtitle2'>{description}</Description>
            )}
            <Content>{children}</Content>
            {Boolean(actions) && <Row justifyContent='flex-end'>{actions}</Row>}
        </Modal>
    )
}

export default CustomModal

const CloseButton = styled(IconButton)`
    margin-left: 12px;
`

const Title = styled(Typography)`
    font-weight: 600;
`
const Description = styled(Typography)`
    margin-top: 12px;
`

const Content = styled.div`
    margin: 24px 0;
`
