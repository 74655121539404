import React from 'react'
import Copyright from '../../model/Copyright'
import {
    FaBan,
    FaDollarSign,
    FaExclamation,
    FaExclamationTriangle,
    FaQuestion,
} from 'react-icons/fa'
import { IconButton, Tooltip } from '@material-ui/core'
import styled from 'styled-components'

const CopyrightTypeIcon = ({ type }) => {
    let Icon,
        color = 'red'

    switch (type) {
        case Copyright.CLAIM_TYPE_STRIKE:
            Icon = FaExclamationTriangle
            break
        case Copyright.CLAIM_TYPE_BLOCK:
            Icon = FaBan
            break
        case Copyright.CLAIM_TYPE_MONETIZE:
            Icon = FaDollarSign
            color = 'green'
            break
        case Copyright.CLAIM_TYPE_NON_COMPLIANT:
            Icon = FaExclamation
            break
        case Copyright.CLAIM_TYPE_UNKNOWN:
            Icon = FaQuestion
            color = 'red'
            break
        case Copyright.CLAIM_TYPE_NONE:
        default:
            Icon = null
    }

    if (!Icon) return '-'

    return (
        <StyledTooltip arrow title={Copyright.LABELS.claim_types[type]}>
            <IconButton disableRipple disableTouchRipple>
                <Icon color={color} />
            </IconButton>
        </StyledTooltip>
    )
}

export default CopyrightTypeIcon

const StyledTooltip = styled(Tooltip)`
    cursor: default;
`
