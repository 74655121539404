import {
	combineReducers,
	configureStore,
	getDefaultMiddleware,
} from '@reduxjs/toolkit'

import agendaReducer from './reducers/agendaReducer'
import analyticsReducer from './reducers/analyticsReducer'
import brandsReducer from './reducers/brandsReducer'
import badgesReducer from './reducers/badgesReducer'
import claimsReducer from './reducers/claimsReducer'
import dealsReducer from './reducers/dealsReducer'
import gmReducer from './reducers/gmReducer'
import mcnReducer from './reducers/mcnReducer'
import revenuesReducer from './reducers/revenuesReducer'
import royaltiesReducer from './reducers/royaltiesReducer'
import talentReducer from './reducers/talentReducer'
import tasksReducer from './reducers/tasksReducer'
import threadsReducer from './reducers/threadsReducer'
import servicesReducer from './reducers/servicesReducer'

const appReducer = combineReducers({
	agenda: agendaReducer,
	analytics: analyticsReducer,
	brands: brandsReducer,
	badges: badgesReducer,
	claims: claimsReducer,
	deals: dealsReducer,
	gm: gmReducer,
	mcn: mcnReducer,
	revenues: revenuesReducer,
	royalties: royaltiesReducer,
	talent: talentReducer,
	tasks: tasksReducer,
	threads: threadsReducer,
	services: servicesReducer,
})

const rootReducer = (state, action) => {
	// Reset tout le store quand le talent se déconnecte
	if (action.type === 'talent/logout') {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV === 'development',
	middleware: getDefaultMiddleware({
		serializableCheck: false,
	}),
})
