import React from 'react'
import { useHistory } from 'react-router'
import toast, { Toaster } from 'react-hot-toast'

export const CustomToaster = () => {
    return (
        <Toaster
            position='top-right'
            toastOptions={{
                duration: 4000,
            }}
        />
    )
}

export const Notification = ({ icon, title, body, redirectPath }) => {
    const history = useHistory()

    const handleClick = () => {
        if (redirectPath) {
            history.push(redirectPath)
        }
        toast.dismiss()
    }

    return (
        <div style={styles.container} onClick={handleClick}>
            <div style={styles.title}>
                {icon}
                <span style={styles.titleText}>{title}</span>
            </div>
            <div style={styles.body}>
                {body}
            </div>
        </div>
    )
}

const styles = {
    container: {
        maxWidth: 360,
        minWidth: 280,
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 6,
    },
    titleText: {
        paddingLeft: 8
    },
    body: {
        fontSize: 16,
    },
}
