import { createAction, createReducer } from '@reduxjs/toolkit'

export const updateBadges = createAction('badges/update')
export const resetBadges = createAction('badges/reset')

const initialState = {
    gm: null,
    claims: null,
    mcn: {
        claims: null,
        copyrights: null,
        strikes: null,
    },
    deals: {
        sent: 0,
        approved: 0,
        in_progress: 0,
        done: 0,
    },
    tasks: {
        in_progress: 0,
    },
    royalties: null,
}

const badgesReducer = createReducer(initialState, {
    [updateBadges]: (state, action) => {
        const {
            newDeals: sent,
            inProgressDeals: in_progress,
            precastingDeals: approved,
            finishedDeals: done,
            gm,
            royalties,
            claims,
            mcn,
            inProgressTasks,
        } = action.payload

        return {
            deals: { sent, in_progress, approved, done },
            tasks: { in_progress: inProgressTasks },
            royalties,
            claims,
            mcn,
            gm,
        }
    },
    [resetBadges]: () => initialState,
})

export default badgesReducer
