import React from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { useDispatch, useSelector } from 'react-redux'
import { manageDeal } from '../../../store/reducers/dealsReducer'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { useCookie } from 'react-use'

const AcceptModal = ({ deal, isOpen, onRequestClose }) => {
   const dispatch = useDispatch()
	const { t } = useTranslation()
	const { trackEvent, pushInstruction } = useMatomo()
	const { data: talent } = useSelector((state) => state.talent)
	const [languageCookie] = useCookie('lang')

	const accept = () => {
		if (window.confirm(t('deals.modal.accept.prompt'))) {
			const payload = {
					dealAccept: true,
					dealId: deal.id,
					previousStatus: deal.status,
					newStatus: 'approved',
			}

			dispatch(manageDeal(payload))

			// Story #2550 Matomo integration
			let event = {
				category: 'Brand Content',
				action: 'Deal accepted',
				name: deal.campaign.title,
				value: deal.id,
				customDimensions: [
					{
						id: 1,
						value: languageCookie
					},
					{
						id: 2,
						value : process.env.REACT_APP_VERSION
					}
				]
			}

			pushInstruction('setUserId', talent.id)
			trackEvent(event)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={t('deals.modal.accept.title', { campaign: deal?.campaign?.title })}
			description={t('deals.modal.accept.description')}
			actions={
					<Button color='primary' variant='contained' onClick={accept}>
						{t('deals.accept')}
					</Button>
			}
		/>
	)
}

export default AcceptModal
