import firebase from 'firebase/app'
import 'firebase/messaging'
import { handleNotification } from './helpers/notificationsHelper'

const firebaseConfig = {
    apiKey: 'AIzaSyBMlZ-2M35baZe_-XTR9LnNx2VPkGtiidw',
    authDomain: 'wizdeoapptalent.firebaseapp.com',
    databaseURL: 'https://wizdeoapptalent.firebaseio.com',
    projectId: 'wizdeoapptalent',
    storageBucket: 'wizdeoapptalent.appspot.com',
    messagingSenderId: '664568568718',
    appId: '1:664568568718:web:58c79b089b4931ff3a62df',
    measurementId: 'G-Z38PMDCJZ8',
}

firebase.initializeApp(firebaseConfig)

const messaging = firebase.messaging()

export const getToken = () => {
    const vapidKey =
        'BKMJ0Cb4tkgM06rJkoo4qRD0Sb5EDk37GDqCo02d4eEMCG7HMuA1KBJnTrZn6q7RiBPAEjylSRjQRPDr-1V5vb4'

    return messaging
        .getToken({ vapidKey })
        .then((token) => {
            console.log(`Firebase token : `, token)
            return token
        })
        .catch((err) => console.log(`err`, err))
}

export const onMessageListener = () => messaging.onMessage(handleNotification)
