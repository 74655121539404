import React from 'react'
import {
    Avatar,
    Collapse,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FaChevronDown, FaChevronUp, FaEnvelope } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import CustomBadge from '../../UI/CustomBadge'
import Loader from '../../UI/Loader'
import HomeCard from '../HomeCard'

const THREADS_LIMIT = 5

const UnreadThreads = ({ threads, loading }) => {
    const [expanded, setExpanded] = React.useState(false)
    const { t } = useTranslation()
    const history = useHistory()
    const { thread_id } = useSelector(state => state.talent.data)

    const handleClick = thread => {
        if (thread_id === thread.id) history.push('/chat')
        else history.push(`/deals/view/${thread.deal.id}`)
    }

    let content

    if (loading) {
        content = <Loader />
    } else if (!threads || !threads.length) {
        content = (
            <List>
                <ListItem>
                    <ListEmptyText>
                        {t('threads.new_messages.empty')}
                    </ListEmptyText>
                </ListItem>
            </List>
        )
    } else {
        const mapThreads = (thread, index) => (
            <ListItem
                button
                alignItems='center'
                key={index}
                onClick={() => handleClick(thread)}
            >
                {Boolean(thread.campaign?.banner_url) && (
                    <ListItemAvatar>
                        <Avatar src={thread.campaign?.banner_url} />
                    </ListItemAvatar>
                )}
                <ListItemText
                    primary={thread.title}
                    secondary={thread.last_message.message}
                    secondaryTypographyProps={{ noWrap: true }}
                />
                <StyledListItemIcon>
                    <CustomBadge value={thread.new_messages} />
                </StyledListItemIcon>
            </ListItem>
        )

        const visibleThreads = [...threads]
        const hiddenThreads = visibleThreads.splice(THREADS_LIMIT)

        content = (
            <List>
                {visibleThreads.map(mapThreads)}
                {!expanded && hiddenThreads.length > 0 && (
                    <ListItem
                        button
                        onClick={() => setExpanded(true)}
                        style={{ justifyContent: 'center' }}
                    >
                        <FaChevronDown />
                    </ListItem>
                )}
                {hiddenThreads.length > 0 && (
                    <Collapse in={expanded}>
                        <List disablePadding>
                            {hiddenThreads.map(mapThreads)}
                            <ListItem
                                button
                                onClick={() => setExpanded(false)}
                                style={{ justifyContent: 'center' }}
                            >
                                <FaChevronUp />
                            </ListItem>
                        </List>
                    </Collapse>
                )}
            </List>
        )
    }

    return (
        <HomeCard title={t('home.new_messages')} icon={FaEnvelope}>
            {content}
        </HomeCard>
    )
}

export default UnreadThreads

const StyledListItemIcon = styled(ListItemIcon)`
    justify-content: flex-end;
`

const ListEmptyText = styled(ListItemText)`
    text-align: center;
`
