export const getCookie = (key = null, defaultValue = '') => {
    if (!document.cookie) return defaultValue
    
    const cookies = document.cookie
        .split(';')
        .map(v => v.split('='))
        .reduce((acc, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
                v[1].trim()
            )
            return acc
        }, {})

    if (key) {
        if (key in cookies) return cookies[key]
        else return defaultValue
    }

    return cookies
}
