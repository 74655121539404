import React from 'react'
import { capitalize } from '../../helpers/stringHelper'
import CustomButton from '../UI/CustomButton'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const CustomToolbar = ({ label, onNavigate }) => {
    const { t } = useTranslation()
    const navigate = {
        previous: 'PREV',
        next: 'NEXT',
        today: 'TODAY',
    }

    const handleClick = (type) => {
        onNavigate(navigate[type])
    }

    return (
        <div
            className='calendar-toolbar'
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
            }}
        >
            <div>
                <CustomButton
                    icon={FaChevronLeft}
                    handleClick={() => handleClick('previous')}
                />
                <span className='text-bold' style={{ fontSize: 16 }}>
                    {capitalize(label)}
                </span>
                <CustomButton
                    icon={FaChevronRight}
                    handleClick={() => handleClick('next')}
                />
            </div>
                <CustomButton
                    title={t('common.today')}
                    handleClick={() => handleClick('today')}
                />
        </div>
    )
}

export default CustomToolbar
