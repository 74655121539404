import React from 'react'
import marked from 'marked'
import styled from 'styled-components'

const Markdown = ({ children }) => {
    const getMarkdown = () => {
        const content = marked(children, { output: 'unicode' })
        return { __html: content }
    }

    return (
        <Wrapper dangerouslySetInnerHTML={getMarkdown()}/>
    )
}

export default Markdown

const Wrapper = styled.div`
    pre code {
        white-space: normal;
    }
    p {
        margin: 0;
    }
`