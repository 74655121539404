import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaChartLine } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getGmProgress } from '../../helpers/gmHelper'
import { formatCurrencies } from '../../helpers/numbersHelper'
import CustomButton from '../UI/CustomButton'
import Note from '../UI/Note'
import HomeCard from './HomeCard'

const GmCard = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const { gm } = useSelector((state) => state.badges)

    let content = null

    if (gm?.contracts?.length) {
        content = getGmProgress({ contracts: gm.contracts })
    } else if (gm?.gmPotential) {
        const amount = formatCurrencies(gm.gmPotential * 12, 'EUR')
        content = (
            <Note
                message={t('home.gm_potential', { amount })}
                type='regular'
                hideIcon
                fontSize={14}
                textAlign='center'
            />
        )
    }

    if (!content) return null

    const handleClick = () => history.push('/gm/summary')

    return (
        <HomeCard
            title={t('pages.gm')}
            icon={FaChartLine}
            actions={
                <CustomButton
                    title={t('common.more')}
                    handleClick={handleClick}
                />
            }
            justifyContent='center'
        >
            {content}
        </HomeCard>
    )
}

export default GmCard
