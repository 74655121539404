import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import { Col, Container, Row } from 'components/Layout'
import Loader from 'components/UI/Loader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ChannelDemoChart from './ChannelDemoChart'
import ChannelGeo from './ChannelGeo'
import SocialNetWorkCard from './SocialNetworkCard'
import CustomButton from 'components/UI/CustomButton'

const ChannelGeneral = ({ isLoading, data, talentOwnsChannel }) => {
	const { t } = useTranslation()

	const socialNetworks = [
		'youtube',
		'facebook',
		'instagram',
		'twitter',
		'twitch',
	]

	const handleCustomizeDescription = () => {
		window.location = `mailto:${
			process.env.REACT_APP_WIZDEO_EMAIL
		}?subject=${t(
			'channel.cta.customize_description_email_subject'
		)}&body=${
			t('channel.cta.customize_description_email_body') +
			' ' +
			data.display_name +
			' (#' +
			data.id +
			') ' +
			t('common.to') +
			' :'
		}`
	}

	return isLoading ? (
		<Loader />
	) : (
		<Container>
			{/* Description only for channels linked to user Zoho accounts */}
			{talentOwnsChannel && (
				<>
					<SectionTitle>
						{t('channel.sections.description')}
					</SectionTitle>
					<Card className='mb-20'>
						<CardContent
							dangerouslySetInnerHTML={{
								__html: data?.description,
							}}
						/>
						<Actions>
							<CustomButton
								title={t('channel.cta.customize_description')}
								handleClick={() => handleCustomizeDescription()}
							/>
						</Actions>
					</Card>
				</>
			)}

			{/* Social networks */}
			{data?.social_networks && (
				<>
					<SectionTitle>
						{t('channel.sections.social_networks')}
					</SectionTitle>
					<SocialNetWorksContainer className='mb-20'>
						{socialNetworks.map(name => (
							<SocialNetWorkCard
								key={name}
								name={name}
								data={data.social_networks[name]}
							/>
						))}
					</SocialNetWorksContainer>
				</>
			)}

			{/* Demo and geo */}
			<Row spacing={2}>
				<Col xs={12} xl={9}>
					<SectionTitle>
						{t('channel.sections.demography')}
					</SectionTitle>
					<ChannelDemoChart data={data?.demographies} />
				</Col>
				<Col xs={12} xl={3}>
					<SectionTitle>
						{t('channel.sections.geography')}
					</SectionTitle>
					<ChannelGeo data={data?.geographies} />
				</Col>
			</Row>
		</Container>
	)
}

export default ChannelGeneral

const SectionTitle = styled(Typography)`
	font-weight: bold !important;
	display: flex;
	align-items: center;
	min-height: 40px;
`

const SocialNetWorksContainer = styled.div`
	display: flex;
	justify-content: start;
	flex-wrap: nowrap;
	@media (max-width: 1100px) {
		flex-wrap: wrap;
	}
`

const Actions = styled(CardActions)`
	justify-content: flex-end;
`
