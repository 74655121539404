import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomBadge from '../UI/CustomBadge'

const ClaimStatusBadge = ({ status }) => {
    const { t } = useTranslation()
    let color, label

    switch (status) {
        case 'accepted':
            color = '#4caf50'
            label = t('claims.status.accepted')
            break
        case 'rejected':
            color = '#f44336'
            label = t('claims.status.rejected')
            break
        case 'in_progress':
            color = '#ffc107'
            label = t('claims.status.in_progress')
            break
		 default:
    }

    return (
        <CustomBadge value={label} backgroundColor={color} />
    )
}

export default ClaimStatusBadge
