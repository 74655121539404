import moment from 'moment'
import i18n, { translate } from '../i18n/i18n'
import {
    formatCurrencies,
    formatMultipleCurrencies,
    formatPercentage,
} from './numbersHelper'
import ProgressBar from '../components/UI/ProgressBar'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

export const getContractName = contract => {
    const start = moment(contract.contract_start_date).format('LL')
    const end = moment(contract.contract_end_date).format('LL')
    return i18n.t('gm.contract_name', { start, end })
}

export const getFormattedGmData = (data, history, year) => {
    const formattedData = {
        contracts: formatGmContracts(data),
        yearRecap: {
            header: {
                title: translate('gm.annual_recap'),
            },
            rows: formatGmYear(data, history, year),
        },
        monthsRecap: {
            header: { title: translate('gm.monthly_recap') },
            columns: [
                '',
                translate('gm.inbox_deals'),
                translate('gm.wizdeo_deals'),
                translate('gm.gm_deals'),
            ],
            footer: [
                translate('gm.monthly_average'),
                formatMultipleCurrencies(data.monthlyAverages.inbox),
                formatMultipleCurrencies(data.monthlyAverages.wizdeo),
                formatMultipleCurrencies(data.monthlyAverages.gm),
            ],
            rows: formatGmMonths(data, year),
        },
    }

    return formattedData
}

export const getGmProgressColor = ({ target, done }) => {
    if (done >= target) return 'green'

    if (done >= parseInt(target, 10) / 2) return 'orange'

    return 'red'
}

export const getGmProgress = (data, index = 0) => {
    const { percentageToDate, contract_end_date, contract_start_date } =
        data.contracts[index]

    return (
        <ProgressBar
            backgroundColor={getGmProgressColor(percentageToDate)}
            value={formatPercentage(percentageToDate.done, true)}
            extraValue={formatPercentage(percentageToDate.target, true)}
            min={moment(contract_start_date).format('LL')}
            max={moment(contract_end_date).format('LL')}
        />
    )
}

const formatGmContracts = data => {
    return data.contracts.map(contract => ({
        header: {
            title: translate('gm.contract_data', {
                start: moment(contract.contract_start_date).format('LL'),
                end: moment(contract.contract_end_date).format('LL'),
            }),
        },
        columns: ['', translate('gm.target'), translate('gm.done')],
        rows: [
            {
                cells: [
                    translate('gm.annual_amount'),
                    formatCurrencies(contract.annualAmount.target),
                    formatCurrencies(contract.annualAmount.done),
                ],
            },
            {
                cells: [
                    translate('gm.to_date'),
                    formatCurrencies(contract.amountToDate.target),
                    formatCurrencies(contract.amountToDate.done),
                ],
            },
            {
                cells: [
                    translate('gm.to_date_percent'),
                    formatPercentage(contract.percentageToDate.target).value,
                    <span
                        className={`text-bold text-${getGmProgressColor(
                            contract.percentageToDate
                        )}`}
                    >
                        {formatPercentage(contract.percentageToDate.done).value}
                    </span>,
                ],
            },
        ],
    }))
}

const MonthLink = ({ value, type, year, month }) => {
    const history = useHistory()
    const handleClick = () =>
        history.push(`/gm/deals?month=${month}&type=${type}&year=${year}`)
    return (
        <Button size='small' onClick={handleClick}>
            <div>{value}</div>
        </Button>
    )
}

const formatGmMonths = (data, year) => {
    return Object.entries(data.monthly).map(([key, value]) => {
        let inbox = formatCurrencies(),
            wizdeo = formatCurrencies(),
            gm = formatCurrencies()

        if (key in data.monthly) {
            if (value.inbox)
                inbox = (
                    <MonthLink
                        value={formatMultipleCurrencies(value.inbox)}
                        type='inbox'
                        year={year}
                        month={key - 1}
                    />
                )
            if (value.wizdeo)
                wizdeo = (
                    <MonthLink
                        value={formatMultipleCurrencies(value.wizdeo)}
                        type='wizdeo'
                        year={year}
                        month={key - 1}
                    />
                )
            if (value.gm)
                gm = (
                    <MonthLink
                        value={formatMultipleCurrencies(value.gm)}
                        type='gm'
                        year={year}
                        month={key - 1}
                    />
                )
        }

        return {
            cells: [moment.months()[key - 1], inbox, wizdeo, gm],
        }
    })
}

const formatGmYear = (data, history, year) => [
    {
        cells: [
            translate('gm.inbox_deals'),
            formatMultipleCurrencies(data.inboxDeals),
        ],
        onClick: () =>
            history.push(`/gm/deals?month=all&type=inbox&year=${year}`),
    },
    {
        cells: [
            translate('gm.wizdeo_deals'),
            formatMultipleCurrencies(data.wizdeoDeals),
        ],
        onClick: () =>
            history.push(`/gm/deals?month=all&type=wizdeo&year=${year}`),
    },
    {
        cells: [translate('gm.gm_deals'), formatMultipleCurrencies(data.gmDeals)],
        onClick: () => history.push(`/gm/deals?month=all&type=gm&year=${year}`),
    },
]
