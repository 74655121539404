import moment from 'moment'
import { translate } from '../i18n/i18n'

export default class Copyright {
    constructor(copyright) {
        this.video = {
            url: copyright.url_reference_video,
            thumbnail: copyright.video_thumbnail,
            title: copyright.video_title,
        }
        this.ref = copyright.external_reference_name || copyright.yt_reference
        this.type = copyright.claim_type
        this.date = moment(copyright.right_date).format('LL')
        this.pirate_name = copyright.pirate_name
        this.pirate_video = copyright.url_pirate_video
    }

    static REFERENCE_VIDEO_TYPE_URL = 'url'
    static REFERENCE_VIDEO_TYPE_COPYRIGHT = 'copyright'
    static REFERENCE_VIDEO_TYPE_VIRTUAL = 'virtual'

    static CLAIM_TYPE_MONETIZE = 'monetize'
    static CLAIM_TYPE_BLOCK = 'block'
    static CLAIM_TYPE_STRIKE = 'strike'
    static CLAIM_TYPE_NON_COMPLIANT = 'non-compliant'
    static CLAIM_TYPE_NONE = 'none'
    static CLAIM_TYPE_UNKNOWN = 'unknown'

    static LABELS = {
        ref_types: {
            [this.REFERENCE_VIDEO_TYPE_URL]: translate('copyrights.ref_types.url'),
            [this.REFERENCE_VIDEO_TYPE_COPYRIGHT]: translate('copyrights.ref_types.copyright'),
            [this.REFERENCE_VIDEO_TYPE_VIRTUAL]: translate('copyrights.ref_types.virtual'),
        },
        claim_types: {
            [this.CLAIM_TYPE_MONETIZE]: translate('copyrights.claim_types.monetize'),
            [this.CLAIM_TYPE_BLOCK]: translate('copyrights.claim_types.block'),
            [this.CLAIM_TYPE_STRIKE]: translate('copyrights.claim_types.strike'),
            [this.CLAIM_TYPE_NON_COMPLIANT]: translate('copyrights.claim_types.non_compliant'),
            [this.CLAIM_TYPE_NONE]: translate('copyrights.claim_types.none'),
            [this.CLAIM_TYPE_UNKNOWN]: translate('copyrights.claim_types.unknown'),
        }

    }

    static COLUMNS = [
        translate('copyrights.table.video'),
        translate('copyrights.table.yt_reference'),
        translate('copyrights.table.type'),
        translate('copyrights.table.date'),
        translate('copyrights.table.pirate_name'),
        translate('copyrights.table.pirate_video'),
    ]
}
