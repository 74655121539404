import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { useTitle } from 'react-use'
import { AUTH } from '../../store/reducers/talentReducer'
import Loader from '../UI/Loader'

const CustomComponent = ({ component: Component, authStatus, title, ...props }) => {
    useTitle(title)

    if (authStatus === AUTH.AUTHENTICATING) return <Loader />

    if (authStatus === AUTH.UNAUTHORIZED) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: {
                        redirect: props.location,
                    },
                }}
            />
        )
    }

    return <Component {...props} />
}

const PublicRoute = ({ component, title = 'WizApp', ...rest }) => {
    const { status } = useSelector((state) => state.talent)

    return (
        <Route {...rest}>
            {(props) => (
                <CustomComponent
                    authStatus={status}
                    component={component}
                    title={title}
                    {...props}
                />
            )}
        </Route>
    )
}

export default PublicRoute
