import React from 'react'
import { useTranslation } from 'react-i18next'

const NoData = ({ icon }) => {
	const { t } = useTranslation()

	return (
		<div
			style={{
				color: 'grey',
				textAlign: 'center',
				fontStyle: 'italic',
				fontSize: '0.7em',
				width: '100%',
			}}
		>
			<div style={{ fontSize: '3rem', color: 'grey' }}>
				{icon ?? icon}
			</div>
			{t('common.no_data')}
		</div>
	)
}

export default NoData
