import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { FaCheck, FaSearch, FaTimesCircle } from 'react-icons/fa'
import getVideoId from 'get-video-id'

import { useDispatch } from 'react-redux'
import { manageTask } from '../../store/reducers/tasksReducer'

import CustomButton from '../UI/CustomButton'
import ReactPlayer from 'react-player'
import { getVideoMetadata } from '../../helpers/videosHelper'

const customStyles = {
    overlay: {
        zIndex: 10000,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        maxHeight: '80%',
        minWidth: '50%',
        alignSelf: 'center',
        padding: 0,
        position: 'relative',
        inset: 0,
        border: 0,
    },
}

Modal.setAppElement('#root')

const TaskCompletionModal = ({ task, isOpen, toggle }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [comment, setComment] = useState('')
    const [youtubeUrl, setYoutubeUrl] = useState('')
    const [video, setVideo] = useState(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (youtubeUrl.trim() === '') {
            setVideo(null)
            setError(false)
        }
    }, [youtubeUrl])

    const handleCommentChange = (e) => {
        setComment(e.target.value)
    }

    const handleUrlChange = (e) => {
        setYoutubeUrl(e.target.value)
    }

    const handleSubmit = () => {
        dispatch(
            manageTask({
                dealId: task.deal_id,
                taskId: task.id,
                taskComments: comment,
                ytId: video ? video.id : null,
            })
        )
        toggle(false)
    }

    const onRequestClose = () => toggle(false)

    const getUrlStatus = () => {
        let dataMessage = null,
            urlStatus = ''

        if (error && !video) {
            urlStatus = 'error'
            dataMessage = t('tasks.modal.invalid_url')
        }
        if (video && !error) {
            urlStatus = 'success'
            dataMessage = t('tasks.modal.valid_url')
        }

        return { dataMessage, urlStatus }
    }

    const validateUrl = async () => {
        const youtubeRegex =
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/g
        const id = youtubeRegex.test(youtubeUrl)
            ? getVideoId(youtubeUrl).id
            : youtubeUrl

        if (id) {
            const metadata = await getVideoMetadata(id)

            if (metadata) {
                setVideo(metadata)
                setError(null)
            } else {
                setError(true)
                setVideo(null)
            }
        } else {
            setError(true)
            setVideo(null)
        }
    }

    const submitDisabled = task.youtube_publication_required && !video

    const { dataMessage, urlStatus } = getUrlStatus()

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
        >
            <header
                className='modal-header'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h3>{t('tasks.modal.title')}</h3>
                <FaTimesCircle
                    className='clickable'
                    size={30}
                    onClick={onRequestClose}
                />
            </header>
            <div
                className='modal-content mini-scroll'
                style={{ overflow: 'auto', height: '80%' }}
            >
                <p>{t('tasks.modal.description')}</p>
                <form
                    className='material flat'
                    onSubmit={(e) => e.preventDefault()}
                >
                    <div className='input-field input-text'>
                        <label htmlFor='comment'>
                            {t('tasks.modal.comment_label')}
                        </label>
                        <textarea
                            className='task-input'
                            placeholder={t('tasks.modal.comment_placeholder')}
                            name='comment'
                            id='comment'
                            rows='3'
                            value={comment}
                            onChange={handleCommentChange}
                        />
                    </div>
                    {task.youtube_publication_required && (
                        <div
                            className={`input-field input-text ${urlStatus}`}
                            data-message={dataMessage}
                        >
                            <label htmlFor='url'>
                                {t('tasks.modal.link_label')} <span style={{ color: '#d32f2f'}}>*</span>
                            </label>
                            <input
                                className='task-input'
                                placeholder={t('tasks.modal.link_placeholder')}
                                name='url'
                                id='url'
                                value={youtubeUrl}
                                onChange={handleUrlChange}
                            />
                        </div>
                    )}
                </form>
                {video ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '10px 0px',
                        }}
                    >
                        <ReactPlayer
                            height={(500 * 9) / 16}
                            width={500}
                            url={video.url}
                        />
                    </div>
                ) : null}
                <div className='modal-footer'>
                    {!video && task.youtube_publication_required ? (
                        <CustomButton
                            disabled={!youtubeUrl}
                            title={t('tasks.modal.check_url')}
                            handleClick={validateUrl}
                            icon={FaSearch}
                        />
                    ) : (
                        <div></div>
                    )}
                    <CustomButton
                        disabled={submitDisabled}
                        title={t('common.send')}
                        handleClick={() => handleSubmit(comment)}
                        icon={FaCheck}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default TaskCompletionModal
