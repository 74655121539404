import React, { useMemo } from 'react'
import { Pagination } from '@material-ui/lab'
import styled from 'styled-components'

const Paginator = ({ count, page, onPageChange }) => {
    return useMemo(
        () => (
            <StyledPagination
                size='small'
                siblingCount={0}
                shape='rounded'
                count={count}
                page={parseInt(page)}
                onChange={onPageChange}
            />
        ),
        [page, count]
    )
}

export default Paginator

const StyledPagination = styled(Pagination)`
    margin: 10px 0px 10px 0px;
`
