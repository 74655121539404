import { Grid } from '@material-ui/core'
import ResultBrandCard from 'components/brands/ResultBrandCard'
import { Container, Header } from 'components/Layout'
import CustomSelect from 'components/UI/CustomSelect'
import Loader from 'components/UI/Loader'
import Note from 'components/UI/Note'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { suggestedBrands } from 'store/reducers/brandsReducer'
import { getChannelsList } from 'helpers/talentHelper'

const BrandSuggested = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const channelsList = getChannelsList()
	const [current, setCurrent] = useState(channelsList[0].value)

	const { results, loading } = useSelector(
		state => state.brands.suggestedBrands
	)

	// Lancer une recherche
	const load = (force = false) => {
		if ((!results[channelsList[0].value] || force) && !loading) {
			dispatch(suggestedBrands({ id: channelsList[0].value }))
		}
	}

	const handleChange = id => {
		setCurrent(id)

		if(!results[id])
			dispatch(suggestedBrands({ id: id }))
	}

	// Vérifie au chargement de la page si il y a un mot clé et lance une recherche si nécessaire
	React.useLayoutEffect(() => {
		load(true)
	}, [])

	return (
		<>
			<Header title={t('brand.titles.suggested')} />
			<Container>
				<CustomSelect
					options={channelsList}
					value={current}
					onChange={item => handleChange(item.value)}
				/>
			</Container>
			{loading ? (
				<Loader />
			) : (
				<Container>
					{results.length > 0 && results[current] ? (
						<Grid
							container
							spacing={2}
							direction='row'
							justifyContent='flex-start'
							alignItems={'stretch'}
						>
							{results[current].map(result => (
								<Grid
									item
									style={{ display: 'flex' }}
									key={result.id}
								>
									<ResultBrandCard
										brand={result}
										potentialRoi
									/>
								</Grid>
							))}
						</Grid>
					) : (
						<Note message={t('brand.no_suggested')} />
					)}
				</Container>
			)}
		</>
	)
}

export default BrandSuggested
