import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'

const Header = ({ title, subtitle }) => {
    const screenIsSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

    return (
        <Wrapper small={screenIsSmall}>
            <Title small={screenIsSmall}>{title}</Title>
            {subtitle ? <Subtitle small={screenIsSmall}> {subtitle}</Subtitle> : null}
        </Wrapper>
    )
}

export default Header

const Wrapper = styled.header`
    padding: ${props => props.small ? 24 : 32}px;
`

const Title = styled.span`
    font-size: ${props => props.small ? 24 : 32}px;
    color: white;
    `

const Subtitle = styled.span`
    font-size: ${props => props.small ? 16 : 24}px;
    color: #d9d9d9;
`