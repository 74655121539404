import { shorten } from '../../helpers/numbersHelper'
import { Tooltip, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import BrandChart from './BrandChart'
import { FaQuestionCircle, FaYoutube } from 'react-icons/fa'
import ChannelsCarousel from 'components/ChannelsCarousel'
import { Container } from 'components/Layout'
import VideosCarousel from 'components/analytics/VideosCarousel'
import CustomButton from 'components/UI/CustomButton'
import BrandYoutubeChannels from './BrandYoutubeChannels'
import BrandYoutubeVideos from './BrandYoutubeVideos'

const BrandYoutubeDashboard = ({
	brandId,
	stats,
	series,
	channels,
	videos,
	displayedContent,
	handlePageChange,
}) => {
	const { t } = useTranslation()

	return (
		<>
			{displayedContent === 'youtube_dashboard' ? (
				<>
					<div className='card' style={{ marginBottom: 0 }}>
						<YoutubeIcon />
						<div className='bc-card-content card-content'>
							<div className='text-right text-large'>
								{t('brand.titles.youtube_sponsoring')}
							</div>
							<div className='text-right text-italic text-small mt-5'>
								{t('brand.titles.last_3months')}
							</div>
							<KpisWrapper>
								<KpiContainer>
									<KpiLabel>
										{t(
											'brand.kpis.influencer_channels_count_3m'
										)}{' '}
										<Tooltip
											title={t(
												'brand.kpis.influencer_channels_count_3m_tooltip'
											)}
											arrow
										>
											<sup>
												<FaQuestionCircle size='0.8em' />
											</sup>
										</Tooltip>
									</KpiLabel>
									<KpiValue>
										{stats.influencer_channels_count_3m
											? shorten(
													stats.influencer_channels_count_3m,
													1
											  )
											: '-'}
									</KpiValue>
								</KpiContainer>

								<KpiContainer>
									<KpiLabel>
										{t(
											'brand.kpis.influencer_videos_count_3m'
										)}{' '}
										<Tooltip
											title={t(
												'brand.kpis.influencer_videos_count_3m_tooltip'
											)}
											arrow
										>
											<sup>
												<FaQuestionCircle size='0.8em' />
											</sup>
										</Tooltip>
									</KpiLabel>
									<KpiValue>
										{stats.influencer_videos_count_3m
											? shorten(
													stats.influencer_videos_count_3m,
													1
											  )
											: '-'}
									</KpiValue>
								</KpiContainer>

								<KpiContainer>
									<KpiLabel>
										{t('brand.kpis.influencer_pricing_3m')}{' '}
										<Tooltip
											title={t(
												'brand.kpis.influencer_pricing_3m_tooltip'
											)}
											arrow
										>
											<sup>
												<FaQuestionCircle size='0.8em' />
											</sup>
										</Tooltip>
									</KpiLabel>
									<KpiValue>
										{stats.youtube_mention_pricing_90d
											? shorten(
													stats.youtube_mention_pricing_90d,
													1
											  )
											: '-'}{' '}
										€
									</KpiValue>
								</KpiContainer>
							</KpisWrapper>
						</div>

						<BrandChart series={series} />
					</div>

					<div
						className='mt-20'
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<SectionTitle>
							{t('brand.sections.sponsored_youtube_channels')}
						</SectionTitle>

						<CustomButton
							type='primary'
							title={t('common.see_more')}
							handleClick={() =>
								handlePageChange('youtube_channels')
							}
						/>
					</div>
					<ChannelsCarousel channels={channels} />

					<div
						className='mt-20'
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<SectionTitle>
							{t('brand.sections.sponsored_youtube_videos')}
						</SectionTitle>

						<CustomButton
							type='primary'
							title={t('common.see_more')}
							handleClick={() =>
								handlePageChange('youtube_videos')
							}
						/>
					</div>
					<VideosCarousel videos={videos} displayChannel />
				</>
			) : displayedContent === 'youtube_channels' ? (
				<BrandYoutubeChannels
					id={brandId}
					handleBackToDetails={handlePageChange}
				/>
			) : (
				<BrandYoutubeVideos
					id={brandId}
					handleBackToDetails={handlePageChange}
				/>
			)}
		</>
	)
}

export default BrandYoutubeDashboard

const KpisWrapper = styled.div`
	display: flex;
	margin-left: 110px;
	margin-top: 12px;

	@media (max-width: 531px) {
		margin-left: 0;
		margin-top: 30px;
		white-space: nowrap;
		flex-wrap: wrap;
	}
`

const KpiContainer = styled.div`
	flex-grow: 1;
	width: 33%;
	display: flex;
	flex-direction: column;

	@media (max-width: 531px) {
		width: 50%;
	}
`
const KpiLabel = styled.div`
	flex-grow: 1;
	text-align: right;
	font-size: 0.8rem;
`

const KpiValue = styled.div`
	text-align: right;
	font-weight: 400;
	font-size: 1.4rem;
`

const YoutubeIcon = styled(FaYoutube)`
	color: #aa181e;
	position: absolute;
	top: -35px;
	z-index: 0;
	left: -20px;
	font-size: 8em;
`

const SectionTitle = styled(Typography)`
	font-weight: bold !important;
	display: flex;
	align-items: center;
	min-height: 40px;
`
