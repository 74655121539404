import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaDollarSign } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { formatCurrencies } from '../../helpers/numbersHelper'
import CustomButton from '../UI/CustomButton'
import Loader from '../UI/Loader'
import HomeCard from './HomeCard'
import classes from './styles/RevenuesCard.module.scss'

const RevenuesCard = ({ revenues, loading }) => {
    const history = useHistory()
    const { t } = useTranslation()

    const handleClick = () => {
        history.push('/revenues')
    }

    let content

    if (!revenues || loading) {
        content = <Loader />
    } else {
        const currentMonth = moment().format('YYYY-MM')
        const pastMonth = moment().subtract(1, 'month').format('YYYY-MM')
        const currentAmount = revenues[currentMonth]
            ? revenues[currentMonth].amount
            : 0
        const pastAmount = revenues[pastMonth] ? revenues[pastMonth].amount : 0
        content = (
            <>
                <dl className={classes.month}>
                    <dt className={classes.label}>{t('revenues.previous_month')}</dt>
                    <dd className={classes.value}>
                        {formatCurrencies(pastAmount)}
                    </dd>
                </dl>
                <dl className={classes.month}>
                    <dt className={classes.label}>{t('revenues.current_month')}</dt>
                    <dd className={classes.value}>
                        {formatCurrencies(currentAmount)}
                    </dd>
                </dl>
            </>
        )
    }

    return (
        <HomeCard
            title={t('home.revenues')}
            icon={FaDollarSign}
            actions={<CustomButton title={t('common.more')} handleClick={handleClick} />}
        >
            <div className={classes.container}>{content}</div>
        </HomeCard>
    )
}

export default RevenuesCard
