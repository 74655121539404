import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import {
	getBrandYoutubeDashboardAction,
	getBrandYouTubeChannelsAction,
	getBrandYouTubeVideosAction,
	getBrandTwitchDashboardAction,
	getBrandTwitchChannelsAction,
	getBrandTwitchSessionsAction,
	searchBrandsAction,
	suggestedBrandsAction,
} from '../actions/brandsActions'

export const getBrandYoutubeDashboard = createAsyncThunk(
	'brands/youtube_dashboard',
	getBrandYoutubeDashboardAction
)

export const getBrandYouTubeChannels = createAsyncThunk(
	'brands/youtube_channels',
	getBrandYouTubeChannelsAction
)

export const getBrandYouTubeVideos = createAsyncThunk(
	'brands/youtube_videos',
	getBrandYouTubeVideosAction
)

export const getBrandTwitchDashboard = createAsyncThunk(
	'brands/twitch_dashboard',
	getBrandTwitchDashboardAction
)

export const getBrandTwitchChannels = createAsyncThunk(
	'brands/twitch_channels',
	getBrandTwitchChannelsAction
)

export const getBrandTwitchSessions = createAsyncThunk(
	'brands/twitch_sessions',
	getBrandTwitchSessionsAction
)

export const searchBrands = createAsyncThunk(
	'brands/search',
	searchBrandsAction
)

export const suggestedBrands = createAsyncThunk(
	'brands/suggested',
	suggestedBrandsAction
)

const initialState = {
	brands: {
		youtube_dashboard: {
			loading: false,
			details: {},
		},
		youtube_channels: {
			brandId: null,
			loading: false,
			pagination: {
				page: null,
				nextPage: null,
				pageCount: null,
			},
			results: {},
		},
		youtube_videos: {
			brandId: null,
			loading: false,
			pagination: {
				page: null,
				nextPage: null,
				pageCount: null,
			},
			results: {},
		},
		twitch_dashboard: {
			loading: false,
			details: {},
		},
		twitch_channels: {
			brandId: null,
			loading: false,
			pagination: {
				page: null,
				nextPage: null,
				pageCount: null,
			},
			results: {},
		},
		twitch_sessions: {
			brandId: null,
			loading: false,
			pagination: {
				page: null,
				nextPage: null,
				pageCount: null,
			},
			results: {},
		},
	},
	searchBrands: {
		loading: false,
		pagination: {
			page: null,
			nextPage: null,
			pageCount: null,
		},
		results: {},
	},
	suggestedBrands: {
		loading: false,
		current: null,
		results: [],
	},
}

const brandsReducer = createReducer(initialState, {
	// Brand YouTube dashboard
	[getBrandYoutubeDashboard.pending]: state => {
		state.brands.youtube_dashboard.loading = true

		state.brands.youtube_channels.brandId = null
		state.brands.youtube_channels.results = {}
		state.brands.youtube_channels.pagination = {
			page: null,
			pageCount: null,
			nextPage: null,
		}

		state.brands.youtube_videos.brandId = null
		state.brands.youtube_videos.results = {}
		state.brands.youtube_videos.pagination = {
			page: null,
			pageCount: null,
			nextPage: null,
		}

		state.brands.twitch_channels.brandId = null
		state.brands.twitch_channels.results = {}
		state.brands.twitch_channels.pagination = {
			page: null,
			pageCount: null,
			nextPage: null,
		}
	},
	[getBrandYoutubeDashboard.fulfilled]: (state, action) => {
		const { brandId } = action.meta.arg
		state.brands.youtube_dashboard.details[brandId] =
			action.payload.data.details
		state.brands.youtube_dashboard.loading = false
	},
	[getBrandYoutubeDashboard.rejected]: state => {
		state.brands.youtube_dashboard.loading = false
	},

	// Brand YouTube channels
	[getBrandYouTubeChannels.pending]: state => {
		state.brands.youtube_channels.loading = true
	},
	[getBrandYouTubeChannels.fulfilled]: (state, action) => {
		const { page } = action.meta.arg
		const { brand_id, channels, page_count, next_page } =
			action.payload.data

		if (page === 1) state.brands.youtube_channels.results = {}

		state.brands.youtube_channels.brandId = parseInt(brand_id)
		state.brands.youtube_channels.results[page] = channels
		state.brands.youtube_channels.pagination = {
			page: page,
			pageCount: page_count,
			nextPage: next_page,
		}
		state.brands.youtube_channels.loading = false
	},
	[getBrandYouTubeChannels.rejected]: state => {
		state.brands.youtube_channels.loading = false
	},

	// Brand YouTube videos
	[getBrandYouTubeVideos.pending]: state => {
		state.brands.youtube_videos.loading = true
	},
	[getBrandYouTubeVideos.fulfilled]: (state, action) => {
		const { page } = action.meta.arg
		const { brand_id, videos, page_count, next_page } = action.payload.data

		if (page === 1) state.brands.youtube_videos.results = {}

		state.brands.youtube_videos.brandId = parseInt(brand_id)
		state.brands.youtube_videos.results[page] = videos
		state.brands.youtube_videos.pagination = {
			page: page,
			pageCount: page_count,
			nextPage: next_page,
		}
		state.brands.youtube_videos.loading = false
	},
	[getBrandYouTubeVideos.rejected]: state => {
		state.brands.youtube_videos.loading = false
	},

	// Brand Twitch dashboard
	[getBrandTwitchDashboard.pending]: state => {
		state.brands.twitch_dashboard.loading = true

		// state.brands.youtube_channels.brandId = null
		// state.brands.youtube_channels.results = {}
		// state.brands.youtube_channels.pagination = {
		// 	page: null,
		// 	pageCount: null,
		// 	nextPage: null,
		// }

		// state.brands.youtube_videos.brandId = null
		// state.brands.youtube_videos.results = {}
		// state.brands.youtube_videos.pagination = {
		// 	page: null,
		// 	pageCount: null,
		// 	nextPage: null,
		// }
	},
	[getBrandTwitchDashboard.fulfilled]: (state, action) => {
		const { brandId } = action.meta.arg
		state.brands.twitch_dashboard.details[brandId] =
			action.payload.data.details
		state.brands.twitch_dashboard.loading = false
	},
	[getBrandTwitchDashboard.rejected]: state => {
		state.brands.twitch_dashboard.loading = false
	},

	// Brand Twitch channels
	[getBrandTwitchChannels.pending]: state => {
		state.brands.twitch_channels.loading = true
	},
	[getBrandTwitchChannels.fulfilled]: (state, action) => {
		const { page } = action.meta.arg
		const { brand_id, channels, page_count, next_page } =
			action.payload.data

		if (page === 1) state.brands.twitch_channels.results = {}

		state.brands.twitch_channels.brandId = parseInt(brand_id)
		state.brands.twitch_channels.results[page] = channels
		state.brands.twitch_channels.pagination = {
			page: page,
			pageCount: page_count,
			nextPage: next_page,
		}
		state.brands.twitch_channels.loading = false
	},
	[getBrandTwitchChannels.rejected]: state => {
		state.brands.twitch_channels.loading = false
	},

	// Brand Twitch sessions
	[getBrandTwitchSessions.pending]: state => {
		state.brands.twitch_sessions.loading = true
	},
	[getBrandTwitchSessions.fulfilled]: (state, action) => {
		const { page } = action.meta.arg
		const { brand_id, sessions, page_count, next_page } =
			action.payload.data

		if (page === 1) state.brands.twitch_sessions.results = {}

		state.brands.twitch_sessions.brandId = parseInt(brand_id)
		state.brands.twitch_sessions.results[page] = sessions
		state.brands.twitch_sessions.pagination = {
			page: page,
			pageCount: page_count,
			nextPage: next_page,
		}
		state.brands.twitch_sessions.loading = false
	},
	[getBrandTwitchSessions.rejected]: state => {
		state.brands.twitch_sessions.loading = false
	},

	// Brand search
	[searchBrands.pending]: state => {
		state.searchBrands.loading = true
	},
	[searchBrands.fulfilled]: (state, action) => {
		const { page } = action.meta.arg
		const { brands, page_count, next_page } = action.payload.data

		if (page === 1) state.searchBrands.results = {}

		state.searchBrands.results[page] = brands
		state.searchBrands.pagination = {
			page,
			pageCount: page_count,
			nextPage: next_page,
		}
		state.searchBrands.loading = false
	},
	[searchBrands.rejected]: state => {
		state.searchBrands.loading = false
	},

	// Brand suggested
	[suggestedBrands.pending]: state => {
		state.suggestedBrands.loading = true
	},
	[suggestedBrands.fulfilled]: (state, action) => {
		const { id } = action.meta.arg
		const { brands } = action.payload.data

		state.suggestedBrands.results[id] = brands
		state.suggestedBrands.loading = false
	},
	[suggestedBrands.rejected]: state => {
		state.suggestedBrands.loading = false
	},
})

export default brandsReducer
