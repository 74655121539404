import { API, handleError, handleResponse } from '../../api'

export const getGmDataAction = (payload, thunk) => {
    return API.get('gm/index.json')
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
}

export const sendInterestedAction = (payload, thunk) => {
	return API.post('gm/interested.json', payload)
		.then((res) => handleResponse(res, thunk))
		.catch(handleError)
}
