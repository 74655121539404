import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGmData } from '../../store/reducers/gmReducer'
import DealsList from '../deals/DealsList'
import { Row } from '../Layout'
import CustomSelect from '../UI/CustomSelect'
import Filters from '../UI/Filters'
import Loader from '../UI/Loader'
import Paginator from '../UI/Paginator'
import { getContractName } from '../../helpers/gmHelper'
import { useMediaQuery } from '@material-ui/core'
import { useCookie, useUpdateEffect } from 'react-use'
import CustomButton from '../UI/CustomButton'
import { FaTh, FaThList } from 'react-icons/fa'

const GmDeals = () => {
    const dispatch = useDispatch()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [page, setPage] = useState(1)
    const { data, loading } = useSelector(state => state.gm)
    // Handle display cookie (list or cards)
    const [displayCookie, updateDisplayCookie] = useCookie('deals_display')
    const [display, setDisplay] = useState(displayCookie ?? 'list')
    const updateDisplay = (value) => {
        updateDisplayCookie(value)
        setDisplay(value)
        }

    const load = () => {
        if (!data && !loading) dispatch(getGmData())
    }

    const paginateDeals = (deals = []) => {
        let output = []
        let dealsCopy = deals.slice()
        while (dealsCopy.length > 0) {
            output.push(dealsCopy.splice(0, 18))
        }
        return output
    }

    useUpdateEffect(() => {
        setPage(1)
    }, [currentIndex])

    useLayoutEffect(() => {
        load()
    }, [])

    const handlePageChange = (event, value) => {
        setPage(value)
    }

    const paginator = deals => {
        if (deals.length) {
            return (
                <Row justifyContent='center'>
                    <Paginator
                        page={page}
                        count={deals.length}
                        onPageChange={handlePageChange}
                    />
                </Row>
            )
        }
        return null
    }

    let content = null

    if (!data || loading) {
        content = <Loader />
    } else {
        const { deals } = data.contracts[currentIndex]
        const paginatedDeals = paginateDeals(deals)

        content = (
            <>
                <DealsList
                    compact={true}
                    deals={paginatedDeals[page - 1]}
                    display={display}
                />
                {paginator(paginatedDeals)}
            </>
        )
    }


    // Media queries
    const forceCardDisplay = useMediaQuery((theme) =>
        theme.breakpoints.down('md')
    )

    const displayButtons = () => {
        if (forceCardDisplay) return null
        const color = 'rgba(0, 0, 0, 0.71)'
        return (
            <div className='btn-group'>
                <CustomButton
                    handleClick={() => updateDisplay('cards')}
                    type='icon'
                    icon={FaTh}
                    color={color}
                    active={display === 'cards'}
                />
                <CustomButton
                    handleClick={() => updateDisplay('list')}
                    type='icon'
                    icon={FaThList}
                    color={color}
                    active={display === 'list'}
                />
            </div>
        )
    }

    const options = data?.contracts
        ? data.contracts.map((contract, index) => ({
              label: getContractName(contract),
              value: index,
          }))
        : []

    return (
        <>
            <Filters>
                {displayButtons()}
                {Boolean(data?.contracts) && (
                    <CustomSelect
                        value={currentIndex}
                        onChange={({ value }) => setCurrentIndex(value)}
                        options={options}
                    />
                )}
            </Filters>
            {content}
        </>
    )
}

export default GmDeals
