import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import Header from '../../components/layout/Header'
import CustomSelect from '../../components/UI/CustomSelect'
import Loader from '../../components/UI/Loader'
import { getStrikes } from '../../store/reducers/mcnReducer'
import Filters from '../../components/UI/Filters'
import Paginator from '../../components/UI/Paginator'
import { Container } from '../../components/Layout'
import styled from 'styled-components'
import Strike from '../../model/Strike'
import StrikeRow from '../../components/mcn/StrikeRow'
import CustomTable2 from '../../components/UI/CustomTable2'

const StrikesPage = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const { channels } = useSelector((state) => state.talent.data)
    const {
        data: strikes,
        loading,
        pagination,
    } = useSelector((state) => state.mcn.strikes)

    const [status, setStatus] = useState('all')
    const [page, setPage] = useState(1)

    let content

    const load = (page = 1, forced = false) => {
        if (!strikes || !strikes[page] || forced) {
            const channelIds = channels.map((channel) => channel.id)
            const payload = {
                channelIds,
                page,
                status: status === 'all' ? null : status,
            }
            dispatch(getStrikes(payload))
        }
    }

    useLayoutEffect(load, [])

    useUpdateEffect(() => {
        load(page)
    }, [page])

    useUpdateEffect(() => {
        setPage(1)
        load(1, true)
    }, [status])

    const handleStatusChange = ({ value }) => setStatus(value)
    const handlePageChange = (event, value) => setPage(value)

    const options = [
        { label: t('strikes.status.all'), value: 'all' },
        { label: t('strikes.status.in_progress'), value: 'in_progress' },
        { label: t('strikes.status.received_plural'), value: 'received' },
        { label: t('strikes.status.resolved_plural'), value: 'resolved' },
    ]

    const paginator =
        strikes && pagination.pageCount > 1 ? (
            <Paginator
                page={page}
                count={pagination ? pagination.pageCount : 0}
                onPageChange={handlePageChange}
            />
        ) : null

    if (!strikes[page] || loading) {
        content = <Loader />
    } else {
        content = (
            <CustomTable2
                columns={Strike.COLUMNS}
                rows={strikes[page].map((strike) => (
                    <StrikeRow data={strike} />
                ))}
            />
        )
    }

    return (
        <>
            <Header
                title={
                    <Wrapper>
                        {t('pages.strikes')}
                    </Wrapper>
                }
            />
            <Container>
                <Filters>
                    <CustomSelect
                        value={status}
                        onChange={handleStatusChange}
                        options={options}
                    />
                </Filters>
                {paginator}
                {content}
                {paginator}
            </Container>
        </>
    )
}

export default StrikesPage

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
