import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API } from '../../api'
import {
	deleteTalentAction,
	editAction,
	loginAction,
	addFavoriteChannelAction,
	deleteFavoriteChannelAction,
} from '../actions/talentActions'

export const AUTH = {
	UNAUTHORIZED: 'unauthorized',
	AUTHENTICATING: 'authenticating',
	AUTHORIZED: 'authorized',
}

export const login = createAsyncThunk('talent/login', loginAction)
export const logout = createAction('talent/logout')
export const edit = createAsyncThunk('talent/edit', editAction)
export const deleteTalent = createAsyncThunk(
	'talent/delete',
	deleteTalentAction
)
export const addFavoriteChannel = createAsyncThunk(
	'talent/addFavoriteChannel',
	addFavoriteChannelAction
)
export const deleteFavoriteChannel = createAsyncThunk(
	'talent/deleteFavoriteChannel',
	deleteFavoriteChannelAction
)
export const updateTalent = createAction('talent/update')

const initialState = {
	status: AUTH.UNAUTHORIZED,
	data: null,
	updating: false,
	token: null,
}

const talentReducer = createReducer(initialState, {
	[login.pending]: (state, action) => {
		state.status = AUTH.AUTHENTICATING
	},
	[login.fulfilled]: (state, action) => {
		const { token, talent } = action.payload.data

		// Set API default auth
		API.defaults.headers.common['Authorization'] = `Bearer ${token}`

		state.data = talent
		state.token = token
		state.status = AUTH.AUTHORIZED
	},
	[login.rejected]: (state, action) => {
		state.status = AUTH.UNAUTHORIZED
	},
	[logout]: (state, action) => {
		localStorage.removeItem('talent')
		state.data = null
		state.token = null
		state.status = AUTH.UNAUTHORIZED
	},
	[edit.pending]: (state, action) => {
		state.updating = true
	},
	[edit.fulfilled]: (state, action) => {
		state.updating = false
	},
	[edit.rejected]: (state, action) => {
		state.updating = false
	},
	[updateTalent]: (state, action) => {
		state.data = action.payload
	},
	[deleteTalent.pending]: (state, action) => {
		state.deleting = true
	},
	[deleteTalent.fulfilled]: (state, action) => {
		state.deleting = false
	},
	[deleteTalent.rejected]: (state, action) => {
		state.deleting = false
	},
	[addFavoriteChannel.pending]: (state, action) => {
		state.updating = true
	},
	[addFavoriteChannel.fulfilled]: (state, action) => {
		state.updating = false
	},
	[addFavoriteChannel.rejected]: (state, action) => {
		state.updating = false
	},
	[deleteFavoriteChannel.pending]: (state, action) => {
		state.updating = true
	},
	[deleteFavoriteChannel.fulfilled]: (state, action) => {
		state.updating = false
	},
	[deleteFavoriteChannel.rejected]: (state, action) => {
		state.updating = false
	},
})

export default talentReducer
