function Profitability({profitability}) {
	let euros;
	if(300000 <= profitability)
		euros = '€€€';
	else if(200000 <= profitability && profitability < 300000)
		euros = '€€';
	else if(100000 <= profitability && profitability < 200000)
		euros = '€';
	else
		euros = '-';

	return <span className='text-bold'>{euros}</span>;
}

export default Profitability;
