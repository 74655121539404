import React from 'react'
import '@brainhubeu/react-carousel/lib/style.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import RoyaltiesCard from './RoyaltiesCard'
import { useTranslation } from 'react-i18next'
import { FaMoneyBill } from 'react-icons/fa'
import HomeCard from '../HomeCard'
import CustomCarousel from '../../UI/CustomCarousel'

const RoyaltiesCarousel = () => {
    const history = useHistory()
    const { t } = useTranslation()

    const { royalties } = useSelector((state) => state.badges)

    const slides = Object.keys(royalties).map((key) => (
        <RoyaltiesCard key={key} type={key} data={royalties[key]} />
    ))

    const handleClick = () =>  history.push('/royalties', { index: 0 })

    return (
        <HomeCard
            title={t('home.royalties')}
            icon={FaMoneyBill}
            onClick={handleClick}
        >
            <CustomCarousel slides={slides} />
        </HomeCard>
    )
}

export default RoyaltiesCarousel
