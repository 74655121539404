import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { getGmDataAction, sendInterestedAction } from '../actions/gmActions'

export const getGmData			= createAsyncThunk('gm/get', getGmDataAction)
export const sendInterested	= createAsyncThunk('gm/interested', sendInterestedAction)

const initialState = {
    loading: false,
    data: null,
}

const gmReducer = createReducer(initialState, {
    [getGmData.pending]: (state) => {
        state.loading = true
    },
    [getGmData.fulfilled]: (state, action) => {
        const { gm } = action.payload.data
        state.data = { ...gm }
        state.loading = false
    },
    [getGmData.rejected]: (state) => {
        state.loading = false
    },

	[sendInterested.pending]: (state) => {
		state.loading = true
	},
	[sendInterested.fulfilled]: (state) => {
		state.loading = false
		state.interested = true
	},
	[sendInterested.rejected]: (state) => {
		state.loading = false
	},
})

export default gmReducer
