import store from '../store/store'

export const TALENT_ACCESS_TYPE = {
	LIMITED: 'limited',
	BASIC: 'basic',
	ADVANCED: 'advanced',
}

export const getTalentActivity = (type = null) => {
	const { talent, badges } = store.getState()

	let talentHasBC = false
	let talentHasStrikes = false
	let talentHasClaims = false
	let talentAccessType = TALENT_ACCESS_TYPE.LIMITED
	const talentHasCopyrights = badges.mcn?.copyrights > 0

	Object.entries(badges.deals).forEach(([key, totalDeals]) => {
		if (totalDeals > 0) talentHasBC = true
	})

	if (badges.mcn) {
		const { strikes, claims } = badges.mcn

		if (strikes) {
			Object.entries(strikes).forEach(([key, totalStrikes]) => {
				if (totalStrikes > 0) talentHasStrikes = true
			})
		}

		if (claims) {
			Object.entries(claims).forEach(([key, totalClaims]) => {
				if (totalClaims > 0) talentHasClaims = true
			})
		}
	}

	if (type) {
		switch (type) {
			case 'strikes':
				return talentHasStrikes
			case 'claims':
				return talentHasClaims
			case 'copyrights':
				return talentHasCopyrights
			default:
				return null
		}
	}

	if (talent.status === 'authorized') {
		talentAccessType =
			talent.data?.channel_zoho_accounts?.length > 0
				? TALENT_ACCESS_TYPE.ADVANCED
				: TALENT_ACCESS_TYPE.BASIC

		return {
			numberOfChannels: talent.data?.channels
				? talent.data.channels.length
				: 0,
			talentHasZohoAccounts:
				talent.data?.channel_zoho_accounts &&
				talent.data?.channel_zoho_accounts.length > 0,
			talentIsAuthorized: talent.data?.status === 'authorized',
			talentHasBC,
			talentHasGm: badges.gm?.contracts?.length > 0,
			talentHasGmPotential: !!badges.gm?.gmPotential,
			talentHasClaims,
			talentHasStrikes,
			talentHasCopyrights,
			talentHasMcn:
				talentHasClaims || talentHasStrikes || talentHasCopyrights,
			talentHasRoyalties: !!badges.royalties,
			talentHasChannels:
				(talent.data?.channels && talent.data.channels.length > 0) ||
				(talent.data?.favorite_channels &&
					talent.data.favorite_channels.length) > 0,
			talentAccessType,
		}
	}
	return {}
}

export const talentOwnsChannel = (talent, channelId) => {
	const { data: _talent } = store.getState().talent
	return _talent.channels?.find(
		channel => channel.id.toString() === channelId.toString()
	)
		? true
		: false
}

export const talentChannels = () => {
	const { data: _talent } = store.getState().talent
	const channels = _talent.channels ? _talent.channels : []
	const favoriteChannels = _talent.favorite_channels
		? _talent.favorite_channels
		: []
	return [...channels, ...favoriteChannels]
}

export const talentHasFavoriteChannels = () => {
	const { data: _talent } = store.getState().talent
	return _talent.favorite_channels.length > 0
}

export const getChannelsList = () => {
	const { data: _talent } = store.getState().talent
	const channels = _talent.channels ? _talent.channels : []

	return channels.map(channel => {
		return { label: channel.display_name, value: channel.id }
	})
}
