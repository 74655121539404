import { API, handleError, handleResponse } from "../../api"

export const getRevenuesAction = (payload, thunk) => {
    const params = { month: payload.month }
    return API('talents/revenues.json', {params})
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}

export const getRevenuesDetailsAction = (payload, thunk) => {
    const {id, month} = payload
    return API(`talents/revenues_detail/${id}/${month}.json`)
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}
