import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaYoutube } from 'react-icons/fa'
import Copyright from '../../model/Copyright'
import VideoInfos from '../UI/VideoInfos'
import CopyrightTypeIcon from './CopyrightTypeIcon'

const CopyrightRow = ({ data }) => {
    const { t } = useTranslation()
    const copyright = new Copyright(data)

    return (
        <TableRow>
            <TableCell>
                <VideoInfos video={copyright.video} />
            </TableCell>
            <TableCell align='center'>{copyright.ref || '-'}</TableCell>
            <TableCell align='center'>
                <CopyrightTypeIcon type={copyright.type} />
            </TableCell>
            <TableCell align='center'>{copyright.date}</TableCell>
            <TableCell align='center'>{copyright.pirate_name}</TableCell>
            <TableCell align='right'>
                {copyright.pirate_video ? (
                    <Tooltip
                        arrow
                        title={t('copyrights.table.video_link')}
                    >
                        <IconButton
                            onClick={() =>
                                window.open(copyright.pirate_video, '_blank')
                            }
                        >
                            <FaYoutube color='red' />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </TableCell>
        </TableRow>
    )
}

export default CopyrightRow
