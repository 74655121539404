import React from 'react'

const Loader = () => {
    return (
        <div className='loader'>
            <div className='loader-logo-container'>
                <svg className='loader-logo' viewBox='25 25 50 50'>
                    <circle
                        className='path'
                        cx='50'
                        cy='50'
                        r='20'
                        fill='none'
                    ></circle>
                </svg>
            </div>
        </div>
    )
}

export default Loader
