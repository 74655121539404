import React from 'react'
import '@brainhubeu/react-carousel/lib/style.css'
import ChannelCard from './ChannelCard'
import { FaStar, FaYoutube } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import HomeCard from '../HomeCard'
import CustomCarousel from '../../UI/CustomCarousel'
import { talentChannels, talentHasFavoriteChannels } from 'helpers/talentHelper'
import CustomButton from 'components/UI/CustomButton'
import { useHistory } from 'react-router-dom'

const ChannelsCarousel = () => {
	const channels = talentChannels()
	const { t } = useTranslation()
	const history = useHistory()

	const slides = channels.map(channel => <ChannelCard channel={channel} />)
	const title = channels.length > 1 ? t('home.channels') : t('home.channel')

	const handleAddFavorite = () => {
		history.push({
			pathname: `/analytics/search`,
		})
	}

	return (
		<HomeCard
			title={title}
			icon={FaYoutube}
			actions={
				!talentHasFavoriteChannels() && (
					<CustomButton
						icon={FaStar}
						type='flat'
						title={t('channel.cta.add_favorites')}
						handleClick={() => handleAddFavorite()}
					/>
				)
			}
		>
			<CustomCarousel slides={slides} />
		</HomeCard>
	)
}

export default ChannelsCarousel
