import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import moment from 'moment'
import {
    clearNewMessagesAction,
    getNextPageAction,
    getThreadAction,
    getUnreadThreadsAction,
    sendMessageAction,
} from '../actions/threadsActions'

export const getThread = createAsyncThunk('threads/get', getThreadAction)
export const sendMessage = createAsyncThunk('threads/send', sendMessageAction)
export const clearNewMessages = createAsyncThunk(
    'threads/clear_new',
    clearNewMessagesAction
)
export const getNextPage = createAsyncThunk(
    'threads/get/next',
    getNextPageAction
)
export const getUnreadThreads = createAsyncThunk(
    'threads/unread/get',
    getUnreadThreadsAction
)

const initialState = {
    unread: {
        loading: false,
        data: null,
    },
}

const threadsReducer = createReducer(initialState, {
    [getThread.pending]: (state, action) => {
        const { id, reload = false } = action.meta.arg
        state[id] = {
            loading: reload ? false : true,
        }
    },
    [getThread.fulfilled]: (state, action) => {
        const { id } = action.meta.arg
        const { thread, threadMessages, page, nextPage } = action.payload.data
        state[id].messages = threadMessages
        state[id].thread = {
            ...thread,
            page,
            nextPage,
        }
        state[id].loading = false
    },
    [getThread.rejected]: (state, action) => {
        const { id } = action.meta.arg
        state[id].loading = false
    },

    [sendMessage.pending]: (state, action) => {
        const { threadId: id, threadMessage, talent } = action.meta.arg
        state[id].messages.unshift({
            type: 'talent',
            message: threadMessage,
            talent,
            sending: true,
            created: moment()
        })
        state[id].sending = true
    },
    [sendMessage.fulfilled]: (state, action) => {
        const { threadId: id } = action.meta.arg
        const { threadMessage, talent } = action.payload.data
        state[id].messages[0] = {
            ...threadMessage,
            talent,
            sending: false
        }
        state[id].sending = false
    },
    [sendMessage.rejected]: (state, action) => {
        const { threadId: id } = action.meta.arg
        state[id].sending = false
        state[id].messages[0].sending = false
        state[id].messages[0].error = true
    },
    [clearNewMessages.pending]: (state, action) => {},
    [clearNewMessages.fulfilled]: (state, action) => {
        const { threadId: id } = action.meta.arg
        const updatedMessages = state[id].messages.map((message) => {
            if (message.is_new) {
                let updatedMessage = { ...message }
                updatedMessage.is_new = false
                return updatedMessage
            }
            return message
        })
        state[id].messages = updatedMessages
    },
    [clearNewMessages.rejected]: (state, action) => {},

    [getNextPage.pending]: (state, action) => {
        const { threadId: id } = action.meta.arg
        state[id].updating = true
    },
    [getNextPage.fulfilled]: (state, action) => {
        const { threadId: id } = action.meta.arg
        const { threadMessages, page, nextPage, thread } = action.payload.data
        const messages = [...state[id].messages, ...threadMessages]
        state[id].messages = messages
        state[id].thread = {
            ...thread,
            page,
            nextPage,
        }
        state[id].updating = false
    },
    [getNextPage.rejected]: (state, action) => {
        const { threadId: id } = action.meta.arg
        state[id].updating = false
    },

    [getUnreadThreads.pending]: (state, action) => {
        state.unread.loading = true
    },
    [getUnreadThreads.fulfilled]: (state, action) => {
        state.unread.data = action.payload.data.threads
        state.unread.loading = false
    },
    [getUnreadThreads.rejected]: (state, action) => {
        state.unread.loading = false
    },
})

export default threadsReducer
