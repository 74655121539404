import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import Header from '../../components/layout/Header'
import CustomSelect from '../../components/UI/CustomSelect'
import Loader from '../../components/UI/Loader'
import { getFormattedClaims } from '../../helpers/claimsHelper'
import { getClaims } from '../../store/reducers/mcnReducer'
import CustomTable from '../../components/UI/CustomTable'
import Filters from '../../components/UI/Filters'
import Paginator from '../../components/UI/Paginator'
import { Container } from '../../components/Layout'
import { IconButton, Tooltip } from '@material-ui/core'
import { FaInfoCircle } from 'react-icons/fa'
import styled from 'styled-components'

const ClaimsPage = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const { channels } = useSelector((state) => state.talent.data)
    const {
        data: claims,
        loading,
        pagination,
    } = useSelector((state) => state.mcn.claims)

    const [status, setStatus] = useState('all')
    const [page, setPage] = useState(1)

    let content

    const load = (page = 1, forced = false) => {
        if (!claims || !claims[page] || forced) {
            const channelIds = channels.map((channel) => channel.id)
            const payload = {
                channelIds,
                page,
                status: status === 'all' ? null : status,
            }
            dispatch(getClaims(payload))
        }
    }

    useLayoutEffect(load, [])

    useUpdateEffect(() => {
        load(page)
    }, [page])

    useUpdateEffect(() => {
        setPage(1)
        load(1, true)
    }, [status])

    const handleStatusChange = ({ value }) => setStatus(value)
    const handlePageChange = (event, value) => setPage(value)

    const options = [
        { label: t('claims.status.all'), value: 'all' },
        { label: t('claims.status.in_progress'), value: 'in_progress' },
        { label: t('claims.status.accepted_plural'), value: 'accepted' },
        { label: t('claims.status.rejected_plural'), value: 'rejected' },
    ]

    const paginator =
        claims && pagination.pageCount > 1 ? (
            <Paginator
                page={page}
                count={pagination ? pagination.pageCount : 0}
                onPageChange={handlePageChange}
            />
        ) : null

    if (!claims[page] || loading) {
        content = <Loader />
    } else {
        const { rows, columns } = getFormattedClaims(claims[page], channels)

        content = <CustomTable rows={rows} columns={columns} />
    }

    return (
        <>
            <Header
                title={
                    <Wrapper>
                        {t('pages.claims')}
                        <Tooltip arrow title={t('claims.tooltip')}>
                            <IconButton aria-label='delete'>
                                <FaInfoCircle color='white' />
                            </IconButton>
                        </Tooltip>
                    </Wrapper>
                }
            />
            <Container>
                <Filters>
                    <CustomSelect
                        value={status}
                        onChange={handleStatusChange}
                        options={options}
                    />
                </Filters>
                {paginator}
                {content}
                {paginator}
            </Container>
        </>
    )
}

export default ClaimsPage

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
