import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import {
    getFormattedRoyalties,
    getRoyaltiesStatusOptions,
} from '../../helpers/royaltiesHelper'
import { getRoyalties } from '../../store/reducers/royaltiesReducer'
import CustomSelect from '../UI/CustomSelect'
import CustomTable from '../UI/CustomTable'
import Filters from '../UI/Filters'
import Loader from '../UI/Loader'
import Paginator from '../UI/Paginator'
import Note from 'components/UI/Note'

const RoyaltiesView = ({
    type,
    status,
    page,
    onStatusChange,
    onPageChange,
    resetPage,
}) => {
    const dispatch = useDispatch()
    const {
        data: royalties,
        loading,
        pagination,
	 } = useSelector((state) => state.royalties[type])
	 const { t } = useTranslation()

    // Load data if necessary or forced, and if not already loading
    const load = (page = 1, forced = false) => {
        if ((!royalties[page] || forced) && !loading) {
            const payload = {
                page,
                type,
                status,
            }
            dispatch(getRoyalties(payload))
        }
    }

    // Load royalties on first mount
    useLayoutEffect(load, [])

    // Load next page if necessary
    useUpdateEffect(() => {
        load(page)
    }, [page])

    // Reload royalties when the user changes the status
    useUpdateEffect(() => {
        resetPage()
        load(1, true)
    }, [status])

    const options = getRoyaltiesStatusOptions()

    const paginator =
        royalties && pagination.pageCount > 1 ? (
            <div className='table-tools'>
                <Paginator
                    page={page}
                    count={pagination ? pagination.pageCount : 0}
                    onPageChange={onPageChange}
                />
            </div>
        ) : null

    let content

    if (!royalties[page] || loading) {
        content = <Loader />
    } else {
        const { columns, tableData } = getFormattedRoyalties(
            royalties,
            page,
            type
        )
        content = <CustomTable columns={columns} rows={tableData} />
    }

    return (
		 <>
			<div className="mb-20">
				<Note>
					 {t('royalties.main_note')}
					 <br />
					 <small>{t('royalties.main_sub_note')}</small>
				</Note>
			</div>
			<Filters>
					<CustomSelect
						value={status}
						onChange={onStatusChange}
						options={options}
					/>
			</Filters>
			{paginator}
			{content}
			{paginator}
		</>
    )
}

export default RoyaltiesView
