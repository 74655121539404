import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import {
    getTasksAction,
    getTaskAction,
    manageTaskAction,
} from '../actions/tasksActions'

export const getTasks = createAsyncThunk('tasks/get', getTasksAction)
export const getTask = createAsyncThunk('task/get', getTaskAction)
export const manageTask = createAsyncThunk('task/manage', manageTaskAction)

const initialState = {
    in_progress: {
        data: null,
        loading: false,
    },
    checking: {
        data: null,
        loading: false,
    },
    done: {
        data: null,
        loading: false,
    },
    current: {
        data: null,
        loading: false,
    },
}

const tasksReducer = createReducer(initialState, {
    [getTasks.pending]: (state, action) => {
        const status = action.meta.arg
        console.log(`${action.type}/${status}`)
        state[status].loading = true
    },
    [getTasks.fulfilled]: (state, action) => {
        const status = action.meta.arg
        console.log(`${action.type}/${status}`)
        state[status].data = action.payload.data.tasks
        state[status].loading = false
    },
    [getTasks.rejected]: (state, action) => {
        const status = action.meta.arg
        console.log(`${action.type}/${status}`)
        state[status].loading = false
    },

    [getTask.pending]: (state, action) => {
        console.log(`${action.type}/${action.meta.arg}`)
        state.current.loading = true
    },
    [getTask.fulfilled]: (state, action) => {
        console.log(`${action.type}/${action.meta.arg}`)
        state.current.data = action.payload.data.task
        state.current.loading = false
    },
    [getTask.rejected]: (state, action) => {
        console.log(`${action.type}/${action.meta.arg}`)
        state.current.loading = false
    },

    [manageTask.pending]: (state, action) => {
        
        state.current.loading = true
    },
    [manageTask.pending]: (state, action) => {
        
        state.current.loading = false
    },
    [manageTask.pending]: (state, action) => {
        
        state.current.loading = false
    },
})

export default tasksReducer
