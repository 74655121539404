import { API, handleError, handleResponse } from '../../api'

export const getServicesAction = (payload, thunk) => {
	const params = {
		lang: payload.lang,
	}

	return API.get('services/list.json', { params })
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}
