import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { shorten } from '../../helpers/numbersHelper'
import { useHistory } from 'react-router-dom'
import ChannelCard from 'components/ChannelCard'
import styled from 'styled-components'

const ResultTwitchCard = ({ channel, displayCardContent }) => {
	const {
		followers,
		potential_avg_viewers,
		sponsored_sessions_count_3m,
		sponsored_sessions_count,
	} = channel

	const { t } = useTranslation()
	const history = useHistory()

	const cardcontent = displayCardContent ? (
		<Typography component='div'>
			<KpisList>
				<div style={{ width: '50%' }}>
					<dl>
						<dt>{t('twitch.kpis.followers')}</dt>
						<dd>{shorten(followers, 1)}</dd>
					</dl>
					<dl>
						<dt>{t('twitch.kpis.sponsored_sessions')}</dt>
						<dd style={{ lineHeight: '16px' }}>
							<>
								<KpiSubtitle>
									{t('twitch.kpis.sponsored_sessions_90d')}
								</KpiSubtitle>{' '}
								{shorten(
									sponsored_sessions_count_3m
										? sponsored_sessions_count_3m
										: 0,
									0
								)}
							</>
						</dd>
						<dd style={{ lineHeight: '16px' }}>
							<KpiSubtitle>
								{t('twitch.kpis.sponsored_sessions_total')}
							</KpiSubtitle>{' '}
							<span style={{ fontSize: '0.7rem' }}>
								{shorten(sponsored_sessions_count, 0)}
							</span>
						</dd>
					</dl>
				</div>

				<div style={{ width: '50%' }}>
					<dl>
						<dt>{t('twitch.kpis.potential_avg_viewers')}</dt>
						<dd>{shorten(potential_avg_viewers, 1)}</dd>
					</dl>
				</div>
			</KpisList>
		</Typography>
	) : null

	const handleClick = () => {
		history.push({
			pathname: `/analytics/search/${channel.id}`,
			state: { channel },
		})
	}

	return (
		<ChannelCard
			channel={channel}
			handleClick={handleClick}
			cardcontent={cardcontent}
		/>
	)
}

const KpisList = styled.dl`
	display: flex;
	text-align: right;
	justify-content: space-between;
`
const KpiSubtitle = styled.span`
	text-align: right;
	font-size: 10px;
	color: grey;
	font-style: italic;
`

export default ResultTwitchCard
