import { API, handleError, handleResponse } from '../../api'

export const getAgendaDataAction = (payload, thunk) => {
    const params = {
        status: payload.status ?? 'in_progress',
        limit: payload.limit ?? null,
    }

    return API.get('agenda/index.json', { params })
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
}
