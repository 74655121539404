import { Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getTaskCardData } from '../../helpers/tasksHelper'
import Avatar from '../UI/Avatar'
import CustomButton from '../UI/CustomButton'
import TaskCompletionModal from './TaskCompletionModal'

const TaskCard = ({ task, dealStatus, redirect }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [modalIsOpen, toggleModal] = useState(false)

    const handleFinish = () => toggleModal(true)

    const { status, expectedDate, dealInProgress, taskInProgress } =
        getTaskCardData(task, dealStatus, redirect)

    return (
        <>
            <TaskCompletionModal
                task={task}
                isOpen={modalIsOpen}
                toggle={toggleModal}
            />
            <Wrapper>
                <div>
                    <div
                        className={status.color}
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 12,
                            color: 'white',
                        }}
                    >
                        {status.icon}
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                                marginTop: 6,
                                fontSize: 14,
                                display: 'inline-flex',
                            }}
                        >
                            {status.label}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom:
                                    (taskInProgress && dealInProgress) ||
                                    (task.description && '1px solid #e0e0e0'),
                                padding: 12,
                            }}
                        >
                            <span style={{ fontWeight: '500' }}>
                                {task.title}
                                {expectedDate && (
                                    <span
                                        style={{
                                            fontSize: 14,
                                            color: 'grey',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        <br />
                                        {expectedDate}
                                    </span>
                                )}
                            </span>
                            {task.campaign ? (
                                <div
                                    style={{
                                        marginTop: 12,
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 14,
                                        fontWeight: '500',
                                    }}
                                >
                                    <Link to={`/deals/view/${task.deal_id}`}>
                                        {task.campaign.title}
                                    </Link>
                                    {task.campaign.icon_url ? (
                                        <Avatar
                                            style={{
                                                marginLeft: 15,
                                                marginRight: 0,
                                            }}
                                            onClick={() =>
                                                history.push(
                                                    `/deals/view/${task.deal_id}`
                                                )
                                            }
                                            src={task.campaign.icon_url}
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                        {task.description ? (
                            <span
                                style={{
                                    fontSize: 14,
                                    padding: 12,
                                }}
                            >
                                {task.description}
                            </span>
                        ) : null}
                        {taskInProgress && dealInProgress && (
                            <div style={{ alignSelf: 'flex-end', padding: 12 }}>
                                <CustomButton
                                    icon={FaCheck}
                                    title={t('tasks.actions.finish')}
                                    handleClick={handleFinish}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Wrapper>
        </>
    )
}

export default TaskCard

const Wrapper = styled(Paper)`
    display: flex;
    width: 100%;
    background-color: 'white';
    border-radius: 10;
    overflow: hidden;
    margin-bottom: 16px;
`
