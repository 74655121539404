import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { API, handleError, handleResponse } from '../api'
import { Container } from '../components/Layout'
import Loader from '../components/UI/Loader'
import Note from '../components/UI/Note'
import store from '../store/store'

const UnsubscribePage = () => {
    const { t } = useTranslation()
    const { token } = useSelector(state => state.talent)
    const [loading, setLoading] = React.useState(false)

    const unsubscribe = () => {
        if (token) {
            setLoading(true)
            API.get('talents/unsubscribe.json')
                .then(res => handleResponse(res, store))
                .catch(handleError)
                .finally(() => setLoading(false))
        }
    }

    React.useEffect(unsubscribe, [token])

    return (
        <Container>
            {loading || !token ? <Loader /> : <Note message={t('unsubscribe.message')} type='success' />}
        </Container>
    )
}

export default UnsubscribePage
