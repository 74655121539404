import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getBrandYouTubeChannels } from '../../store/reducers/brandsReducer'
import * as Redux from 'react-redux'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import { Col, Container, Row } from '../Layout'
import Loader from '../UI/Loader'
import Paginator from '../UI/Paginator'
import ResultCard from '../analytics/ResultCard'
import { Breadcrumbs, Divider, Link, Typography } from '@material-ui/core'

const BrandYoutube = ({ id, handleBackToDetails }) => {
	const params = {
		page: useSearchParam('page'),
	}

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const {
		brandId,
		results,
		loading,
		pagination: { pageCount },
	} = Redux.useSelector(state => state.brands.brands.youtube_channels)

	const [loaded, setLoaded] = React.useState(false)
	const [page, setPage] = React.useState(params.page ?? 1)

	const search = (page = 1, force = false) => {
		if (id !== brandId || ((!results[page] || force) && !loading)) {
			setPage(page)
			updateParams(page)
			if (!loaded) setLoaded(true)
			dispatch(getBrandYouTubeChannels({ id, page }))
		}
	}

	// Vérifie au chargement de la page si il y a un mot clé et lance une recherche si nécessaire
	React.useLayoutEffect(() => {
		search(page ?? 1)
	}, [])

	// Relance une recherche à chaque changement de page
	React.useEffect(() => {
		if (page) search(page)
	}, [page])

	const handlePageChange = (e, value) => setPage(value)
	const updateParams = page => {
		// history.replace({ search: `${qs.stringify({ page })}` })
	}

	const paginator = Object.keys(results).length ? (
		<PaginatorWrapper>
			<Paginator
				count={pageCount}
				page={page}
				onPageChange={handlePageChange}
			/>
		</PaginatorWrapper>
	) : null

	let content

	if (results[page] && results[page].length) {
		content = (
			<Row spacing={2}>
				{results[page].map(result => (
					<Col xs={12} lg={6} xl={4} key={result.id}>
						<ResultCard
							channel={result}
							displayCardContent={true}
						/>
					</Col>
				))}
			</Row>
		)
	}

	if (!loading && (!results[page] || !results[page].length)) {
		content = (
			<EmptyMessage variant='body2' align='center'>
				{loaded ? t('search.no_results') : t('search.message')}
			</EmptyMessage>
		)
	}

	if (loading) {
		content = <Loader />
	}

	return (
		<>
			<Breadcrumbs aria-label='breadcrumb'>
				<Link
					style={{ cursor: 'pointer' }}
					underline='hover'
					color='inherit'
					onClick={() => handleBackToDetails('youtube_dashboard')}
				>
					{t('brand.pages.details')}
				</Link>
				<Typography color='textPrimary'>
					{t('brand.pages.sponsored_youtube_channels')}
				</Typography>
			</Breadcrumbs>
			<Row spacing={2} justifyContent='flex-end'>
				<Col xs={12} md={6} lg={6}>
					<h4>{t('brand.titles.sponsored_youtube_channels')}</h4>
				</Col>
				<Col xs={12} md={6} lg={6}>
					{paginator}
				</Col>
				<Col xs={12}>
					<Divider light orientation='horizontal' />
				</Col>
			</Row>
			{content}
			{!loading && (
				<Row spacing={2} justifyContent='flex-end'>
					<Col xs={12} md={6} lg={6}>
						{paginator}
					</Col>
				</Row>
			)}
		</>
	)
}

export default BrandYoutube

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`

const EmptyMessage = styled(Typography)`
	padding: 24px;
`
