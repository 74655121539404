import React from 'react'
import {
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Paper,
	Tooltip,
	Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'
import VideoPlayer from './VideoPlayer'
import { shorten } from '../../helpers/numbersHelper'
import styled from 'styled-components'
import emptyImg from '../../assets/img/empty-img.png'
import { useHistory } from 'react-router'
import { Avatar } from '@chatscope/chat-ui-kit-react'
import DateDisplay from './DateDisplay'

const VideoCard = ({ video, kpi, displayBrands, displayChannel, flat }) => {
	const { title, yt_id } = video
	const [card, { width }] = useMeasure()
	const { t } = useTranslation()
	const history = useHistory()

	const availableKpis = {
		views: t('video.kpis.views'),
		'7d_views': t('channels.7d_views'),
	}

	if (kpi === undefined) kpi = '7d_views'

	const handleChannelClick = () => {
		history.push({
			pathname: `/analytics/search/${video.channel.id}`,
			state: { channel: video.channel },
		})
	}

	const maincontent = (
		<>
			{displayChannel && video?.channel && (
				<CardHeader
					style={{ cursor: 'pointer' }}
					onClick={() => handleChannelClick()}
					titleTypographyProps={{ noWrap: true }}
					subheaderTypographyProps={{ noWrap: true }}
					title={video.channel.display_name}
					subheader={
						video.channel?.subscribers
							? shorten(video.channel.subscribers, 1) +
							  ' ' +
							  t('channel.kpis.subscribers')
							: '-'
					}
					avatar={<Avatar src={video.channel.logo} />}
				/>
			)}
			<CardMedia>
				<VideoPlayer light id={yt_id} width={width} />
			</CardMedia>
			<CardContent>
				<Typography variant='body1' noWrap gutterBottom>
					{title}
				</Typography>
				<KpisWrapper>
					<Kpi variant='subtitle2'>
						{shorten(video[kpi], 1)} {availableKpis[kpi]}
					</Kpi>
					<Kpi variant='subtitle2' $right>
						<DateDisplay date={video.uploaded} />
					</Kpi>
				</KpisWrapper>
				<BrandsWrapper className='mt-10'>
					{displayBrands &&
						video?.brands &&
						video?.brands.length > 0 && (
							<>
								<Tooltip
									key={yt_id + '_' + video.brands[0].id}
									title={video.brands[0].name}
									arrow
								>
									<Logo
										src={video.brands[0].logo}
										onError={e => {
											e.target.onerror = null
											e.target.src = emptyImg
										}}
									/>
								</Tooltip>

								<SecondaryBrandsWrapper>
									{video.brands
										.map(
											(brand, bIndex) =>
												bIndex > 0 && (
													<Tooltip
														key={
															yt_id +
															'_' +
															brand.id
														}
														title={brand.name}
														arrow
													>
														<SecondaryBrandLogo
															src={brand.logo}
															onError={e => {
																e.target.onerror =
																	null
																e.target.src =
																	emptyImg
															}}
														/>
													</Tooltip>
												)
										)
										.reverse()}
								</SecondaryBrandsWrapper>
							</>
						)}
				</BrandsWrapper>
			</CardContent>
		</>
	)

	return flat ? (
		<Card variant='outlined' ref={card}>
			{maincontent}
		</Card>
	) : (
		<Card component={Paper} ref={card}>
			{maincontent}
		</Card>
	)
}

export default VideoCard

const KpisWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

const Kpi = styled(Typography)`
	color: rgba(0, 0, 0, 0.54);
	${props => props.$right && 'text-align: right'}
`

const BrandsWrapper = styled.div`
	min-height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Logo = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 50%;
`

const SecondaryBrandsWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;

	& img:not(:first-child) {
		margin-right: -8px;
	}
`

const SecondaryBrandLogo = styled.img`
	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 3px solid white;
`
