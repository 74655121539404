import React from 'react'
import moment from 'moment'
import { formatCurrencies } from '../../../helpers/numbersHelper'
import { useTranslation } from 'react-i18next'
import classes from '../styles/Royalties.module.scss'

import scamLogo from '../../../assets/img/logo_scam.png'
import sacdLogo from '../../../assets/img/sacd.png'

const RoyaltiesCard = ({ type, data }) => {
    const { t } = useTranslation()

    const logos = {
        sacd: sacdLogo,
        scam: scamLogo,
    }

    return (
        <div className={classes.card_container}>
            <img src={logos[type]} className={classes.logo} alt='Royalties logo'/>
            <dl className={classes.sheet}>
                <dt className={classes.label}>
                    {t('royalties.last_sheet', {
                        date: moment(data.file_date).calendar(),
                    })}
                </dt>
                <dd className={classes.value}>{formatCurrencies(data.amount)}</dd>
            </dl>
            {type === 'sacd' && data.amount_corrections ? (
                <dl className={classes.sheet}>
                    <dt className={classes.label}>{t('royalties.corrections')}</dt>
                    <dd className={classes.value}>{formatCurrencies(data.amount_corrections)}</dd>
                </dl>
            ) : null}
        </div>
    )
}

export default RoyaltiesCard
