import { useLayoutEffect } from 'react'
import { googleLogout } from '@react-oauth/google'
import { onLogoutSuccess } from '../../helpers/authHelper'

const LogoutPage = () => {
	useLayoutEffect(() => {
		googleLogout()
		onLogoutSuccess()
	}, [])

	return null
}

export default LogoutPage
