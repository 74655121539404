import { API, handleError, handleResponse } from '../../api'
import { updateBadges } from '../reducers/badgesReducer'
import { getDeal, getDeals } from '../reducers/dealsReducer'
import { updateTalent } from '../reducers/talentReducer'

export const getDealsAction = (payload, thunk) => {
    const { page, filter, search, status, year, month } = payload

    const params = {
        ts: new Date().getTime(),
        dealsFilter: filter === 'all' ? null : filter,
        dealsStatus: status === 'in_progress' ? 'in_progress2' : status,
        search,
        year,
        month,
        limit: 12,
        page,
    }

    return API.get('deals/index.json', { params })
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
}

export const getDealAction = async (payload, thunk) => {
    try {
        // Fetch deal
        const {
            data: { deal },
        } = await API.get(`deals/view/${payload.id}.json`)

        // fetch thread from the thread_id in deal
        const options = {
            params: {
                page: 1,
            },
        }

        const {
            data: { thread, page, nextPage, threadMessages, badge, talent },
        } = await API.get(`threads/view/${deal.thread_id}.json`, options)

        // update badges if needed
        if (talent) thunk.dispatch(updateTalent(talent))
        if (badge) thunk.dispatch(updateBadges(badge))

        return {
            thread: { ...thread, page, hasNextPage: nextPage },
            threadMessages,
            deal,
        }
    } catch (error) {
        handleError(error)
    }
}

export const manageDealAction = (payload, thunk) => {
    const { dealId, previousStatus, newStatus } = payload;
    return (
        API.post('deals/dealManagement.json', payload)
            .then((res) => handleResponse(res, thunk, false))
            // Reload current deal
            .then(() => thunk.dispatch(getDeal({id: dealId})))
            // Reload deals list with the previous deal status
            .then(() => thunk.dispatch(getDeals({status: previousStatus})))
            // Reload deals list with the new deal status
            .then(() => {
                if (newStatus !== 'negociation') {
                    thunk.dispatch(getDeals({status: newStatus}))
                }
            })
            .catch(handleError)
    )
}
