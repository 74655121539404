import React from 'react'
import moment from 'moment'
import { MessageSeparator, Message } from '@chatscope/chat-ui-kit-react'
import CustomBadge from '../UI/CustomBadge'
import Markdown from '..//UI/Markdown'
import styled from 'styled-components'
import Loader from '@chatscope/chat-ui-kit-react/dist/cjs/Loader'
import { IconButton, Tooltip } from '@material-ui/core'
import { MdOutlineErrorOutline } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

const ThreadMessage = ({ message, notification, showBadge }) => {
    const { t } = useTranslation()
    if (notification) return <MessageSeparator content={message} />

    let direction = null,
        sender = ''

    switch (message.type) {
        case 'notification':
            const marks = {
                fr: 'Profitez',
                en: 'Benefit'
            }

            let notificationMessage = message.message
            let extraMessage = null

            let index = notificationMessage.indexOf(marks.fr)
            if (index === -1) index = notificationMessage.indexOf(marks.en)

            if (index !== -1) {
                extraMessage = notificationMessage.slice(index)
                notificationMessage = notificationMessage.slice(0, index)
            }

            return (
                <>
                    <MessageSeparator children={notificationMessage} />
                    {Boolean(index) && (
                        <MessageSeparator
                            as='a'
                            href='https://app.okamedia.fr/auth/sign-up?specialOffer=wizdeo'
                            children={extraMessage}
                        />
                    )}
                </>
            )
        case 'admin':
            sender = `${message.user.firstname} ${message.user.lastname}`
            direction = 'incoming'
            break
        case 'talent':
            sender = `${message.talent.firstname} ${message.talent.lastname}`
            direction = 'outgoing'
            break
		 default:
    }

    return (
        <Wrapper>
            {showBadge ? <CustomBadge /> : null}
            <Message
                style={{ maxWidth: '90%' }}
                model={{
                    sender,
                    direction,
                }}
            >
                <Message.Header
                    className='message-header'
                    sender={sender}
                    sentTime={null}
                />
                <Message.CustomContent>
                    <Markdown>{message.message}</Markdown>
                </Message.CustomContent>
                <Message.Footer
                    className='message-footer'
                    sentTime={moment(message.created).format('LLL')}
                />
            </Message>
            {message.sending && (
                <LoaderContainer className='small_loader'>
                    <Loader />
                </LoaderContainer>
            )}
            {message.error && (
                <Tooltip arrow title={t('threads.error')}>
                    <IconButton>
                        <MdOutlineErrorOutline size={18} color='red' />
                    </IconButton>
                </Tooltip>
            )}
        </Wrapper>
    )
}

export default ThreadMessage

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

const LoaderContainer = styled.div`
    margin-left: 12px;
    height: 100%;
`
