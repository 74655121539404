import React, { useLayoutEffect } from 'react'
import Header from '../components/layout/Header'
import { Calendar, momentLocalizer } from 'react-big-calendar'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getAgendaData } from '../store/reducers/agendaReducer'
import Loader from '../components/UI/Loader'
import CustomToolbar from '../components/agenda/CustomToolbar'
import CustomEvent from '../components/agenda/CustomEvent'
import { getFormattedEvents } from '../helpers/agendaHelper'
import { useHistory } from 'react-router'

const AgendaPage = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { data, loading } = useSelector((state) => state.agenda)

    const load = () => {
        if (!data) {
            dispatch(getAgendaData({}))
        }
    }

    const handleEventClick = ({redirectPath}) => {
        if (redirectPath) history.push(redirectPath)
    }

    useLayoutEffect(load, [])

    if (!data || loading) {
        return <Loader />
    }

    const localizer = momentLocalizer(moment)
    const events = getFormattedEvents(data)

    return (
        <>
            <Header title='Agenda' />
                <div
                    style={{
                        height: '100%',
                        padding: 20,
                        backgroundColor: 'white',
                    }}
                >
                    <Calendar
                        popup
                        onSelectEvent={handleEventClick}
                        events={events}
                        views={['month']}
                        components={{
                            toolbar: CustomToolbar,
                            eventWrapper: CustomEvent,
                        }}
                        defaultDate={new Date()}
                        defaultView='month'
                        localizer={localizer}
                    />
                </div>
        </>
    )
}

export default AgendaPage
