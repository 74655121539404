import axios from 'axios'
import { updateBadges } from './store/reducers/badgesReducer'
import { updateTalent } from './store/reducers/talentReducer'

let options = {
    baseURL:  process.env.REACT_APP_API_URL,
    responseType: 'json',
}
if ( process.env.DEBUG ) {
    options.params = {
        XDEBUG_SESSION: 'WizApp-Web'
    }
}

export const API = axios.create( options );

export const handleResponse = (res, thunk) => {
    if (res.data.badge) {
        thunk.dispatch(updateBadges(res.data.badge))
    }

    if (res.data.talent) {
        thunk.dispatch(updateTalent(res.data.talent))
    }

    return res
}

export const handleError = (error) => {
    console.log(`error`, error)
}
