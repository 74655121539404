import React from 'react'
import { ButtonBase } from '@material-ui/core'
import styled from 'styled-components'

const VideoInfos = ({ video }) => {
    return (
        <Wrapper>
            {Boolean(video.thumbnail) && (
                <ButtonBase onClick={() => window.open(video.url, '_blank')}>
                    <Thumbnail src={video.thumbnail} />
                </ButtonBase>
            )}
            <Title target='_blank' href={video.url}>
                {video.title}
            </Title>
        </Wrapper>
    )
}

export default VideoInfos

const Title = styled.a`
    width: 200px;
    margin-left: 16px;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

const Thumbnail = styled.img`
    aspect-ratio: 16/9;
    height: 75px;
    border-radius: 5px;
    object-fit: cover;
`
