import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import appstore_fr_small from '../../assets/img/badges/appstore_fr_36.png'
import playstore_fr_small from '../../assets/img/badges/playstore_fr_36.png'
import appstore_en_small from '../../assets/img/badges/appstore_en_36.png'
import playstore_en_small from '../../assets/img/badges/playstore_en_36.png'
import appstore_fr from '../../assets/img/badges/appstore_fr_48.png'
import playstore_fr from '../../assets/img/badges/playstore_fr_48.png'
import appstore_en from '../../assets/img/badges/appstore_en_48.png'
import playstore_en from '../../assets/img/badges/playstore_en_48.png'
import { app_links } from '../../helpers/authHelper'
import { useMediaQuery } from '@material-ui/core'

const MobileAppButton = ({ os }) => {
    const { i18n, t } = useTranslation()
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'))

    const sources = {
        fr: {
            android: isSmallScreen ? playstore_fr_small : playstore_fr,
            ios: isSmallScreen ? appstore_fr_small : appstore_fr,
        },
        en: {
            android: isSmallScreen ? playstore_en_small : playstore_en,
            ios: isSmallScreen ? appstore_en_small : appstore_en,
        },
    }

    const handleClick = () => {
        window.open(app_links[os], '_blank').focus()
    }

    return (
        <Wrapper onClick={handleClick} padding={isSmallScreen ? '8px' : '16px'}>
            <img
                className='clickable'
                src={sources[i18n.language][os]}
                alt={ t( 'login.app_badges_alt' ) }
            />
        </Wrapper>
    )
}

export default MobileAppButton

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.padding};
`
