import moment from "moment"
import { translate } from '../i18n/i18n'

const labels = () => ({
    deal: translate('agenda.type.deal'),
    task: translate('agenda.type.task')
})

export const getFormattedEvents = (data) => {
    const events = []

    Object.keys(data).forEach((day) => {
        data[day].forEach((item) => {
            let dealId = item.type === 'deal' ? item.id : item.deal.id
            let redirectPath = `/deals/view/${dealId}`
            let color = item.type === 'deal' ? 'red' : 'orange'
            const newEvent = {
                title: `${labels()[item.type]} - ${item.title}`,
                start: moment(day),
                end: moment(day),
                redirectPath,
                color
            }
            events.push(newEvent)
        })
    })

    return events
}
