import { API, handleError, handleResponse } from "../../api"
import { getUnreadThreads } from "../reducers/threadsReducer"

export const getThreadAction = (payload, thunk) => {
    const {id, page = 1} = payload
    const params = {page}

    return API.get(`threads/view/${id}.json`, {params})
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}

export const sendMessageAction = (payload, thunk) => {
    return API.post('threads/sendMessage.json', payload)
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}

export const clearNewMessagesAction = (payload, thunk) => {
    return API.post('threads/messages-read.json', payload)
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
        .finally(() => thunk.dispatch(getUnreadThreads()))
}

export const getNextPageAction = (payload, thunk) => {
    const { threadId, page } = payload
    const options = {
        params: {
            page,
        },
    }
    return API.get(`threads/view/${threadId}.json`, options)
        .then((res) => handleResponse(res, thunk))
        .catch(handleError)
}

export const getUnreadThreadsAction = (payload, thunk) => {
    return API.get('threads/unread.json')
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}