import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Tooltip,
	Typography,
	Paper,
} from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import Carousel, {
	arrowsPlugin,
	slidesToShowPlugin,
	slidesToScrollPlugin,
} from '@brainhubeu/react-carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import NoData from 'components/UI/NoData'
import { useTranslation } from 'react-i18next'
import { FaCopyright } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import badgeWizdeo from '../../assets/img/badge_wizdeo.jpg'
import emptyImg from '../../assets/img/empty-img.png'

const BrandsCarousel = ({ brands }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const handleClick = brand => {
		history.push({
			pathname: `/brands/search/${brand.id}`,
			state: { brand },
		})
	}

	return brands && brands.length > 0 ? (
		<CarouselWrapper>
			<Carousel
				plugins={[
					{
						resolve: arrowsPlugin,
						options: {
							arrowLeft: (
								<CarouselButtonWrapper
									$left
									className='carousel-button-wrapper'
								>
									<CarouselButton>
										<CarouselButtonBg $left />
										<CarouselButtonIcon>
											<FontAwesomeIcon
												icon={faChevronLeft}
											/>
										</CarouselButtonIcon>
									</CarouselButton>
								</CarouselButtonWrapper>
							),
							arrowLeftDisabled: <></>,
							arrowRight: (
								<CarouselButtonWrapper
									$right
									className='carousel-button-wrapper'
								>
									<CarouselButton>
										<CarouselButtonBg $right />
										<CarouselButtonIcon>
											<FontAwesomeIcon
												icon={faChevronRight}
											/>
										</CarouselButtonIcon>
									</CarouselButton>
								</CarouselButtonWrapper>
							),
							arrowRightDisabled: <></>,
							addArrowClickHandler: true,
						},
					},
					{
						resolve: slidesToShowPlugin,
						options: { numberOfSlides: 5 },
					},
					{
						resolve: slidesToScrollPlugin,
						options: {
							numberOfSlides: 5,
						},
					},
				]}
				breakpoints={{
					1100: {
						plugins: [
							{
								resolve: slidesToShowPlugin,
								options: {
									numberOfSlides: 3,
								},
							},
							{
								resolve: slidesToScrollPlugin,
								options: {
									numberOfSlides: 3,
								},
							},
						],
					},
					531: {
						plugins: [
							{
								resolve: slidesToShowPlugin,
								options: {
									numberOfSlides: 1,
								},
							},
							{
								resolve: slidesToScrollPlugin,
								options: {
									numberOfSlides: 1,
								},
							},
						],
					},
				}}
			>
				{brands.map(brand => (
					<CarouselItemWrapper key={brand.id}>
						<CardActionArea onClick={() => handleClick(brand)}>
							<Card component={Paper}>
								<CardMedia
									component='img'
									height={150}
									image={brand.logo}
									alt={brand.name}
									onError={e => {
										e.target.onerror = null
										e.target.src = emptyImg
									}}
								/>
								<CardContent style={{ display: 'flex' }}>
									<Tooltip title={brand.name} arrow>
										<Typography noWrap>
											{brand.name}
										</Typography>
									</Tooltip>
									{brand.wizdeo_sponsored_videos_2y > 0 && (
										<sup>
											<Tooltip
												title={t('brand.wizdeo_client')}
												placement='left'
												arrow
											>
												<img
													src={badgeWizdeo}
													style={{ marginLeft: 5 }}
													alt={t(
														'brand.wizdeo_client'
													)}
													height={16}
													width={16}
												/>
											</Tooltip>
										</sup>
									)}
								</CardContent>
							</Card>
						</CardActionArea>
					</CarouselItemWrapper>
				))}
			</Carousel>
		</CarouselWrapper>
	) : (
		<Card>
			<CardContent>
				<NoData icon={<FaCopyright />} />
			</CardContent>
		</Card>
	)
}

export default BrandsCarousel

const CarouselWrapper = styled.div`
	position: relative;
	&:hover .carousel-button-wrapper {
		opacity: 1;
	}
`

const CarouselButtonWrapper = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	${props => props.$left && 'left: 0;'}
	${props => props.$right && 'right: 0;'}
	opacity: 0;
	transition: 0.5s;
	z-index: 1;
`

const CarouselButton = styled.div`
	position: relative;
	font-size: 1.8em;
	color: white;
	font-weight: bold;
	height: 100%;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1;
`

const CarouselButtonBg = styled.div`
	background-color: black;
	opacity: 0.5;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 0;
	${props =>
		props.$left &&
		'border-top-left-radius: 4px; border-bottom-left-radius: 4px;'}
	${props =>
		props.$right &&
		'border-top-right-radius: 4px; border-bottom-right-radius: 4px;'}
`

const CarouselButtonIcon = styled.div`
	z-index: 1;
`

const CarouselItemWrapper = styled.div`
	width: 100%;
	padding: 5px;
`
