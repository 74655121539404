import toast from 'react-hot-toast'
import { getDeals } from '../store/reducers/dealsReducer'
import { getThread } from '../store/reducers/threadsReducer'
import { getUnreadThreads } from '../store/reducers/threadsReducer'
import store from '../store/store'
import { Notification } from '../components/UI/notifications'
import {
    FaCheckCircle,
    FaClock,
    FaExclamationCircle,
    FaTimesCircle,
    FaComment,
} from 'react-icons/fa'
import { getTasks } from '../store/reducers/tasksReducer'

const ICONS = {
    success: <FaCheckCircle color='green' size={18} />,
    clock: <FaClock color='orange' size={18} />,
    error: <FaTimesCircle color='red' size={18} />,
    alert: <FaExclamationCircle color='red' size={18} />,
    message: (iconUrl = null) => iconUrl ? (
        <img
            src={iconUrl}
				alt="icon"
            style={{
                height: 18,
                aspectRatio: 1,
                marginRight: 12,
                borderRadius: 100,
            }}
        />
    ) : <FaComment size={18} />,
}

const updateDeals = (types = []) => {
    types.forEach((type) => {
        store.dispatch(getDeals({ status: type }))
    })
}

const updateTasks = (types = []) => {
    types.forEach((type) => {
        store.dispatch(getTasks(type))
    })
}

const updateThreads = () => store.dispatch(getUnreadThreads())

const notify = (title, body, icon, redirectPath) => {
    toast.custom(
        <Notification
            title={title}
            body={body}
            icon={icon}
            redirectPath={redirectPath}
        />
    )
}

const getThreadIdFromNotification = (path) =>
    path.replace('wizapp://thread/', '')

export const handleNotification = async ({ data }) => {
    console.log(`New notification`, data)
    const { title, body, type, path, iconUrl = null, dealId } = data
    const { thread_id } = store.getState().talent.data


    let showNotification = true,
        icon = null,
        redirectPath = null

    if (dealId) redirectPath = `/deals/view/${dealId}`

    // On effectue différentes actions selon le type de la notification
    switch (type) {
        case 'approved':
            redirectPath = '/home'
            icon = ICONS.success
            break
        case 'sent_reminder':
        case 'reminder':
            icon = ICONS.alert
            break

        // DEALS
        // On set l'icône de la notification et l'action au clic
        // puis on update le store en fonction du type de notif
        case 'sent':
            icon = ICONS.alert
            updateDeals(['sent', 'done'])
            break
        case 'refuse':
            icon = ICONS.error
            updateDeals(['sent', 'done'])
            break
        case 'in_progress':
            icon = ICONS.success
            updateDeals(['approved', 'in_progress'])
            break
        case 'checking_failed':
            icon = ICONS.error
            break
        case 'payment':
            icon = ICONS.clock
            updateDeals(['in_progress', 'done'])
            break
        case 'done':
            icon = ICONS.success
            updateDeals(['in_progress', 'done'])
            break

        // TASKS
        case 'checking_to_in_progress':
            icon = ICONS.clock
            updateTasks(['checking', 'in_progress'])
            break
        case 'checking_to_done':
            icon = ICONS.success
            updateTasks(['checking', 'done'])
            break

        // Threads
        case 'new_message':
            const threadId = getThreadIdFromNotification(path)

            if (dealId) redirectPath = `/deals/view/${dealId}`

            icon = ICONS.message(iconUrl)

            if (threadId === thread_id) {
                redirectPath = '/chat'
                if (window.location.pathname.includes(`/chat`)) {
                    showNotification = false
                }
            } else {
                if (window.location.pathname.includes(`/deals/view/${dealId}`)) {
                    showNotification = false
                }
            }
            store.dispatch(getThread({ id: threadId, reload: true }))

            // Mise à jour des threads non-lus
            updateThreads()
            break
		 default:
    }

    if (showNotification) notify(title, body, icon, redirectPath)
}
