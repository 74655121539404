import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fr from './locales/fr.json'
import en from './locales/en.json'
import { getCookie } from '../helpers/helpers'

i18n.use(initReactI18next).init({
    fallbackLng: ['en', 'fr'],
    lng: 'fr',
    resources: {
        fr: {
            translation: fr,
        },
        en: {
            translation: en,
        },
    },
    interpolation: {
        escapeValue: false,
    },
    react: {
        bindI18n: 'loaded languageChanged',
        bindI18nStore: 'added',
        useSuspense: true,
    },
})

export default i18n

/**
 * Fonction de traduction en dehors des composants React
 * (permet de traduire vers le bon language via le cookie)
 * 
 * @param {string} str 
 * @returns {string} Texte traduit
 */
 export const translate = (str, options = {}) => {
    return i18n.t(str, { lng: getCookie('lang', 'fr'), ...options})
  }
