import React from 'react'

const CustomButton = ({
    title,
    icon: Icon,
    handleClick,
    type = 'text',
    inverse,
    color,
    disabled,
    active,
    small
}) => {
    let classes = `btn-${type} waves-effect ${disabled && 'disabled'} ${active && 'active'} ${color ? 'text-'+ color : null}`
    let iconStyle = { marginRight: title ? 10 : 0 }
    if (color) iconStyle.color = color

    if (inverse) classes += ' btn-text-inverse'

    return (
        <button
            disabled={disabled}
            onClick={handleClick}
            className={`btn custom-btn ${classes}`}
        >
            {Icon ? <Icon className='icon' style={iconStyle} /> : null}
            {title ? title : null}
        </button>
    )
}

export default CustomButton
