import React from "react";
import { FaStar } from "react-icons/fa";

function StarRank({ maxStars, fullStars }) {
	let stars = [];

	for (let i = 1; i <= (maxStars || 5); i++) {
		stars.push(<FaStar key={i} color={i <= fullStars ? '#e7711b' : null} />);
	}

	return stars;
}

export default StarRank;
