import { API, handleError, handleResponse } from '../../api'

export const getRoyaltiesAction = (payload, thunk) => {
    const { type, status, page } = payload
    const config = {
		params: {
			statuses_version: 2,
			page: page.toString(),
			status
		},
    }

    return API.get(`royalties/${type}.json`, config)
        .then(res => handleResponse(res, thunk))
        .catch(handleError)
}
