import React from 'react'
import { useTranslation } from 'react-i18next'
import {  Button, TextField, InputAdornment } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { formatCurrencies } from '../../../helpers/numbersHelper'
import { manageDeal } from '../../../store/reducers/dealsReducer'
import Modal from '../Modal'
import styled from 'styled-components'
import { FaDollarSign, FaEuroSign } from 'react-icons/fa'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { useCookie } from 'react-use'

const NegociationModal = ({ deal, isOpen, onRequestClose }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { trackEvent, pushInstruction } = useMatomo()
	const { data: talent } = useSelector((state) => state.talent)
	const [languageCookie] = useCookie('lang')

	const [amount, setAmount] = React.useState(deal.amount - deal.wizdeo_commission_amount)
	const [comment, setComment] = React.useState('')

	const handleAmountChange = (e) => setAmount(e.target.value)
	const handleCommentChange = (e) => setComment(e.target.value)

	const negociate = () => {
		if (
			window.confirm(
					t('deals.modal.negociate.prompt', {
						amount: formatCurrencies(Number(amount), deal.currency),
					})
			)
		) {
			let payload = {
					dealNegociate: true,
					dealNegociateAmount: amount,
					dealNegociateComment: comment?.trim(),
					dealId: deal.id,
					previousStatus: deal.status,
					newStatus: 'negociation',
			}

			dispatch(manageDeal(payload))

			// Story #2550 Matomo integration
			let event = {
				category: 'Brand Content',
				action: 'Deal negociated',
				name: deal.campaign.title,
				value: deal.id,
				customDimensions: [
					{
						id: 1,
						value: languageCookie
					},
					{
						id: 2,
						value : process.env.REACT_APP_VERSION
					}
				]
			}

			pushInstruction('setUserId', talent.id)
			trackEvent(event)
		}
	}

	const disabled = !amount || amount == (deal.amount - deal.wizdeo_commission_amount)

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={t('deals.modal.negociate.title', {
					campaign: deal?.campaign?.title,
			})}
			description={t('deals.modal.negociate.message')}
			actions={
					<Button
						onClick={negociate}
						disabled={disabled}
						color='primary'
						variant='contained'
					>
						{t('deals.negociate')}
					</Button>
			}
		>
			<NumberInput
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
									{deal.currency === 'USD' ? (
										<FaDollarSign />
									) : (
										<FaEuroSign />
									)}
							</InputAdornment>
						),
					}}
					required
					label='Montant'
					type='number'
					value={amount}
					onChange={handleAmountChange}
			/>
			<TextInput
					label='Commentaire'
					multiline
					maxRows={5}
					value={comment}
					onChange={handleCommentChange}
			/>
		</Modal>
	)
}

export default NegociationModal

const NumberInput = styled(TextField)`
   width: 100%;
`

const TextInput = styled(TextField)`
	width: 100%;
	margin-top: 24px;
`
