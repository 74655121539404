import { API, handleError, handleResponse } from '../../api'

export const getChannelGeneralAction = (payload, thunk) => {
	const options = {
		params: {
			channelId: payload.channelId,
		},
	}

	return API.get('channels/general.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getChannelPricingsAction = (payload, thunk) => {
	const options = {
		params: {
			channelId: payload.channelId,
		},
	}

	return API.get('channels/pricings.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getChannelYoutubeAction = (payload, thunk) => {
	const options = {
		params: {
			channelId: payload.channelId,
		},
	}

	return API.get('channels/youtube.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getChannelTwitchAction = (payload, thunk) => {
	const options = {
		params: {
			channelId: payload.channelId,
		},
	}

	return API.get('channels/twitch.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const searchChannelsAction = (payload, thunk) => {
	const { keywords, page = 1 } = payload

	const options = {
		params: {
			keywords,
			page,
		},
	}

	return API.get('channels/search.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}
