import { API, handleError, handleResponse } from '../../api'

export const loginAction = (payload, thunk) => {
	return API.post('talents/login.json', payload)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const editAction = (payload, thunk) => {
	return API.post('talents/edit.json', payload)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const deleteTalentAction = async payload => {
	try {
		const res = await API.get('talents/delete_account.json', payload)
		return res
	} catch (error) {
		return handleError(error)
	}
}

export const addFavoriteChannelAction = (payload, thunk) => {
	return API.post('talentFavoriteChannels/add.json', payload)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const deleteFavoriteChannelAction = (payload, thunk) => {
	return API.delete(`talentFavoriteChannels/delete/${payload.channelId}.json`)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}
