import axios from "axios"

export const getVideoMetadata = async (id = null) => {
    if (!id) return null

    const url = `https://youtu.be/${id}`
    const path = `https://www.youtube.com/oembed?url=${url}&format=json`

    try {
        const res = await axios.get(path)

        if (res.status !== 200) throw new Error()

        return {
            id,
            url,
            ...res.data
        }
    } catch (err) {
        console.log('err', err)
        return null
    }
}