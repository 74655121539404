import React from 'react'

const CustomBadge = ({ value, backgroundColor = '#e51c23'  }) => {
    if (value <= 0) return null
    return (
            <span
                style={{
                    backgroundColor,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    height: value ? 22 : 10,
                    minWidth: value ? 22 : 10,
                    borderRadius: 100,
                    padding: value ? '0 6px' : 0,
                    marginRight: value ? 0 : 10,
                    color: 'white',
                    fontSize: '0.65rem',
                }}
            >
                {value}
            </span>

    )
}

export default CustomBadge
