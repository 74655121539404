import React from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = ({
    light = false,
    id,
    width,
}) => {
    const url = `http://youtu.be/${id}`

    return (
        <ReactPlayer
            controls
            height={width * 9 / 16}
            width={width}
            light={light}
            url={url}
        />
    )
}

export default VideoPlayer
