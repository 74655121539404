import React, { useEffect, useState } from 'react'
import CustomSelect from '../UI/CustomSelect'
import Filters from '../UI/Filters'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getGmData } from '../../store/reducers/gmReducer'
import Loader from '../UI/Loader'
import {
	capitalize,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	formatCurrencies,
	formatMultipleCurrencies,
	formatPercentage,
} from '../../helpers/numbersHelper'
import ProgressBar from '../UI/ProgressBar'
import { getContractName, getGmProgressColor } from '../../helpers/gmHelper'
import styled from 'styled-components'
import Avatar from '../UI/Avatar'

const GmRecap = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [currentIndex, setCurrentIndex] = useState(0)
	const { data, loading } = useSelector(state => state.gm)

	const load = () => {
		if (!data && !loading) dispatch(getGmData())
	}

	useEffect(load, [])

	let content

	if (!data || loading) {
		content = <Loader />
	} else {
		const {
			total_amount,
			amount_to_date,
			average_per_month,
			percentage_to_date,
			contract_start_date,
			contract_end_date,
			months,
			channels,
		} = data.contracts[currentIndex]

		content = (
			<Grid container spacing={3}>
				<Grid xs={12} item>
					<TableContainer component={Paper}>
						<TableHeader>
							<TableTitle>
								{getContractName(data.contracts[currentIndex])}
							</TableTitle>
							<ProgressWrapper>
								<ProgressBar
									backgroundColor={getGmProgressColor(
										percentage_to_date
									)}
									value={formatPercentage(
										percentage_to_date.done,
										true
									)}
									extraValue={formatPercentage(
										percentage_to_date.target,
										true
									)}
									min={moment(contract_start_date).format(
										'LL'
									)}
									max={moment(contract_end_date).format('LL')}
								/>
							</ProgressWrapper>

							<TableSubtitle>
								{t('gm.related_channels')}
							</TableSubtitle>
							<RelatedChannels>
								{channels.map(channel => (
									<Avatar
										key={channel.id}
										src={channel.logo}
										label={channel.display_name}
									/>
								))}
							</RelatedChannels>
						</TableHeader>
						<Table>
							<THead>
								<TRow>
									<TCell />
									<TCell align='center'>
										{t('gm.target')}
									</TCell>
									<TCell align='center'>{t('gm.done')}</TCell>
								</TRow>
							</THead>
							<TableBody>
								<TRow>
									<TCell>{t('gm.total_amount')}</TCell>
									<TCell align='center'>
										{formatCurrencies(total_amount.target)}
									</TCell>
									<TCell align='center'>
										{formatCurrencies(total_amount.done)}
									</TCell>
								</TRow>
								<TRow>
									<TCell>{t('gm.amount_to_date')}</TCell>
									<TCell align='center'>
										{formatCurrencies(
											amount_to_date.target
										)}
									</TCell>
									<TCell align='center'>
										{formatCurrencies(amount_to_date.done)}
									</TCell>
								</TRow>
								<TRow>
									<TCell>{t('gm.percentage_to_date')}</TCell>
									<TCell align='center'>
										{
											formatPercentage(
												percentage_to_date.target
											).value
										}
									</TCell>
									<TCell
										align='center'
										color={getGmProgressColor(
											amount_to_date
										)}
										bold
									>
										{
											formatPercentage(
												percentage_to_date.done
											).value
										}
									</TCell>
								</TRow>
							</TableBody>
							<TFoot>
								<TRow>
									<TCell>Total</TCell>
									<TCell />
									<TCell align='center'>
										{formatCurrencies(amount_to_date.done)}
									</TCell>
								</TRow>
							</TFoot>
						</Table>
					</TableContainer>
				</Grid>
				<Grid xs={12} item>
					<TableContainer component={Paper}>
						<TableHeader>
							<TableTitle>{t('gm.monthly_recap')}</TableTitle>
						</TableHeader>
						<Table>
							<THead>
								<TRow>
									<TCell>{t('gm.month')}</TCell>
									<TCell align='center'>Total</TCell>
								</TRow>
							</THead>
							<TableBody>
								{Object.entries(months).map(
									([month, values]) => (
										<TRow>
											<TCell>
												{capitalize(
													moment(`${month}01`).format(
														'MMMM YYYY'
													)
												)}
											</TCell>
											<TCell align='center'>
												{formatMultipleCurrencies(
													values
												)}
											</TCell>
										</TRow>
									)
								)}
							</TableBody>
							<TFoot>
								<TRow>
									<TCell>{t('gm.average_per_month')}</TCell>
									<TCell align='center'>
										{formatMultipleCurrencies(
											average_per_month
										)}
									</TCell>
								</TRow>
							</TFoot>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		)
	}

	const options = data?.contracts
		? data.contracts.map((contract, index) => ({
				label: getContractName(contract),
				value: index,
		  }))
		: []

	return (
		<>
			{Boolean(data?.contracts) && (
				<Filters>
					<CustomSelect
						value={currentIndex}
						onChange={({ value }) => setCurrentIndex(value)}
						options={options}
					/>
				</Filters>
			)}
			{content}
		</>
	)
}

export default GmRecap

const ProgressWrapper = styled.div`
	padding: 18px;
	margin-top: 18px;
	border-radius: 8px;
	background-color: #f5f5f5;
`

const TableHeader = styled.div`
	padding: 12px 16px 0;
`
const TableTitle = styled.div`
	font-weight: bold;
	font-size: 18px;
`

const TableSubtitle = styled.div`
	font-weight: 500;
	font-size: 14px;
	color: grey;
	margin: 12px 0 6px;
`

const RelatedChannels = styled.div`
	display: flex;
	gap: 24px;
`

const THead = styled(TableHead)`
	tr {
		background: transparent !important;
		border-bottom: 1px solid #e0e0e0 !important;
		th {
			background: transparent !important;
			font-weight: 600;
		}
		&:hover {
			background: transparent !important;
		}
	}
`

const TFoot = styled(TableFooter)`
	tr {
		border-top: 1px solid #e0e0e0;
		td {
			background-color: white;
			font-weight: 600;
			font-size: 0.875rem;
			color: black;
		}
		&:hover {
			background: inherit;
		}
	}
`

const TRow = styled(TableRow)`
	border-bottom: 0;
`

const TCell = styled(TableCell)`
	border-bottom: 0;
	${props => (props.color ? `color: ${props.color};` : null)}
	${props => (props.bold ? `font-weight: bold;` : null)}
    padding: 12px;
`
