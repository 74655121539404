import React from 'react'
import { GoogleLogin } from '@react-oauth/google'
import styled from 'styled-components'
import { onLoginFailure, onLoginSuccess } from '../../helpers/authHelper'

const GoogleLoginButton = () => {
	return (
		<Wrapper>
			<GoogleLogin
				onSuccess={onLoginSuccess}
				onError={onLoginFailure}
				auto_select
				useOneTap
			/>
		</Wrapper>
	)
}

export default GoogleLoginButton

const Wrapper = styled.div`
	display: inline-flex;
	align-self: center;
	margin: 36px 0;
	transition: box-shadow 0.3s;
	&:hover {
		-webkit-box-shadow: 0px 0px 5px 0px #2c94ff;
		box-shadow: 0px 0px 5px 0px #2c94ff;
	}
`
