import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faBookmarkInactive } from '@fortawesome/pro-light-svg-icons'
import { faStar as faBookmarkActive } from '@fortawesome/pro-solid-svg-icons'


import { faCircleNotch } from '@fortawesome/pro-light-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import {
	addFavoriteChannel,
	deleteFavoriteChannel,
} from 'store/reducers/talentReducer'

const FavoriteChannel = ({ channelId }) => {
	const dispatch = useDispatch()
	const { data: talent, updating } = useSelector(state => state.talent)
	const isFavorite = talent.favorite_channels
		? talent.favorite_channels.filter(channel => channel.id === channelId)
				.length > 0
		: false
	const handleClick = () => {
		const payload = {
			channelId: channelId,
		}

		if (isFavorite) {
			dispatch(deleteFavoriteChannel(payload))
		} else {
			dispatch(addFavoriteChannel(payload))
		}
	}

	return (
		<>
			{updating ? (
				<FontAwesomeIcon icon={faCircleNotch} color='white' spin={true} size='xl'/>
			) : (
				<FontAwesomeIcon
					style={{cursor:'pointer'}}
					icon={isFavorite ? faBookmarkActive : faBookmarkInactive}
					color={isFavorite ? '#ff9800' : 'white'}
					size='xl'
					onClick={handleClick}
				/>
			)}
		</>
	)
}

export default FavoriteChannel
