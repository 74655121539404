import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import Note from '../UI/Note'
import TaskCard from './TaskCard'

const TasksList = ({ tasks, dealStatus }) => {
    const match = useRouteMatch()
    const { t } = useTranslation()

    if (!tasks || !(tasks instanceof Array)) return null

    if (!tasks.length) return <Note message={t('tasks.list.empty')} />

    return tasks.map((task, index) => (
        <TaskCard
            key={index}
            redirect={match.path.includes('tasks')}
            index={index + 1}
            dealStatus={dealStatus}
            task={task}
        />
    ))
}

export default TasksList
