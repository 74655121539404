import i18n from "../i18n/i18n"

export const formatCurrencies = (value = 0, currency = 'EUR') => {
    if (typeof value === 'number') {
        const lang = i18n.language === 'fr' ? 'fr-FR' : 'en-US'
        const formattedValue = new Intl.NumberFormat(lang, {
            style: 'currency',
            currency,
        }).format(value)
        return currency === 'USD' ? formattedValue.replace('US', '') : formattedValue
    } 
    return '-'
}

export const formatPercentage = (value) => {
    if (typeof value === 'number') {
        const formattedValue = Intl.NumberFormat('fr-FR', {
            style: 'percent',
            maximumFractionDigits: 0,
        }).format(value / 100)

        const rawValue = Intl.NumberFormat('fr-FR', {
            maximumFractionDigits: 0
        }).format(value)
        return {
            value: formattedValue,
            raw: rawValue
        }
    }
    return '-'
}

export const formatMultipleCurrencies = (data) => {
    if (data instanceof Array && !data.length) return formatCurrencies()

    if (data instanceof Object) {
        const keys = Object.keys(data)
        const formattedValues = keys.map((key) =>
            !data[key] ? formatCurrencies() : formatCurrencies(data[key], key)
        )
        return formattedValues.map(value => (
            <span>{value}<br></br></span>
        ))
    }

    return '-'
}

export const shorten = (number, decimals) => {
    let divisors = {
        0: '', // 1000^0 == 1
        1: 'K', // Thousand
        2: 'M', // Million
        3: 'B', // Billion
        4: 'T', // Trillion
        5: 'Qa', // Quadrillion
        6: 'Qi', // Quintillion
    }
    let divisor
    let shorthand
    let result

    for (let [divisorIndex, divisorShorthand] of Object.entries(divisors)) {
        divisor = Math.pow(1000, divisorIndex)
        shorthand = divisorShorthand
        if (number < divisor * 1000) {
            break
        }
    }

    result = parseFloat(number) / parseFloat(divisor)

    if (parseInt(result) - result === 0) result = parseInt(result)

    //handle decimals render :
    if (decimals >= 0) {
        result =
            Math.round(result * Math.pow(10, decimals)) / Math.pow(10, decimals)
    }

    return result + shorthand
}

export const formatOrdinals = (number) => {
    const locale = i18n.language;
    const pluralRules = new Intl.PluralRules(locale, { type: 'ordinal' })
    
    const suffixes = new Map([
        ['one', 'st'],
        ['two', 'nd'],
        ['few', 'rd'],
        ['other', 'th'],
    ])

    const rule = pluralRules.select(number)
    const suffix = suffixes.get(rule)

    return `${number}${suffix}`
}
