import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    FaExclamationCircle,
    FaFire,
    FaRegCheckSquare,
    FaRegDotCircle,
} from 'react-icons/fa'
import { useHistory } from 'react-router'
import CustomButton from '../../UI/CustomButton'

const BrandContentCard = ({ type, value }) => {
    const history = useHistory()
    const { t } = useTranslation()
    let title, Icon

    const classes = {
        card: 'card ',
        icon: 'bc-card-icon text-lighten-2 ',
        card_content: 'card-content text-center ',
        card_action: 'card-actions ',
        action_text: 'btn btn-text waves-effect ',
    }

    switch (type) {
        case 'sent':
            title = t('deals.status.sent')
            Icon = FaExclamationCircle
            classes.card += 'text-white red lighten-1'
            classes.icon += 'text-red'
            classes.card_action += 'red darken-1'
            break
        case 'approved':
            title = t('deals.status.approved')
            Icon = FaFire
            classes.card += 'text-white orange lighten-1'
            classes.icon += 'text-orange'
            classes.card_action += 'orange darken-1'
            break
        case 'in_progress':
            title = t('deals.status.in_progress')
            Icon = FaRegDotCircle
            classes.card += 'text-white green lighten-1'
            classes.icon += 'text-green'
            classes.card_action += 'green darken-1'

            break
        case 'tasks':
            title = t('tasks.status.in_progress_title')
            classes.icon += 'text-grey'
            Icon = FaRegCheckSquare
            classes.card += 'text-black white lighten-1'
            classes.card_action += 'white darken-1'
            break
		 default:
    }

    const handleClick = () => {
        const path = type === 'tasks' ? '/tasks' : `/deals/${type}`
        history.push(path)
    }

    return (
        <Grid item xs={6} xl={3}>
            <div
                className={classes.card}
                style={{ height: '100%', marginBottom: 0 }}
            >
                <Icon className={classes.icon} />
                <div
                    className='bc-card-content card-content text-center'
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <p className='mt-5'>{title}</p>
                    <h1 className='mb-5'>{value}</h1>
                </div>
                <div className='card-actions' style={{ height: 50 }}>
                    <CustomButton
                        title={t('common.more')}
                        inverse={type !== 'tasks'}
                        handleClick={handleClick}
                    />
                </div>
            </div>
        </Grid>
    )
}

export default BrandContentCard
