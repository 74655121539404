import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Note from './Note'

const CustomTable2 = ({ columns, rows, page }) => {
    const { t } = useTranslation()
    return useMemo(
        () => {
            if (!rows.length) return <Note message={t('common.no_results')} />
           return ( <TableContainer className='mini-scroll' component={Paper}>
                <Table>
                    {columns ? (
                        <TableHead>
                            <TableRow>
                                {columns.map((col, index) => (
											   <TableCell
												    key={index}
                                        align={index > 0 ? 'center' : 'left'}
                                        style={{ fontSize: 14 }}
                                    >
                                        {col}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {rows ? <TableBody>{rows}</TableBody> : null}
                </Table>
            </TableContainer>)

        }
        ,
        [page]
    )
}

export default CustomTable2
