import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react"

const instance = createInstance({
	urlBase: process.env.REACT_APP_MATOMO_URL,
	siteId: process.env.REACT_APP_MATOMO_SITE_ID
})

const MatomoExtendedProvider = ({ children }) => {
	return <MatomoProvider value={instance}>{children}</MatomoProvider>
}

export default MatomoExtendedProvider
