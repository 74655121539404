import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { Fab } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ContactUsCard = () => {
	const { t } = useTranslation()

	const handleClick = () => {
		window.location = `mailto:${process.env.REACT_APP_WIZDEO_EMAIL}`
	}

	return (
		<ContactUsContainer>
			<Fab
				variant='extended'
				color='primary'
				onClick={() => handleClick()}
			>
				<FaEnvelope className='mr-5' />
				{t('common.contact_us')}
			</Fab>
		</ContactUsContainer>
	)
}

export default ContactUsCard

const ContactUsContainer = styled.div`
	display: flex;
	justify-content: center;
`
