import moment from 'moment'

import {
	FaCalendarAlt,
	FaHeadset,
	FaInstagram,
	FaPencilRuler,
	FaRegFlag,
	FaTwitter,
	FaYoutube,
	FaTwitch,
	FaTiktok,
} from 'react-icons/fa'
import Avatar from '../components/UI/Avatar'
import {formatCurrencies} from './numbersHelper'
import styled from 'styled-components'
import store from '../store/store'
import {translate} from '../i18n/i18n'

export const STATUS = {
	sent: 'sent',
	approved: 'approved',
	in_progress: 'in_progress',
	done: 'done',
	cancelled: 'cancelled',
	checking: 'checking',
	refused: 'refused',
	payment: 'payment',
	negociation: 'negociation',
}

export const LOST_REASONS = {
	brand_abandoned: 'brand_abandoned',
	brand_no_news: 'brand_no_news',
	brand_reject_offer: 'brand_reject_offer',
	brand_rejected_talent: 'brand_rejected_talent',
	brand_rejected_talent_format: 'brand_rejected_talent_format',
	brand_rejected_talent_no_priority: 'brand_rejected_talent_no_priority',
	brand_rejected_talent_price: 'brand_rejected_talent_price',
	talent_rejected_offer: 'talent_rejected_offer',
	talent_rejected_offer_gm: 'talent_rejected_offer_gm',
	talent_no_news: 'talent_no_news',
}

export const lostReasonsLabels = {
	[LOST_REASONS.brand_abandoned]: translate('deals.lost_reasons.labels.brand_abandoned'),
	[LOST_REASONS.brand_no_news]: translate('deals.lost_reasons.labels.brand_no_news'),
	[LOST_REASONS.brand_reject_offer]: translate('deals.lost_reasons.labels.brand_reject_offer'),
	[LOST_REASONS.brand_rejected_talent]: translate('deals.lost_reasons.labels.brand_rejected_talent'),
	[LOST_REASONS.brand_rejected_talent_format]: translate('deals.lost_reasons.labels.brand_rejected_talent_format'),
	[LOST_REASONS.brand_rejected_talent_no_priority]: translate('deals.lost_reasons.labels.brand_rejected_talent_no_priority'),
	[LOST_REASONS.brand_rejected_talent_price]: translate('deals.lost_reasons.labels.brand_rejected_talent_price'),
	[LOST_REASONS.talent_rejected_offer]: translate('deals.lost_reasons.labels.talent_rejected_offer'),
	[LOST_REASONS.talent_rejected_offer_gm]: translate('deals.lost_reasons.labels.talent_rejected_offer_gm'),
	[LOST_REASONS.talent_no_news]: translate('deals.lost_reasons.labels.talent_no_news'),
}

export const lostReasonsDescriptions = {
	[LOST_REASONS.brand_abandoned]: translate('deals.lost_reasons.descriptions.brand_abandoned'),
	[LOST_REASONS.brand_no_news]: translate('deals.lost_reasons.descriptions.brand_no_news'),
	[LOST_REASONS.brand_reject_offer]: translate('deals.lost_reasons.descriptions.brand_reject_offer'),
	[LOST_REASONS.brand_rejected_talent]: translate('deals.lost_reasons.descriptions.brand_rejected_talent'),
	[LOST_REASONS.brand_rejected_talent_format]: translate('deals.lost_reasons.descriptions.brand_rejected_talent_format'),
	[LOST_REASONS.brand_rejected_talent_no_priority]: translate('deals.lost_reasons.descriptions.brand_rejected_talent_no_priority'),
	[LOST_REASONS.brand_rejected_talent_price]: translate('deals.lost_reasons.descriptions.brand_rejected_talent_price'),
	[LOST_REASONS.talent_rejected_offer]: translate('deals.lost_reasons.descriptions.talent_rejected_offer'),
	[LOST_REASONS.talent_rejected_offer_gm]: translate('deals.lost_reasons.descriptions.talent_rejected_offer_gm'),
	[LOST_REASONS.talent_no_news]: translate('deals.lost_reasons.descriptions.talent_no_news'),
}


export const REFUSAL_REASONS = {
	not_interested: 'not_interested',
	not_in_my_edit_line: 'not_in_my_edit_line',
	not_available_too_short_delay: 'not_available_too_short_delay',
	dont_trust_brand_ethical_issue: 'dont_trust_brand_ethical_issue',
	too_low_price: 'too_low_price',
	work_with_similar_brand: 'work_with_similar_brand',
	other: 'other',
}

export const refusalReasonsLabels = {
	[REFUSAL_REASONS.not_interested]: translate(
		'deals.refusal_reasons.not_interested'
	),
	[REFUSAL_REASONS.not_in_my_edit_line]: translate(
		'deals.refusal_reasons.not_in_my_edit_line'
	),
	[REFUSAL_REASONS.not_available_too_short_delay]: translate(
		'deals.refusal_reasons.not_available_too_short_delay'
	),
	[REFUSAL_REASONS.dont_trust_brand_ethical_issue]: translate(
		'deals.refusal_reasons.dont_trust_brand_ethical_issue'
	),
	[REFUSAL_REASONS.too_low_price]: translate(
		'deals.refusal_reasons.too_low_price'
	),
	[REFUSAL_REASONS.work_with_similar_brand]: translate(
		'deals.refusal_reasons.work_with_similar_brand'
	),
	[REFUSAL_REASONS.other]: translate('deals.refusal_reasons.other'),
}

export const statusLabels = {
	[STATUS.sent]: translate('deals.status.short.sent'),
	[STATUS.approved]: translate('deals.status.short.approved'),
	[STATUS.in_progress]: translate('deals.status.short.in_progress'),
	[STATUS.done]: translate('deals.status.short.done'),
	[STATUS.cancelled]: translate('deals.status.short.cancelled'),
	[STATUS.checking]: translate('deals.status.short.checking'),
	[STATUS.refused]: translate('deals.status.short.refused'),
	[STATUS.payment]: translate('deals.status.short.payment'),
	[STATUS.negociation]: translate('deals.status.short.negociation'),
}

export const subtypes = {
	'Mention integration': {
		Icon: FaYoutube,
		color: 'youtube',
		label: translate('deals.subtypes.mention_integration'),
	},
	'YouTube full dedicated': {
		Icon: FaYoutube,
		color: 'youtube',
		label: translate('deals.subtypes.youtube_full_dedicated'),
	},
	'Instagram story': {
		Icon: FaInstagram,
		color: 'instagram',
		label: translate('deals.subtypes.instagram_story'),
	},
	'Instagram post': {
		Icon: FaInstagram,
		color: 'instagram',
		label: translate('deals.subtypes.instagram_post'),
	},
	Tweet: {
		Icon: FaTwitter,
		color: 'twitter',
		label: translate('deals.subtypes.tweet'),
	},
	Affiliation: {
		Icon: FaRegFlag,
		color: 'creator',
		label: translate('deals.subtypes.affiliation'),
	},
	'Live/Stream': {
		Icon: FaHeadset,
		color: 'twitch',
		label: translate('deals.subtypes.live_stream'),
	},
	Event: {
		Icon: FaCalendarAlt,
		color: 'creator',
		label: translate('deals.subtypes.event'),
	},
	Custom: {
		Icon: FaPencilRuler,
		color: 'creator',
		label: translate('deals.subtypes.custom'),
	},
}

export const realizations = {
	'YouTube mention': {
		Icon: FaYoutube,
		label: translate('deals.realizations.yt_mention'),
		label_plural: translate('deals.realizations.yt_mention_plural'),
		color: 'youtube',
	},
	'YouTube dedicated video': {
		Icon: FaYoutube,
		label: translate('deals.realizations.yt_dedicated'),
		label_plural: translate('deals.realizations.yt_dedicated_plural'),
		color: 'youtube',
	},
	'Instagram story': {
		Icon: FaInstagram,
		label: translate('deals.realizations.insta_story'),
		label_plural: translate('deals.realizations.insta_story_plural'),
		color: 'instagram',
	},
	'Instagram post': {
		Icon: FaInstagram,
		label: translate('deals.realizations.insta_post'),
		label_plural: translate('deals.realizations.insta_post_plural'),
		color: 'instagram',
	},
	'Twitter post': {
		Icon: FaTwitter,
		label: translate('deals.realizations.twitter_post'),
		label_plural: translate('deals.realizations.twitter_post_plural'),
		color: 'twitter',
	},
	'Twitch live': {
		Icon: FaTwitch,
		label: translate('deals.realizations.twitch_live'),
		label_plural: translate('deals.realizations.twitch_live_plural'),
		color: 'twitch',
	},
	'TikTok video': {
		Icon: FaTiktok,
		label: translate('deals.realizations.tiktok_video'),
		label_plural: translate('deals.realizations.tiktok_video_plural'),
		color: 'creator',
	},
	Custom: {
		Icon: FaPencilRuler,
		label: 'Custom',
		color: 'creator',
	},
}

/**
 * Récupère la raison de refus d'une offre (uniquement la raison zoho)
 * @param deal
 * @returns {null}
 */
export const getSimpleLostReason = (deal) => {
	if ( deal.status !== STATUS.cancelled && deal.status !== STATUS.refused ) return null;

	if ( deal.stage === 'closed_lost' && deal.lost_reason ) {
		return lostReasonsLabels[deal.lost_reason];
	}
	return null;
}


/**
 * Récupère la raison de refus d'une offre (Raison zoho et talent), version longue
 * @param deal
 * @returns {{label: string, value: *}}
 */
export const getLostReason = (deal) => {
	if ( deal.status !== STATUS.cancelled && deal.status !== STATUS.refused ) return null;

	const label = deal.status === STATUS.cancelled ? translate('deals.card_labels.lost_reason') : translate('deals.card_labels.refused_reason');
	let values = [];

	if ( deal.stage === 'closed_lost' && deal.lost_reason ) {
		values.push( lostReasonsDescriptions[deal.lost_reason] );
	}

	if ( deal.talent_refused_reason ) {
		values.push( refusalReasonsLabels[deal.talent_refused_reason] );
		if ( deal.talent_refused_reason === REFUSAL_REASONS.other && deal.talent_refused_reason_other) {
			values.push( `${deal.talent_refused_reason_other}` );
		}
	}

	if ( !values.length ) return null;

	return {
		'label': label,
		'values': values,
	}
}

export const getSubtypes = (deal, type = 'list') => {
	if (deal.realizations?.length) {
		let formattedRealizations = []
		for (const realization of deal.realizations) {
			if (realizations[realization.zoho_product_name]) {
				let {Icon, color, label} =
					realizations[realization.zoho_product_name]

				let element

				if (realization.zoho_product_name === 'Custom') {
					label = realization.instructions
						? realization.instructions
						: 'Custom'
				}

				if (type === 'list')
					element = (
						<Icon
							title={label}
							className={`text-${color} list-subtype`}
						/>
					)

				if (type === 'card')
					element = (
						<div className={`subtype bg-${color}`}>
							<Icon className='icon' size={20}/>{' '}
							<span style={{wordBreak: 'break-word'}}>
                                {label}{' '}
								{realization.quantity > 1
									? `(x${realization.quantity})`
									: null}
                            </span>
						</div>
					)

				formattedRealizations.push(element)
			}
		}
		return formattedRealizations
	} else if (deal.subtypes?.length) {
		let formattedSubtypes = []
		for (const subtype of deal.subtypes) {
			if (subtypes[subtype]) {
				const {Icon, color, label} = subtypes[subtype]
				let element

				if (type === 'list')
					element = (
						<Icon
							title={
								subtype === 'Custom'
									? deal.custom_subtype_label
									: label
							}
							className={`text-${color} list-subtype`}
						/>
					)

				if (type === 'card')
					element = (
						<div className={`subtype bg-${color}`}>
							<Icon className='icon' size={20}/>{' '}
							<span style={{wordBreak: 'break-word'}}>
                                {subtype === 'Custom'
											  ? deal.custom_subtype_label
											  : label}
                            </span>
						</div>
					)

				formattedSubtypes.push(element)
			}
		}
		return formattedSubtypes
	}
	return type === 'list' ? <span>-</span> : null
}

export const getColorByStatus = (status) => {
	switch (status) {
		case STATUS.sent:
		case STATUS.approved:
		case STATUS.negociation:
			return 'warning'
		case STATUS.done:
		case STATUS.in_progress:
		case STATUS.payment:
			return 'success'
		case STATUS.cancelled:
		case STATUS.refused:
			return 'danger'
		default:
			return 'blue'
	}
}

export const getColorByStatusAlt = (status) => {
	switch (status) {
		case STATUS.sent:
		case STATUS.approved:
		case STATUS.negociation:
			return '#f57c00'
		case STATUS.done:
		case STATUS.in_progress:
		case STATUS.payment:
			return '#388e3c'
		case STATUS.cancelled:
		case STATUS.refused:
			return '#d32f2f'
		default:
			return 'blue'
	}
}

export const getFormattedStatus = (deal) => {
	let title = null

	if (
		deal.status === STATUS.negociation &&
		deal.negociation_amount !== null
	) {
		title = formatCurrencies(deal.negociation_amount, deal.currency)
	}

	return (
		<span title={title}
				style={ { fontSize: 14, alignSelf: 'center', padding: 8, whiteSpace: 'nowrap', }} >
			{statusLabels[deal.status]}<br/>
			{ getSimpleLostReason(deal) ? getSimpleLostReason(deal) : null }
		</span>
	)
}

export const getEndDate = (deal) => {
	if (deal.end_date) {
		return moment(deal.end_date).format('ll')
	}
	return '-'
}

export const getRefusalReasonsOptions = () =>
	Object.keys(REFUSAL_REASONS).map((key) => ({
		label: refusalReasonsLabels[key],
		value: key,
	}))

export const getFilteredDeals = (deals, searchValue) => {
	if (!deals) return null

	return deals.filter((deal) =>
		deal.campaign.title.toLowerCase().includes(searchValue.toLowerCase())
	)
}

export const getAssociatedChannels = (deal) => {
	if (deal.linked_channels && deal.linked_channels.length) {
		return deal.linked_channels.map((channel, index) => {
			return (
				<Avatar
					key={index}
					src={channel.logo}
					label={channel.display_name}
				/>
			)
		})
	}
	return '-'
}

export const getCommission = (deal, displayConvertedAmount = false) => {
	if (deal.wizdeo_commission_amount) {
		return (
			<Commission>
				{formatCurrencies(deal.wizdeo_commission_amount, deal.currency)}
				{displayConvertedAmount && (
					<>
						<br />
						<Converted>{formatCurrencies(
							deal.converted_wizdeo_commission_amount,
							deal.converted_currency
						)}</Converted>
					</>
				)}
			</Commission>
		)
	}
}

export const getNetAmount = (deal, displayConvertedAmount = false) => {
	if (deal.amount) {
		return (
			<NetAmount>
				{formatCurrencies(
					deal.amount - deal.wizdeo_commission_amount,
					deal.currency
				)}
				{displayConvertedAmount && (
					<>
						<br />
						<Converted>{formatCurrencies(
							deal.converted_amount - deal.converted_wizdeo_commission_amount,
							deal.converted_currency
						)}</Converted>
					</>
				)}
			</NetAmount>
		)
	}
}

export const getInvoicingStatus = (deal) => {
	if (deal.status !== STATUS.payment && deal.status !== STATUS.done) return null;
	let status = translate('deals.waiting_invoice');
	let style = 'text-italic';

	if (deal.payment_date) {
		status = deal.is_estimated_payment_date ? translate('deals.estimated_invoice_paid') : translate('deals.invoice_paid');
		status += ' ' + moment(deal.payment_date).format('ll');

		style = deal.is_estimated_payment_date ? 'text-italic' : 'text-bold';
	}
	return (<span class={style}>{status}</span>);
}

export const gmApplicable = (deal) => {
	const {
		closing_date,
		created,
		wizdeo_commission_amount,
		amount,
		stage,
		lost_reason,
	} = deal

	const {gm} = store.getState().badges

	if (!gm || !gm.contracts) return null

	const date = closing_date ? moment(closing_date) : moment(created)
	let dateCondition = false

	for (const contract of gm.contracts) {
		const start = moment(contract.contract_start_date)
		const end = moment(contract.contract_end_date)

		if (date.isBetween(start, end)) {
			dateCondition = true
		}
	}

	const statusCondition =
		stage === 'closed_won' ||
		(stage === 'closed_lost' &&
			lost_reason === 'talent_rejected_offer')

	let margin = 0

	if (wizdeo_commission_amount && amount) {
		margin = (wizdeo_commission_amount / amount) * 100
	}

	if (margin > 5 && statusCondition && dateCondition) {
		return true
	}

	return false
}

const Commission = styled.span`
    font-size: 14px;
`

const NetAmount = styled.span`
    font-size: 16px;
    font-weight: bold;
`

const Converted = styled.span`
	font-weight: normal;
	font-size: 12px;
	color: grey;
`
