import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import {
	getChannelGeneralAction,
	getChannelPricingsAction,
	getChannelYoutubeAction,
	getChannelTwitchAction,
	searchChannelsAction,
} from '../actions/analyticsActions'

export const getChannelGeneral = createAsyncThunk(
	'channels/general',
	getChannelGeneralAction
)

export const getChannelPricings = createAsyncThunk(
	'channels/pricings',
	getChannelPricingsAction
)

export const getChannelYoutube = createAsyncThunk(
	'channels/youtube',
	getChannelYoutubeAction
)

export const getChannelTwitch = createAsyncThunk(
	'channels/twitch',
	getChannelTwitchAction
)

export const searchChannels = createAsyncThunk(
	'channels/search',
	searchChannelsAction
)

const initialState = {
	channels: {
		general: {
			loading: false,
			data: {},
		},
		pricings: {
			loading: false,
			data: {},
		},
		youtube: {
			loading: false,
			data: {},
		},
		twitch: {
			loading: false,
			data: {},
		},
	},
	search: {
		loading: false,
		pagination: {
			page: null,
			nextPage: null,
			pageCount: null,
		},
		results: {},
	},
}

const analyticsReducer = createReducer(initialState, {
	// General tab
	[getChannelGeneral.pending]: state => {
		state.channels.general.loading = true
	},
	[getChannelGeneral.fulfilled]: (state, action) => {
		const { channelId } = action.meta.arg
		state.channels.general.data[channelId] = action.payload.data
		state.channels.general.loading = false
	},
	[getChannelGeneral.rejected]: state => {
		state.channels.general.loading = false
	},

	// Pricings tab
	[getChannelPricings.pending]: state => {
		state.channels.pricings.loading = true
	},
	[getChannelPricings.fulfilled]: (state, action) => {
		const { channelId } = action.meta.arg
		state.channels.pricings.data[channelId] = action.payload.data
		state.channels.pricings.loading = false
	},
	[getChannelPricings.rejected]: state => {
		state.channels.pricings.loading = false
	},

	// YouTube tab
	[getChannelYoutube.pending]: state => {
		state.channels.youtube.loading = true
	},
	[getChannelYoutube.fulfilled]: (state, action) => {
		const { channelId } = action.meta.arg
		state.channels.youtube.data[channelId] = action.payload.data
		state.channels.youtube.loading = false
	},
	[getChannelYoutube.rejected]: state => {
		state.channels.youtube.loading = false
	},

	// Twitch tab
	[getChannelTwitch.pending]: state => {
		state.channels.twitch.loading = true
	},
	[getChannelTwitch.fulfilled]: (state, action) => {
		const { channelId } = action.meta.arg
		state.channels.twitch.data[channelId] = action.payload.data
		state.channels.twitch.loading = false
	},
	[getChannelTwitch.rejected]: state => {
		state.channels.twitch.loading = false
	},

	// Search
	[searchChannels.pending]: state => {
		state.search.loading = true
	},
	[searchChannels.fulfilled]: (state, action) => {
		const { page } = action.meta.arg
		const { channels, page_count, next_page } = action.payload.data

		if (page === 1) state.search.results = {}

		state.search.results[page] = channels
		state.search.pagination = {
			page,
			pageCount: page_count,
			nextPage: next_page,
		}
		state.search.loading = false
	},
	[searchChannels.rejected]: state => {
		state.search.loading = false
	},
})

export default analyticsReducer
