import {Paper, Tooltip} from '@material-ui/core'
import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {FaExclamationTriangle, FaInfoCircle} from 'react-icons/fa'
import {useHistory} from 'react-router'
import styled from 'styled-components'
import defaultBanner from '../../assets/img/default-banner.jpg'
import {
	getColorByStatus,
	getColorByStatusAlt,
	getEndDate,
	getInvoicingStatus,
	getLostReason,
	getSubtypes,
	gmApplicable,
	statusLabels,
	lostReasonsLabels, getSimpleLostReason,
} from '../../helpers/dealsHelper'
import {formatCurrencies} from '../../helpers/numbersHelper'
import Avatar from '../UI/Avatar'
import CustomBadge from '../UI/CustomBadge'
import CustomButton from '../UI/CustomButton'
import Markdown from '../UI/Markdown'
import Note from '../UI/Note'
import AcceptModal from './modals/Accept'
import DenyModal from './modals/Deny'
import NegociationModal from './modals/Negociate'

const DealCard = ({deal, showActions = false, compact = false}) => {
	const history = useHistory()
	const {t, i18n} = useTranslation()

	const [activeModal, setActiveModal] = useState(null)

	const modals = {
		negociation: 'negociation',
		deny: 'deny',
		accept: 'accept',
	}

	const handleClick = () => history.push(`/deals/view/${deal.id}`)
	const closeModal = () => setActiveModal(null)

	const getDeadline = date => moment(date).add(1, 'days').format('LLLL')
	const lostReason = getLostReason(deal)
	const invoicingStatus = getInvoicingStatus(deal);


	let shortBrief = deal.short_brief
	let brief = deal.brief
	let specificClause = deal.specific_clause

	if (i18n.language === 'en') {
		if (deal.short_brief_en) shortBrief = deal.short_brief_en
		if (deal.brief_en) brief = deal.brief_en
		if (deal.specific_clause_en) specificClause = deal.specific_clause_en
	}

	if (compact) {
		return (
			<Wrapper>
				<div
					className='card deal'
					style={{height: '100%', marginBottom: 0}}
				>
					<div
						className={`card-title ${
							showActions ? 'clickable' : ''
						}`}
						onClick={handleClick}
					>
						{deal.campaign?.icon_url ? (
							<div
								className='logo'
								style={{
									minHeight: 60,
									maxHeight: 60,
									minWidth: 60,
									maxWidth: 60,
								}}
							>
								<img
									onClick={handleClick}
									src={deal.campaign?.icon_url}
									alt=''
									style={{
										objectFit: 'cover',
										marginRight: 0,
										padding: 8,
										width: '100%',
										height: '100%',
										borderRadius: '100%',
									}}
								/>
							</div>
						) : null}
						<div
							className='infos'
							style={{
								padding: 8,
								overflow: 'hidden',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'unset',
							}}
						>
							<div
								className='title'
								style={{
									minWidth: '50%',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									fontSize: 16,
									fontWeight: 500,
								}}
							>
								{deal.campaign?.title}
							</div>
							<div style={{fontSize: 14, color: 'grey'}}>
								{statusLabels[deal.status]}
								{ getSimpleLostReason(deal) ? ' - ' + getSimpleLostReason(deal) : null }
								{ deal.end_date ? ` · ${getEndDate(deal)}` : null}
							</div>
						</div>
						{Boolean(
							deal.thread?.new_messages &&
							!window.location.pathname.includes('view')
						) && (
							<div
								style={{
									height: '100%',
									display: 'flex',
									padding: 10,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<CustomBadge value={deal.thread.new_messages}/>
							</div>
						)}
					</div>
					{invoicingStatus ? (
						<div class="text-xsmall" style={{padding: '6px 8px'}}>
							{t('deals.invoicing')} : {' '}
							{getInvoicingStatus(deal)}
						</div>) : null}
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'flex-end',
							width: '100%',
							flex: 1,
						}}
					>
						<div></div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								maxWidth: '50%',
								padding: '4px 8px',
								textAlign: 'right',
								backgroundColor: getColorByStatusAlt(
									deal.status
								),
							}}
						>
                            <span className='text-white text-xsmall'>
                                {t('deals.net_amount')} :{' '}
											<span className='text-bold'>
												{deal.converted_amount ? (
													<Tooltip title={formatCurrencies(deal.converted_amount - deal.converted_wizdeo_commission_amount, deal.converted_currency)} placement='left' arrow>
														<span>
															{formatCurrencies(
																deal.amount -
																deal.wizdeo_commission_amount,
																deal.currency
															)}
															<sup><FaInfoCircle /></sup>
														</span>
													</Tooltip>
												): (
													formatCurrencies(
														deal.amount -
														deal.wizdeo_commission_amount,
														deal.currency
													)
												)}
                                	</span>
                            </span>
							{deal.amount_bonus ? (
								<span className='text-white text-xsmall'>
                                    {deal.amount_bonus >= 0 ? (deal.bonus_to_be_invoiced
													? t('deals.amount_bonus')
													: t('deals.potential_bonus')) : t('deals.minimum_amount') }{' '}
									:{' '}
									<span className='text-bold'>
                                        {formatCurrencies(
														 deal.amount_bonus >= 0 ? deal.amount_bonus : deal.amount + deal.amount_bonus,
														 deal.currency
													 )}
                                    </span>
                                </span>
							) : null}
							{deal.wizdeo_commission_amount ? (
								<span className='text-white text-xsmall'>
									{t('deals.wizdeo_commission')} :{' '}
									<span className='text-bold'>
										{deal.converted_wizdeo_commission_amount ? (
											<Tooltip title={formatCurrencies(deal.converted_wizdeo_commission_amount, deal.converted_currency)} placement='left' arrow>
												<span>
													{formatCurrencies(
														deal.wizdeo_commission_amount,
														deal.currency
													)}
													<sup><FaInfoCircle /></sup>
												</span>
											</Tooltip>
										) : (
											formatCurrencies(
												deal.wizdeo_commission_amount,
												deal.currency
											)
										)}
                           </span>
                        </span>
							) : null}
						</div>
					</div>
				</div>
			</Wrapper>
		)
	}

	return (
		<Wrapper>
			<AcceptModal
				isOpen={activeModal === modals.accept}
				onRequestClose={closeModal}
				deal={deal}
			/>
			<DenyModal
				isOpen={activeModal === modals.deny}
				onRequestClose={closeModal}
				deal={deal}
			/>
			<NegociationModal
				isOpen={activeModal === modals.negociation}
				onRequestClose={closeModal}
				deal={deal}
			/>
			<div
				className='card deal'
				style={{height: '100%', marginBottom: 0}}
			>
				<div
					className={`card-title ${showActions ? 'clickable' : ''}`}
					onClick={handleClick}
				>
					{deal.campaign?.icon_url ? (
						<div
							className='logo'
							style={{
								minHeight: 60,
								maxHeight: 60,
								minWidth: 60,
								maxWidth: 60,
							}}
						>
							<img
								onClick={handleClick}
								src={deal.campaign?.icon_url}
								alt=''
								style={{
									objectFit: 'cover',
									marginRight: 0,
									padding: 8,
									width: '100%',
									height: '100%',
									borderRadius: '100%',
								}}
							/>
						</div>
					) : null}
					<div
						className='infos'
						style={{
							padding: 8,
							overflow: 'hidden',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'unset',
						}}
					>
						<div
							className='title'
							style={{
								minWidth: '50%',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								fontSize: 16,
								fontWeight: 500,
							}}
						>
							{deal.campaign?.title}
						</div>
						{invoicingStatus ? (
							<div style={{fontSize: 14, color: 'grey'}}>
								{t('deals.invoicing')} {' : '} {invoicingStatus}
							</div>
						) : (
							<div style={{fontSize: 14, color: 'grey'}}>
								{statusLabels[deal.status]}
							</div>
						)}
					</div>
					{Boolean(
						deal.thread?.new_messages &&
						!window.location.pathname.includes('view')
					) && (
						<div
							style={{
								height: '100%',
								display: 'flex',
								padding: 10,
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CustomBadge value={deal.thread.new_messages}/>
						</div>
					)}
				</div>
				<div
					onClick={handleClick}
					className={`card-image ${showActions ? 'clickable' : ''}`}
					style={{
						backgroundImage: `url(${
							deal.campaign?.banner_url ?? defaultBanner
						})`,
						height: 200,
					}}
				>
					{deal.sent_reminder_date ? (
						<div
							className='red darken-1'
							style={{
								padding: '5px 10px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<FaExclamationTriangle
								color='white'
								size={14}
								style={{marginRight: 10}}
							/>
							<span
								className='text-white'
								style={{fontSize: 12}}
							>
                                {t('deals.reminder_label')}{' '}
								<span className='text-bold'>
                                    {getDeadline(deal.sent_reminder_date)}
                                </span>
                            </span>
						</div>
					) : null}
					<div className='subtypes' style={{maxWidth: '50%'}}>
						{getSubtypes(deal, 'card')}
					</div>

					<div
						className={`pricing ${getColorByStatus(deal.status)}`}
						style={{padding: 4}}
					>
						<div className='net_amount'>
							<label>{t('deals.net_amount')}</label>
							<span
								className='mt-5'
								style={{fontSize: 18, fontWeight: 500}}
							>
                                {formatCurrencies(
											  deal.amount - deal.wizdeo_commission_amount,
											  deal.currency
										  )}
                            </span>
						</div>
						{deal.amount_bonus ? (
							<div className='wizdeo_commission mt-5'>
								<label>
									{deal.amount_bonus >= 0 ? (deal.bonus_to_be_invoiced
										? t('deals.amount_bonus')
										: t('deals.potential_bonus')) : t('deals.minimum_amount')}
								</label>
								<span className='mt-5'>
                                    {formatCurrencies(
													deal.amount_bonus >= 0 ? deal.amount_bonus : deal.amount + deal.amount_bonus,
													deal.currency
												)}
                                </span>
							</div>
						) : null}
						{deal.wizdeo_commission_amount ? (
							<div className='wizdeo_commission mt-5'>
								<label>{t('deals.wizdeo_commission')}</label>
								<span className='mt-5'>
                                    {formatCurrencies(
													deal.wizdeo_commission_amount,
													deal.currency
												)}
                                </span>
							</div>
						) : null}
					</div>
				</div>
				<div
					className='card-content mini-scroll'
					style={{flexGrow: 1}}
				>
					{deal.status === 'negociation' ? (
						<Note
							fontSize={14}
							type='warning'
							hideIcon
							message={t('deals.status_messages.negociation', {
								amount: formatCurrencies(
									deal.negociation_amount,
									deal.currency
								),
							})}
						/>
					) : null}
					{Boolean(deal.linked_channels) && (
						<dl>
							<dt className='mb-10'>
								{deal.linked_channels.length > 1
									? t('deals.associated_channels')
									: t('deals.associated_channel')}
							</dt>
							{deal.linked_channels.map((channel, index) => (
								<dd
									key={index.toString()}
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: 10,
									}}
								>
									<Avatar
										size='small'
										src={channel.logo}
										label={
											<span className='text-bold'>
                                                {channel.display_name}
                                            </span>
										}
									/>
									<span className='text-bold'></span>
								</dd>
							))}
						</dl>
					)}
					{lostReason ? (
						<dl>
							<dt>{lostReason.label}</dt>
							{lostReason.values.map((reason, index) => (
								<dd>{reason}</dd>
							))}
						</dl>
					) : null}
					{Boolean(shortBrief) && (
						<dl className='mt-10'>
							<dt>{t('deals.short_brief')}</dt>
							<dd>
								<Markdown>{shortBrief}</Markdown>
							</dd>
						</dl>
					)}
					{Boolean(brief) && (
						<dl className='mt-10'>
							<dt>{t('deals.brief')}</dt>
							<dd>
								<Markdown>{brief}</Markdown>
							</dd>
						</dl>
					)}
					{Boolean(specificClause) && (
						<dl className='mt-10 text-small'>
							<dt>{t('deals.specific_clause')}</dt>
							<dd>
								<Markdown>{specificClause}</Markdown>
							</dd>
						</dl>
					)}

					{Boolean(deal.drive_url) && (
						<dl className='mt-20'>
							<dt className='mb-10'>Documents</dt>
							<dd>
								<a href={deal.drive_url}>{deal.drive_url}</a>
							</dd>
						</dl>
					)}
					{gmApplicable(deal) && (
						<div className='mt-10'>
							<Note
								message={
									'Abonnement ‘Minimum garanti’ applicable'
								}
								type='info'
								small
								hideIcon
								textAlign='center'
							/>
						</div>
					)}
				</div>

				{deal.status === 'sent' ? (
					<div className='card-actions'>
						<>
							<CustomButton
								title={t('deals.negociate')}
								type='text'
								handleClick={() =>
									setActiveModal(modals.negociation)
								}
							/>
							<CustomButton
								title={t('deals.deny')}
								type='text'
								handleClick={() => setActiveModal(modals.deny)}
							/>
							<CustomButton
								title={t('deals.accept')}
								type='text'
								handleClick={() =>
									setActiveModal(modals.accept)
								}
							/>
						</>
					</div>
				) : null}
			</div>
		</Wrapper>
	)
}

export default DealCard

const Wrapper = styled(Paper)`
    height: 100%;
    display: flex;
    flex-direction: column;
`
