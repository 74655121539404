import moment from 'moment'
import {FaChevronDown, FaChevronUp} from 'react-icons/fa'
import RevenuesDetails from '../components/revenues/RevenuesDetails'
import {formatCurrencies} from './numbersHelper'
import {capitalize} from './stringHelper'
import {translate} from '../i18n/i18n'

const labels = () => {
    return {
        current: translate('revenues.current_month'),
        by_account: translate('revenues.by_account'),
        by_activity: translate('revenues.by_activity'),
        mcn: translate('revenues.activity.mcn'),
        royalties: translate('revenues.activity.royalties'),
        bc: translate('revenues.activity.brand_content'),
        other: translate('revenues.activity.other'),
        in_progress: translate('revenues.in_progress'),
        estimation: translate('revenues.estimation'),
    }
}

export const getMonthsList = () => {
    const months = []

    const start = moment('20190101')
    const now = moment()

    while (start.isBefore(now)) {
        const label = start.isSame(now, 'month')
            ? labels()['current']
            : start.format('MMMM YYYY')
        const value = start.isSame(now, 'month')
            ? 'current'
            : start.format('YYYY-MM')

        months.push({ label, value })

        start.add(1, 'months')
    }

    return months.reverse()
}

export const getFormattedRevenues = (data, month) => {
    const { zoho_accounts, services } = data

    const dataByAccount = Object.keys(zoho_accounts).map(key => {
        const { zoho_account_name, amount, payment_date, is_estimated_payment_date, invoice_status } = zoho_accounts[key]


        let paymentStatus = null;
        if ( payment_date ) {
            if ( is_estimated_payment_date ) {
                paymentStatus = translate('revenues.estimated_payment_date', {date: moment(payment_date).format('LL')});
            } else {
                paymentStatus = translate('revenues.payment_date', { date: moment(payment_date).format('LL') })
            }
        } else if ( invoice_status === 'waiting' ) {
            paymentStatus = translate('revenues.waiting_invoice');
        }
        return {
            cells: {
                label: zoho_account_name,
                value: {
                    style: {
                        textAlign: 'right',
                    },
                    value: (
                        <>
                            <span>{formatCurrencies(amount)}</span>
                            <br></br>
                            {paymentStatus ? (<small>{paymentStatus}</small>): null}
                        </>
                    ),
                },
                expander: open => (open ? <FaChevronUp /> : <FaChevronDown />),
            },
            subComponent: <RevenuesDetails key={key} id={key} month={month} />,
        }
    })

    dataByAccount.unshift({
        cells: {
            label: {
                value: labels()['by_account'],
                colSpan: 3,
                style: { fontWeight: 600, fontSize: 16 },
            },
        },
        style: { background: 'lightgrey' },
    })

    const activityLabels = {
        mcn: labels()['mcn'],
        royalties: labels()['royalties'],
        brand_content: labels()['bc'],
        other: labels()['other'],
    }

    const dataByActivity = Object.keys(services).map(key => ({
        cells: {
            label: activityLabels[key],
            value: {
                value: formatCurrencies(services[key]),
                colSpan: 2,
            },
        },
    }))

    dataByActivity.unshift({
        cells: {
            label: {
                value: labels()['by_activity'],
                colSpan: 3,
                style: { fontWeight: 600, fontSize: 16 },
            },
        },
        style: { background: 'lightgrey' },
    })

    const rows = [...dataByAccount, ...dataByActivity]

    let backgroundColor,
        subtitle,
        right = formatCurrencies(data.amount)
    const title = capitalize(moment(month).format('MMMM YYYY'))

    const now = moment()

    if (moment(month).isSame(now, 'month')) {
        backgroundColor = 'green darken-1'
        subtitle = labels()['in_progress']
    }

    if (moment(month).isAfter(now, 'month')) {
        backgroundColor = 'orange darken-1'
        subtitle = labels()['estimation']
    }

    return { rows, header: { backgroundColor, title, subtitle, right } }
}

export const getFormattedRevenuesDetails = details => {
    return details.operations.map(activity => {
        if (activity.details.length) {
            return activity.details.map(op => {
                if (op.amount) {
                    let color = ''
                    if (op.amount > 0) color = 'text-green'
                    if (op.amount < 0) color = 'text-red'
                    return (
                      <div className='revenues-details'>
                          <span className='text-italic'>{op.label}</span>
                          <span className={color}>
                                {formatCurrencies(op.amount)}
                            </span>
                      </div>
                    )
                }
            })
        }
    })
}
