import { login, logout } from '../store/reducers/talentReducer'
import store from '../store/store'
import { DeviceUUID } from 'device-uuid'
import { getToken } from '../firebase'
import jwtDecode from 'jwt-decode'

export const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

export const app_links = {
	ios: 'https://apps.apple.com/fr/app/wiz-app/id1465589704',
	android: 'http://play.google.com/store/apps/details?id=com.wizdeoapptalent',
}

export const onLoginSuccess = res => {
	const decodedRes = jwtDecode(res.credential)

	getToken()
		.then(token => {
			const data = formatGoogleUser(decodedRes, token)
			localStorage.setItem('talent', JSON.stringify(data))
			store.dispatch(login(data))
		})
		.catch(err => console.log('err', err))
}

export const onLogoutSuccess = () => {
	store.dispatch(logout())
}

export const onLoginFailure = res => {
	console.log(`res`, res)
}

export const onLogoutFailure = res => {
	console.log(`res`, res)
}

export const formatGoogleUser = (user, token) => {
	const formattedUser = {
		google_id: user.sub,
		user: {
			...user,
			id: user.sub,
			photo: user.picture,
			givenName: user.given_name,
			familyName: user.family_name,
		},
		deviceId: new DeviceUUID().get(),
		deviceOS: 'web',
		fcmToken: token,
		lang: navigator.language.substr(0, 2),
		version: process.env.REACT_APP_API_VERSION,
		appVersion: process.env.REACT_APP_VERSION,
	}

	delete formattedUser.user.googleId
	delete formattedUser.user.imageUrl

	return formattedUser
}
