import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { getRoyaltiesAction } from '../actions/royaltiesActions'

export const getRoyalties = createAsyncThunk(
    'royalties/get',
    getRoyaltiesAction
)

const initialState = {
    sacd: {
        loading: false,
        data: {},
        pagination: {},
    },
    scam: {
        loading: false,
        data: {},
        pagination: {},
    },
}

const royaltiesReducer = createReducer(initialState, {
    [getRoyalties.pending]: (state, action) => {
        const { type } = action.meta.arg
        state[type].loading = true
    },
    [getRoyalties.fulfilled]: (state, action) => {
        const { type, page } = action.meta.arg
        const { sheets, pagination } = action.payload.data[type]

        if (page === 1) {
            state[type].data = {
                [page]: sheets,
            }
        } else {
            state[type].data[page] = sheets
        }
        state[type].pagination = pagination
        state[type].loading = false
    },
    [getRoyalties.rejected]: (state, action) => {
        const { type } = action.meta.arg
        state[type].loading = false
    },
})

export default royaltiesReducer
