import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import classes from './styles/HomeCard.module.scss'

const HomeCard = ({
	children,
	icon: Icon = null,
	title,
	onClick = null,
	actions = null,
	justifyContent = 'flex-start',
}) => {
	return (
		<CardWrapper>
			<Header
				onClick={onClick}
				title={title}
				titleTypographyProps={{ variant: 'subtitle1' }}
				avatar={
					Icon ? <Icon className={classes.icon} size={22} /> : null
				}
			/>
			<Content justifycontent={justifyContent}>{children}</Content>
			{actions ? <Actions>{actions}</Actions> : null}
		</CardWrapper>
	)
}

export default HomeCard

const CardWrapper = styled(Card)`
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

const Header = styled(CardHeader)`
	background-color: #1976d2;
	color: white;
	padding: 10px 16px;
	min-height: 60px;
`

const Content = styled(CardContent)`
	padding: 0px !important;
	display: flex;
	flex-direction: column;
	justify-content: ${props => props.justifyContent};
	flex-grow: 1;
`

const Actions = styled(CardActions)`
	justify-content: flex-end;
	height: 50px;
`
