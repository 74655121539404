import React from 'react'
import GoogleLoginButton from '../../components/auth/GoogleLoginButton'
import headerLogo from '../../assets/img/header-logo.png'
import { useSelector } from 'react-redux'
import { AUTH } from '../../store/reducers/talentReducer'
import Loader from '../../components/UI/Loader'
import { Redirect } from 'react-router-dom'
import MobileAppButton from '../../components/UI/MobileAppButton'
import { Container, Row } from '../../components/Layout'
import {
    Divider,
    Paper,
} from '@material-ui/core'
import styled from 'styled-components'
import { useTitle } from 'react-use'
import { useTranslation } from 'react-i18next'

const LoginPage = ({ location }) => {
    const { t } = useTranslation()
    const { status } = useSelector((state) => state.talent)
    useTitle(t('pages.login'))
    if (status === AUTH.AUTHENTICATING) {
        return <Loader />
    }

    if (status === AUTH.AUTHORIZED) {
        const redirect = location.state?.redirect
        const redirectTo = redirect && redirect.pathname !== '/logout' ? redirect : '/home';
        return <Redirect to={redirectTo} />
    }

    return (
        <Container height='100%' center>
            <Wrapper>
                <Header>
                    <img src={headerLogo} alt="Wizdeo Logo"/>
                </Header>
                <Content>
                    <GoogleLoginButton />
                    <VersionNumber>
                        Version {process.env.REACT_APP_VERSION}
                    </VersionNumber>
                </Content>
                <Divider />
                <Row justifyContent='center'>
                    <MobileAppButton os='ios' />
                    <MobileAppButton os='android' />
                </Row>
            </Wrapper>
        </Container>
    )
}

export default LoginPage

const Wrapper = styled(Paper)`
    overflow: hidden;
`

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: #303646;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 12px;
`

const VersionNumber = styled.span`
    font-size: 12px;
    color: grey;
`
