import {Formik} from 'formik'
import React from 'react'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../components/layout/Header'
import CustomButton from '../components/UI/CustomButton'
import _ from 'underscore'
import {
	getInitialValues,
	Input,
	isPrivatePerson,
	validators,
} from '../helpers/settingsHelper'
import {FaSave, FaTimesCircle, FaTrash} from 'react-icons/fa'
import Loader from '../components/UI/Loader'
import {deleteTalent, edit} from '../store/reducers/talentReducer'
import {useTranslation} from 'react-i18next'
import {Container} from '../components/Layout'
import {getTalentActivity} from '../helpers/talentHelper'
import {useHistory,useParams} from 'react-router-dom'

const SettingsPage = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const {data: talent, updating} = useSelector((state) => state.talent)
	const {t} = useTranslation()

	const {talentHasZohoAccounts, talentIsAuthorized} = getTalentActivity()

	const { id } = useParams();
	let defaultTab = 0;
	if ( id ) {
		defaultTab = talent.channel_zoho_accounts.map( ( account ) => {
			return account.zoho_id;
		} ).indexOf( id );
	}

	const onSubmit = (values, account = null) => {
		if (account) {
			const data = {...values, zoho_id: account.zoho_id}
			dispatch(edit({zoho_account: data}))
		} else {
			const data = {...values, id: talent.id}
			dispatch(edit({talent: data}))
		}
	}

	const onDelete = (e) => {
		e.preventDefault()
		const confirmed = window.confirm('Confirmer la suppression de votre compte ?')
		if (confirmed) {
			Promise.all([
				dispatch(deleteTalent()),
				history.push('/logout')
			])
		}
	}

	const disabled = (props) => {
		return (
			_.isEqual(props.values, props.initialValues) ||
			props.isSubmitting ||
			Object.keys(props.errors).length
		)
	}
	const buttons = (props, withDelete = false) => (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: 10,
				marginBottom: 10,
			}}
		>
			<div style={{alignSelf: 'flex-start'}}>
				{withDelete && talentIsAuthorized && (
					<CustomButton
						icon={FaTrash}
						color={'red'}
						title={t('common.delete_account')}
						handleClick={onDelete}
					/>
				)}
			</div>
			<div>
				<CustomButton
					disabled={disabled(props)}
					icon={FaTimesCircle}
					handleClick={props.handleReset}
					title={t('common.reset')}
					type='flat'
				/>
				<CustomButton
					disabled={disabled(props)}
					icon={FaSave}
					title={t('common.save')}
					type='flat'
				/>
			</div>
		</div>
	)

	if (updating) return <Loader/>

	return (
		<>
			<Header title={t('pages.settings')}/>
			<Container>
				<div className='card-panel'>
					<Formik
						enableReinitialize
						initialValues={getInitialValues(talent)}
						validationSchema={validators().talent}
						onSubmit={(values) => onSubmit(values)}
					>
						{(props) => (
							<form
								className='material flat'
								onSubmit={props.handleSubmit}
							>
								<h4>
									{t('settings.section.personal_details')}
								</h4>
								<Input
									props={props}
									talent={talent}
									field='firstname'
								/>
								<Input
									props={props}
									talent={talent}
									field='lastname'
								/>
								<Input
									props={props}
									account={talent}
									field='email'
								/>

								{talentIsAuthorized && <Input
									props={props}
									field='email_notifications'
									talent={talent}
									type='checkbox'
								/>}

								{buttons(props, true)}
							</form>
						)}
					</Formik>
				</div>
				{talentHasZohoAccounts && (
					<Tabs defaultIndex={defaultTab}>
						<TabList>
							{talent.channel_zoho_accounts.map((account, index) => (
								<Tab key={index}>{account.name}</Tab>
							))}
						</TabList>
						{talent.channel_zoho_accounts.map((account, index) => (
							<TabPanel key={index}>
								<div className='card-panel'>
									<Formik
										enableReinitialize
										validationSchema={
											validators().zoho_account
										}
										initialValues={getInitialValues(
											account,
											'zoho_account'
										)}
										onSubmit={(values) => {
											console.log(`values`, values)
											onSubmit(values, account)
										}}
									>
										{(props) => (
											<form
												className='material flat'
												onSubmit={props.handleSubmit}
											>
												<h4>
													{t(
														'settings.section.legal_structure'
													)}
												</h4>
												<Input
													props={props}
													account={account}
													field='legal_structure'
													type='select'
												/>
												{!isPrivatePerson(
													props.values.legal_structure
												) && (
													<>
														<Input
															props={props}
															account={account}
															field='name'
														/>
														<Input
															props={props}
															account={account}
															field='siret'
														/>
														<Input
															props={props}
															account={account}
															field='tva_intra'
														/>
													</>
												)}
												<div className='divider'/>

												<h4>
													{t(
														'settings.section.contact'
													)}
												</h4>
												<Input
													props={props}
													account={account}
													field='billing_name'
												/>
												<Input
													props={props}
													account={account}
													field='billing_email'
												/>
												<Input
													props={props}
													account={account}
													field='language'
												/>
												<div className='divider'/>

												<h4>
													{t(
														'settings.section.billing_address'
													)}
												</h4>
												<Input
													props={props}
													account={account}
													field='billing_company'
												/>
												<Input
													props={props}
													account={account}
													field='billing_street'
												/>
												<div
													style={{
														display: 'flex',
														width: '100%',
														justifyContent:
															'space-between',
													}}
												>
													<Input
														props={props}
														account={account}
														field='billing_code'
														extraStyle={{
															container: {
																width: '30%',
															},
														}}
													/>
													<Input
														props={props}
														account={account}
														field='billing_city'
														extraStyle={{
															container: {
																width: '65%',
															},
														}}
													/>
												</div>
												<div className='divider'/>

												<h4>
													{t(
														'settings.section.bank_details'
													)}
												</h4>
												<Input
													props={props}
													account={account}
													field='billing_type'
													disabled
												/>
												<Input
													props={props}
													account={account}
													field='account_holder'
												/>
												<Input
													props={props}
													account={account}
													field='iban_bban'
												/>
												<Input
													props={props}
													account={account}
													field='bic_swift'
												/>
												<div className='divider'/>

												{buttons(props)}
											</form>
										)}
									</Formik>
								</div>
							</TabPanel>
						))}
					</Tabs>
				)}
			</Container>
		</>
	)
}

export default SettingsPage
