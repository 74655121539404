import { capitalize, Paper, Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Recharts from 'recharts'
import styled from 'styled-components'
import { shorten } from '../../helpers/numbersHelper'

const BrandChart = ({ series }) => {
	const { t, i18n } = useTranslation()

	const labels = {
		videos: t('brand.kpis.videos'),
		views: t('brand.kpis.views'),
	}

	const renderTooltip = ({ active, payload, label }) => {
		if (active && payload?.length) {
			const locale = i18n.language === 'fr' ? 'fr-FR' : 'en-US'
			return (
				<CustomToolTip square elevation={0}>
					<TooltipLabel variant='subtitle1'>
						{capitalize(moment(label).format('MMMM YYYY'))}
					</TooltipLabel>
					{payload.map(({ color, name, value }) => (
						<TooltipValue
							key={name}
							color={color}
							variant='subtitle2'
						>
							{labels[name]}
							{' : '}
							{Intl.NumberFormat(locale).format(value)}
						</TooltipValue>
					))}
				</CustomToolTip>
			)
		}
		return null
	}

	return (
		<Wrapper elevation={0}>
			<Recharts.ResponsiveContainer width='100%' height={400}>
				<Recharts.AreaChart
					margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
					data={series}
				>
					<Recharts.Tooltip content={renderTooltip} />
					<Recharts.XAxis
						domain={['dataMin', 'dataMax']}
						dataKey='month'
						tickFormatter={value =>
							moment(value).format('MMMM YYYY')
						}
						interval={0}
					/>
					<Recharts.YAxis
						yAxisId='videos'
						type='number'
						tickFormatter={value => shorten(value, 0)}
						dataKey='videos'
						label={{
							value: labels['videos'],
							position: 'insideTopLeft',
							dy: -35,
							style: { fill: '#007fe4' },
						}}
					/>
					<Recharts.YAxis
						yAxisId='views'
						type='number'
						tickFormatter={value => shorten(value, 0)}
						dataKey='views'
						orientation='right'
						label={{
							value: labels['views'],
							position: 'insideTopRight',
							dy: -35,
							style: { fill: '#ff9800' },
						}}
					/>
					<Recharts.Area
						yAxisId='views'
						activeDot={false}
						dataKey='views'
						stroke='#ff9800'
						fill='#ff9800'
					/>
					<Recharts.Area
						yAxisId='videos'
						activeDot={false}
						dataKey='videos'
						stroke='#007fe4'
						fill='#007fe4'
					/>
				</Recharts.AreaChart>
			</Recharts.ResponsiveContainer>
		</Wrapper>
	)
}

export default BrandChart

const Wrapper = styled(Paper)`
	width: 100%;
	min-height: 70%;
	// flex-grow: 1;
	// display: inline;
	padding: 12px 12px 12px 0px;
	// background: #ffd7d2;
`

const CustomToolTip = styled(Paper)`
	padding: 8px 12px;
	background-color: rgba(50, 50, 50, 0.75);
	border-radius: 10px;
`

const TooltipLabel = styled(Typography)`
	color: white;
`

const TooltipValue = styled.div`
	font-size: 15px;
	color: ${props => props.color || 'white'};
`

const CustomLabel = styled(Recharts.Label)`
	color: blue;
`
