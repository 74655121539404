import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    getAssociatedChannels,
    getCommission,
    getEndDate,
    getFormattedStatus,
    getNetAmount,
    getSubtypes,
    getInvoicingStatus,
    gmApplicable,
} from '../../helpers/dealsHelper'
import { Col, Row } from '../Layout'
import Avatar from '../UI/Avatar'
import CustomTable from '../UI/CustomTable'
import Loader from '../UI/Loader'
import Note from '../UI/Note'
import DealCard from './DealCard'

const DealsList = ({ deals, loading, error, display = 'list', compact = false }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { gm } = useSelector(state => state.badges)

    if (error) return <Note message={t('error.generic')} type='error' />

    if (!deals || loading) {
        return <Loader />
    }

    if (!deals.length) {
        return <Note message={t('deals.empty')} />
    }

    if (display === 'list') {
        let columns = [
            'Deal',
            t('deals.status_title'),
            t('deals.associated_channels'),
            t('deals.type'),
            t('deals.expected_date'),
            t('deals.commission'),
            t('deals.net_amount'),
            t('deals.invoicing'),
        ]

        if (gm?.contracts) columns.push(t('deals.gm_applicable_title'))

        let rows = deals.map((deal) => {
            const cells = [
                <Avatar
                    src={deal.campaign.icon_url}
                    label={deal.campaign.title}
                />,
                getFormattedStatus(deal),
                getAssociatedChannels(deal),
                getSubtypes(deal),
                getEndDate(deal),
                getCommission(deal, deal.converted_wizdeo_commission_amount !== undefined),
                getNetAmount(deal, deal.converted_amount !== undefined),
                getInvoicingStatus(deal)
            ]

            if (gm?.contracts) cells.push(<span>{gmApplicable(deal) ? t('common.yes') : t('common.no')}</span>)

            return {
                cells: cells,
                onClick: () => history.push(`/deals/view/${deal.id}`),
            }
        })

        return <CustomTable rows={rows} columns={columns} />
    }

    return (
        <Row spacing={3}>
            {deals.map((deal, index) => (
                <Col key={index.toString()} xs={12} md={6} lg={4}>
                    <DealCard compact={compact} redirect deal={deal} showActions />
                </Col>
            ))}
        </Row>
    )
}

export default DealsList
