import React from 'react'
import {
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Paper,
	Tooltip,
	Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'
import { shorten } from '../../helpers/numbersHelper'
import styled from 'styled-components'
import emptyImg from '../../assets/img/empty-img.png'
import { Avatar } from '@chatscope/chat-ui-kit-react'
import DateDisplay from 'components/UI/DateDisplay'

const SessionCard = ({ session, kpi, displayBrands, displayChannel, flat }) => {
	const { main_title, id } = session
	const [card, { width }] = useMeasure()
	const { t } = useTranslation()

	const availableKpis = {
		avg_viewers: t('twitch.kpis.avg_viewers'),
	}

	if (kpi === undefined) kpi = 'avg_viewers'

	const maincontent = (
		<>
			{displayChannel && session?.channel && (
				<CardHeader
					titleTypographyProps={{ noWrap: true }}
					subheaderTypographyProps={{ noWrap: true }}
					title={session.channel.display_name}
					subheader={
						session.channel?.followers
							? shorten(session.channel.followers, 1) +
							  ' ' +
							  t('twitch.kpis.followers')
							: '-'
					}
					avatar={<Avatar src={session.channel.logo} />}
				/>
			)}
			<GameLogoContainer>
				<img
					src={session.game.logo}
					alt={session.main_title}
					width='100%'
				/>
			</GameLogoContainer>
			<CardContent>
				<Typography variant='body1' noWrap gutterBottom>
					{main_title}
				</Typography>
				<KpisWrapper>
					<Kpi variant='subtitle2'>
						{shorten(session[kpi], 1)} {availableKpis[kpi]}
					</Kpi>
					<Kpi variant='subtitle2' $right>
						<DateDisplay date={session.start_time} />
					</Kpi>
				</KpisWrapper>
				{displayBrands && session?.brands && (
					<BrandsWrapper className='mt-10'>
						<Tooltip
							key={id + '_' + session.brands[0].id}
							title={session.brands[0].name}
							arrow
						>
							<Logo
								src={session.brands[0].logo}
								onError={e => {
									e.target.onerror = null
									e.target.src = emptyImg
								}}
							/>
						</Tooltip>

						<SecondaryBrandsWrapper>
							{session.brands.map(
								(brand, bIndex) =>
									bIndex > 0 && (
										<Tooltip
											key={id + '_' + brand.id}
											title={brand.name}
											arrow
										>
											<SecondaryBrandLogo
												src={brand.logo}
												onError={e => {
													e.target.onerror = null
													e.target.src = emptyImg
												}}
											/>
										</Tooltip>
									)
							)}
						</SecondaryBrandsWrapper>
					</BrandsWrapper>
				)}
			</CardContent>
		</>
	)

	return flat ? (
		<Card variant='outlined' ref={card}>
			{maincontent}
		</Card>
	) : (
		<Card component={Paper} ref={card}>
			{maincontent}
		</Card>
	)
}

export default SessionCard

const GameLogoContainer = styled(CardMedia)`
	overflow: hidden;
	height: 260px;

	& img {
		margin-top: -100px;
	}
`

const KpisWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

const Kpi = styled(Typography)`
	color: rgba(0, 0, 0, 0.54);
	${props => props.$right && 'text-align: right'}
`

const BrandsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Logo = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 50%;
`

const SecondaryBrandsWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;

	& img:not(:first-child) {
		margin-right: -8px;
	}
`

const SecondaryBrandLogo = styled.img`
	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 3px solid white;
`
