import React from 'react'
import * as MUI from '@material-ui/core'
import styled from 'styled-components'

const Avatar = (props) => {
    return (
        <Wrapper>
            <StyledAvatar {...props} />
            {props.label ? <Label>{props.label}</Label> : null}
        </Wrapper>
    )
}

export default Avatar

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

const StyledAvatar = styled(MUI.Avatar)`
    cursor: ${props => props.onClick ? 'pointer' : 'default'};
    ${(props) => (props.label ? 'margin-right: 8px;' : '')}
    ${(props) => props.size ? `
        height: ${props.size}px !important;
        width: ${props.size}px !important;
    ` : ''}
`

const Label = styled.span`
    font-size: 14px;
`
