import React from 'react'
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Tooltip,
	Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { shorten } from '../../helpers/numbersHelper'
import { useHistory } from 'react-router-dom'
import badgeWizdeo from '../../assets/img/badge_wizdeo.jpg'
import styled from 'styled-components'
import {
	FaQuestionCircle,
	FaInfoCircle,
	FaThumbsUp,
	FaTwitch,
	FaYoutube,
	FaStar,
} from 'react-icons/fa'
import moment from 'moment'
import StarRank from 'components/UI/StarRank'
import Profitability from 'components/UI/Profitability'

const ResultBrandCard = ({ brand, potentialRoi }) => {
	const {
		logo,
		name,
		influencer_channels_count_3m,
		influencer_videos_count_3m,
		twitch_influencer_channels_count_3m,
		twitch_influencer_sessions_count_3m,
		wizdeo_sponsored_videos_2y,
	} = brand
	const { t } = useTranslation()
	const history = useHistory()

	const handleClick = () => {
		history.push({
			pathname: `/brands/search/${brand.id}`,
			state: { brand },
		})
	}

	let 	color = '',
			tooltipROI = ''

	if (brand.proximity_label) {
		switch(brand.proximity_label) {
			case 'very_high':
				color = '#1b5e20'
				tooltipROI = t('brand.kpis.potential_roi_dark_green_tooltip')
				break;

			case 'high':
				color = '#4caf50'
				tooltipROI = t('brand.kpis.potential_roi_green_tooltip')
				break;

			case 'average':
				color = '#fdd835'
				tooltipROI = t('brand.kpis.potential_roi_yellow_tooltip')
				break;

			case 'low':
				color = '#ff9800'
				tooltipROI = t('brand.kpis.potential_roi_orange_tooltip')
				break;

			default:
				color = '#e0e0e0';
				tooltipROI = t('brand.kpis.potential_roi_grey_tooltip');
		}
	}

	return (
		<Card style={{ width: '250px' }}>
			<CardActionArea onClick={handleClick}>
				<CardMedia
					component='img'
					height='250'
					width='250'
					image={logo}
					alt={name}
				/>
				<CardContent style={{ padding: '10px' }}>
					<BrandHeader>
						<Tooltip
							title={name}
							placement='left'
							arrow
						>
							<Typography
								gutterBottom
								variant='h6'
								component='div'
								style={{ marginBottom: 0 }}
								noWrap
							>
								{name}
							</Typography>
						</Tooltip>
						{wizdeo_sponsored_videos_2y > 0 && (
							<Tooltip
								title={t('brand.wizdeo_client')}
								placement='left'
								arrow
							>
								<img
									src={badgeWizdeo}
									alt={t('brand.wizdeo_client')}
									height={16}
									width={16}
								/>
							</Tooltip>
						)}
						{potentialRoi && (
							brand.version < 3 ? (
								<Tooltip title={tooltipROI} placement='left' arrow>
									<span>
										<FaThumbsUp size={20} color={color} />
									</span>
								</Tooltip>
							) : (
								<div className='text-right no-wrap' style={{flexGrow: 1}}>
									<StarRank fullStars={brand.avg_proxi_reliability} />
									<br />
									<Profitability profitability={brand.profitability_score} />
								</div>
							)
						)}
					</BrandHeader>

					{brand?.last_sponsored_date ? (
						<LastCollabWrapper className='mb-10'>
							<FaInfoCircle />&nbsp;
							<span>{t('brand.kpis.last_sponsored_date')} {moment(brand.last_sponsored_date).format('MMMM YYYY')}</span>
						</LastCollabWrapper>
					) : null}

					{influencer_videos_count_3m > 0 && (
						<Line>
							<YoutubeTitle>
								<FaYoutube /> YouTube
							</YoutubeTitle>
							<KpiContainer>
								<Kpi>
									{influencer_channels_count_3m ? (
										<>
											{shorten(
												influencer_channels_count_3m,
												0
											)}{' '}
											{t(
												'brand.kpis.influencer_channels_count_3m_short'
											)}
											<Tooltip
												title={t(
													'brand.kpis.influencer_channels_count_3m_tooltip'
												)}
												arrow
											>
												<sup>
													<FaQuestionCircle size='0.7em' />
												</sup>
											</Tooltip>
										</>
									) : (
										'-'
									)}
								</Kpi>
								<Kpi>
									{influencer_videos_count_3m ? (
										<>
											{shorten(
												influencer_videos_count_3m,
												1
											)}{' '}
											{t(
												'brand.kpis.influencer_videos_count_3m_short'
											)}
											<Tooltip
												title={t(
													'brand.kpis.influencer_videos_count_3m_tooltip'
												)}
												arrow
											>
												<sup>
													<FaQuestionCircle size='0.7em' />
												</sup>
											</Tooltip>
										</>
									) : (
										'-'
									)}
								</Kpi>
							</KpiContainer>
						</Line>
					)}
					{twitch_influencer_channels_count_3m > 0 && (
						<Line>
							<TwitchTitle>
								<FaTwitch /> Twitch
							</TwitchTitle>
							<KpiContainer>
								<Kpi>
									{twitch_influencer_channels_count_3m ? (
										<>
											{shorten(
												twitch_influencer_channels_count_3m,
												1
											)}{' '}
											{t(
												'brand.kpis.twitch_influencer_channels_count_3m_short'
											)}
											<Tooltip
												title={t(
													'brand.kpis.twitch_influencer_channels_count_3m_tooltip'
												)}
												arrow
											>
												<sup>
													<FaQuestionCircle size='0.7em' />
												</sup>
											</Tooltip>
										</>
									) : (
										'-'
									)}
								</Kpi>
								<Kpi>
									{twitch_influencer_sessions_count_3m ? (
										<>
											{shorten(
												twitch_influencer_sessions_count_3m,
												1
											)}{' '}
											{t(
												'brand.kpis.twitch_influencer_sessions_count_3m_short'
											)}
											<Tooltip
												title={t(
													'brand.kpis.twitch_influencer_sessions_count_3m_tooltip'
												)}
												arrow
											>
												<sup>
													<FaQuestionCircle size='0.7em' />
												</sup>
											</Tooltip>
										</>
									) : (
										'-'
									)}
								</Kpi>
							</KpiContainer>
						</Line>
					)}
				</CardContent>
			</CardActionArea>
		</Card>
	)
}

const BrandHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 0.35rem;
	width: 100%;
	gap: 5px;;
`

const Line = styled.div`
	margin-bottom: 8px;
`

const KpiContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const Kpi = styled.div`
	flex-grow: 1;
	width: 50%;
	text-align: right;
`

const YoutubeTitle = styled.div`
	color: #aa181e;
`

const TwitchTitle = styled.div`
	color: #6441a4;
`

const LastCollabWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	font-style: italic;
	color: #2196F3;
	font-size: 0.65rem;
`

export default ResultBrandCard
