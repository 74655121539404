import React from 'react'
import { FaExclamationCircle, FaExclamationTriangle, FaCheckCircle, FaRegLightbulb } from 'react-icons/fa'

const Note = ({ message, children, title, type = 'info', footer, hideIcon = false, fontSize = 16, small = false, textAlign = 'left' }) => {
    let backgroundColor, color, Icon = null

    switch (type) {
        case 'success':
            Icon = FaCheckCircle
            color = '#388e3c'
            backgroundColor = '#A5D6A7'
            break
        case 'info':
            Icon = FaRegLightbulb
            color = '#0288d1'
            backgroundColor = '#BBDEFB'
            break
        case 'warning':
            color = '#fb8c00'
            backgroundColor = '#ffcc80'
            Icon = FaExclamationCircle
            break
        case 'error':
            color = '#d32f2f'
            backgroundColor = '#f3989b'
            Icon = FaExclamationTriangle
            break
        default:
            backgroundColor = '#FFFFFF'
            color = '#3A3A3A'
    }

    return (
        <div
            style={{
                color,
                backgroundColor,
                display: 'flex',
                alignItems: 'center',
                padding: small ? '6px 12px' : '12px 18px ',
                borderRadius: 5,
                lineHeight: 2,
            }}
        >
            {Icon && !hideIcon ? <Icon size={small ? 20 : 30 } style={{ marginRight: 20 }} /> : null}
            <div>
                {children || (
                    <div style={{ flexGrow: 1, fontSize: small ? 14 : fontSize, textAlign }}>
                        {title ? <h4 className='text-bold text-center'>{title}</h4> : null}
                        {message}
                        {footer}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Note
