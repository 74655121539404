import { shorten } from '../../helpers/numbersHelper'
import { Tooltip, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FaQuestionCircle, FaTwitch } from 'react-icons/fa'
import ChannelsCarousel from 'components/ChannelsCarousel'
import { Container } from 'components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from 'components/UI/CustomButton'
import { getBrandTwitchDashboard } from 'store/reducers/brandsReducer'
import React, { useLayoutEffect } from 'react'
import Loader from 'components/UI/Loader'
import BrandTwitchChannels from './BrandTwitchChannels'
import SessionsCarousel from 'components/twitch/SessionsCarousel'
import BrandTwitchSessions from './BrandTwitchSessions'

const BrandTwitchDashboard = ({
	brandId,
	displayedContent,
	handlePageChange,
}) => {
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const { twitch_dashboard } = useSelector(state => state.brands.brands)

	const load = () => {
		if (!twitch_dashboard.details[brandId] && !twitch_dashboard.loading) {
			dispatch(getBrandTwitchDashboard({ brandId }))
		}
	}

	useLayoutEffect(load, [brandId])

	return twitch_dashboard.loading ? (
		<Loader />
	) : (
		<>
			{displayedContent === 'twitch_dashboard' ? (
				<>
					<div className='card'>
						<TwitchIcon />
						<div className='bc-card-content card-content'>
							<div className='text-right text-large'>
								{t('brand.titles.twitch_sponsoring')}
							</div>
							<div className='text-right text-italic text-small mt-5'>
								{t('brand.titles.last_3months')}
							</div>
							<KpisWrapper>
								<KpiContainer>
									<KpiLabel>
										{t(
											'brand.kpis.twitch_influencer_channels_count_3m'
										)}{' '}
										<Tooltip
											title={t(
												'brand.kpis.twitch_influencer_channels_count_3m_tooltip'
											)}
											arrow
										>
											<sup>
												<FaQuestionCircle size='0.8em' />
											</sup>
										</Tooltip>
									</KpiLabel>
									<KpiValue>
										{twitch_dashboard.details[brandId]
											?.stats
											.twitch_influencer_channels_count_3m
											? shorten(
													twitch_dashboard.details[
														brandId
													].stats
														.twitch_influencer_channels_count_3m,
													1
											  )
											: '-'}
									</KpiValue>
								</KpiContainer>

								<KpiContainer>
									<KpiLabel>
										{t(
											'brand.kpis.twitch_influencer_sessions_count_3m'
										)}{' '}
										<Tooltip
											title={t(
												'brand.kpis.twitch_influencer_sessions_count_3m_tooltip'
											)}
											arrow
										>
											<sup>
												<FaQuestionCircle size='0.8em' />
											</sup>
										</Tooltip>
									</KpiLabel>
									<KpiValue>
										{twitch_dashboard.details[brandId]
											?.stats
											?.twitch_influencer_sessions_count_3m
											? shorten(
													twitch_dashboard.details[
														brandId
													].stats
														.twitch_influencer_sessions_count_3m,
													1
											  )
											: '-'}
									</KpiValue>
								</KpiContainer>
							</KpisWrapper>
						</div>
					</div>

					<div
						className='mt-20'
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<SectionTitle>
							{t('brand.sections.sponsored_twitch_channels')}
						</SectionTitle>

						<CustomButton
							type='primary'
							title={t('common.see_more')}
							handleClick={() =>
								handlePageChange('twitch_channels')
							}
						/>
					</div>
					<ChannelsCarousel
						type='twitch'
						channels={twitch_dashboard.details[brandId]?.channels}
					/>

					<div
						className='mt-20'
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<SectionTitle>
							{t('brand.sections.sponsored_twitch_sessions')}
						</SectionTitle>

						<CustomButton
							type='primary'
							title={t('common.see_more')}
							handleClick={() =>
								handlePageChange('twitch_sessions')
							}
						/>
					</div>
					<SessionsCarousel
						sessions={twitch_dashboard.details[brandId]?.sessions}
					/>
				</>
			) : displayedContent === 'twitch_channels' ? (
				<BrandTwitchChannels
					id={brandId}
					handleBackToDetails={handlePageChange}
				/>
			) : (
				<BrandTwitchSessions
					id={brandId}
					handleBackToDetails={handlePageChange}
				/>
			)}
		</>
	)
}

export default BrandTwitchDashboard

const KpisWrapper = styled.div`
	display: flex;
	margin-left: 110px;
	margin-top: 12px;

	@media (max-width: 531px) {
		margin-left: 0;
		margin-top: 30px;
		white-space: nowrap;
		flex-wrap: wrap;
	}
`

const KpiContainer = styled.div`
	flex-grow: 1;
	width: 33%;
	display: flex;
	flex-direction: column;

	@media (max-width: 531px) {
		width: 50%;
	}
`
const KpiLabel = styled.div`
	flex-grow: 1;
	text-align: right;
	font-size: 0.8rem;
`

const KpiValue = styled.div`
	text-align: right;
	font-weight: 400;
	font-size: 1.4rem;
`

const TwitchIcon = styled(FaTwitch)`
	color: #6441a4;
	position: absolute;
	top: -35px;
	z-index: 0;
	left: -20px;
	font-size: 8em;
`

const SectionTitle = styled(Typography)`
	font-weight: bold !important;
	display: flex;
	align-items: center;
	min-height: 40px;
`
