import {
	Card,
	CardContent,
	LinearProgress,
	Tooltip,
	Typography,
} from '@material-ui/core'
import Loader from 'components/UI/Loader'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'components/Layout'
import NoData from 'components/UI/NoData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepadModern } from '@fortawesome/pro-solid-svg-icons'
import { shorten } from '../../helpers/numbersHelper'
import emptyImg from '../../assets/img/empty-img.png'
import BrandsCarousel from 'components/brands/BrandsCarousel'
import SessionsCarousel from 'components/twitch/SessionsCarousel'
import { FaInfoCircle } from 'react-icons/fa'

const ChannelTwitch = ({ isLoading, data }) => {
	const { t } = useTranslation()

	return isLoading ? (
		<Loader />
	) : (
		<>
			<SectionTitle>{t('twitch.sections.collaborations')}</SectionTitle>
			<Typography variant='subtitle2'>
				{t('twitch.sections.latest_sponsored_sessions')}
			</Typography>
			<div className='mb-20'>
				<SessionsCarousel sessions={data?.sponsored_sessions} />
			</div>

			<Typography variant='subtitle2'>
				{t('twitch.sections.brands')}
			</Typography>
			<div className='mb-20'>
				<BrandsCarousel brands={data?.brands} />
			</div>

			<Row spacing={2}>
				<Col xs={12} xl={6}>
					<SectionTitle>
						{t('twitch.sections.engagement')}
					</SectionTitle>
					<Card className='mb-20'>
						<CardContent>
							<Row spacing={2} style={{ textAlign: 'right' }}>
								<Col xs={6} xl={6} style={{ padding: 0 }}>
									<dl>
										<dt>
											{t(
												'twitch.kpis.avg_viewer_duration_3m'
											)}
											<Tooltip
												title={t(
													'twitch.kpis.avg_viewer_duration_3m_tooltip'
												)}
												arrow
											>
												<sup>
													<FaInfoCircle />
												</sup>
											</Tooltip>
										</dt>
										<dd>
											{data &&
											data['avg_viewer_duration_3m']
												? shorten(
														data[
															'avg_viewer_duration_3m'
														],
														1
												  )
												: '-'}
										</dd>
									</dl>
								</Col>
								<Col xs={6} xl={6} style={{ padding: 0 }}>
									<dl>
										<dt>
											{t(
												'twitch.kpis.potential_avg_viewers'
											)}
											<Tooltip
												title={t(
													'twitch.kpis.potential_avg_viewers_tooltip'
												)}
												arrow
											>
												<sup>
													<FaInfoCircle />
												</sup>
											</Tooltip>
										</dt>
										<dd>
											{data &&
											data['potential_avg_viewers']
												? shorten(
														data[
															'potential_avg_viewers'
														],
														1
												  )
												: '-'}
										</dd>
									</dl>
								</Col>
							</Row>
						</CardContent>
					</Card>
				</Col>
				<Col xs={12} xl={6}>
					<SectionTitle>{t('twitch.sections.games')}</SectionTitle>
					{data?.games && data.games.length ? (
						<Card>
							<GamesContainer>
								{data.games.map(game => (
									<GameWrapper key={game.id}>
										<GameNameWrapper xs={8}>
											<GameLogoWrapper>
												<GameLogo
													src={game.logo}
													alt={game.name}
													onError={e => {
														e.target.onerror = null
														e.target.src = emptyImg
													}}
												/>
											</GameLogoWrapper>
											<GameName>{game.name}</GameName>
										</GameNameWrapper>
										<GamePercentage xs={4}>
											{shorten(game.percentage, 1)}%
										</GamePercentage>
										<LinearProgress
											style={{
												position: 'absolute',
												left: '0',
												bottom: '0',
												width: '100%',
											}}
											variant='determinate'
											value={shorten(game.percentage)}
										/>
									</GameWrapper>
								))}
							</GamesContainer>
						</Card>
					) : (
						<GamesContainer $empty>
							<CardContent>
								<NoData
									icon={
										<FontAwesomeIcon
											icon={faGamepadModern}
										/>
									}
								/>
							</CardContent>
						</GamesContainer>
					)}
				</Col>
			</Row>
		</>
	)
}

export default ChannelTwitch

const SectionTitle = styled(Typography)`
	font-weight: bold !important;
	display: flex;
	align-items: center;
	min-height: 40px;
`

const GamesContainer = styled(Card)`
	overflow: auto;
	min-height: 142px;
	max-height: 375px;

	${props =>
		props.$empty &&
		'display: flex; align-items: center; justify-content: center;'}
`

const GameWrapper = styled(Col)`
	position: relative;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 6px 12px 9px 12px;
`

const GameNameWrapper = styled(Col)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

const GameLogoWrapper = styled.div`
	height: 30px;
	width: 30px;
	margin-right: 5px;
`

const GameLogo = styled.img`
	height: 30px;
	width: 30px;
	border-radius: 50%;
`

const GameName = styled.div`
	white-space: nowrap;
	font-size: 0.9em;
	overflow: hidden;
	text-overflow: ellipsis;
`

const GamePercentage = styled(Col)`
	font-weight: bold;
	text-align: right;
`
