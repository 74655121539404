import { Paper, Typography } from '@material-ui/core'
import React from 'react'
import { TagCloud } from 'react-tagcloud'
import styled from 'styled-components'

const WordCloud = ({ words }) => {
    const getColor = (value) => {
        switch (value) {
            case 'bad':
                return 'red'
            case 'poor':
                return 'orange'
            case 'good':
                return 'green'
            case 'very_good':
                return 'darkgreen'
            default:
                return 'grey'
        }
    }

    const getSize = (value) => {
        const max = 40
        const min = 12

        if (value > max) return max
        if (value < min) return min

        return value
    }

    const tags = words.map((item) => ({
        value: item.tag,
        count: item.point,
        class: item.class,
    }))

    const renderWord = (tag) => (
        <Tag
            key={tag.value}
            size={getSize(tag.count)}
            color={getColor(tag.class)}
        >
            {tag.value}
        </Tag>
    )

    return (
        <Wrapper>
            <TagCloud
                tags={tags}
                renderer={renderWord}
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            />
        </Wrapper>
    )
}

export default WordCloud

const Wrapper = styled(Paper)`
    padding: 12px;
`

const Tag = styled(Typography)`
    display: inline-block;
    padding: 6px;
    line-height: 1 !important;
    font-size: ${(props) => props.size}px !important;
    color: ${(props) => props.color} !important;
`
