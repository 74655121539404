import { Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { FaThumbsUp } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { shorten } from '../../helpers/numbersHelper'

const Engagement = ({ engagement }) => {
	const { t } = useTranslation()

	const engagementDefs = [
		{ type: 'medium', min: 23.2 },
		{ type: 'good', min: 47.6 },
		{ type: 'excellent', min: 82 },
	]

	let tooltip = null
	let textColor = null
	engagementDefs.map(values => {
		if (values?.min && values?.max) {
			if (engagement >= values.min && engagement < values.max) {
				textColor = '#f57c00'
				tooltip = t('common.engagement.medium')
			}
		} else if (values?.min) {
			if (engagement >= values.min) {
				textColor = '#388e3c'
				tooltip = t('common.engagement.good')
			}
		} else if (values?.max) {
			if (engagement < values.max) {
				textColor = '#afb42b'
				tooltip = t('common.engagement.excellent')
			}
		}
	})

	return (
		<Typography
			style={{
				fontSize: '1.8em',
				color: textColor,
				fontWeight: 'bold',
			}}
		>
			<Tooltip title={tooltip} arrow>
				<>
					<FaThumbsUp />{' '}
					{engagement ? shorten(engagement, 2) + '‰' : '-'}
				</>
			</Tooltip>
		</Typography>
	)
}

export default Engagement
