import { Card, CardContent, Typography } from '@material-ui/core'
import { Col, Row } from 'components/Layout'
import CustomButton from 'components/UI/CustomButton'
import Loader from 'components/UI/Loader'
import React from 'react'
import { FaTwitch, FaYoutube } from 'react-icons/fa'
import styled from 'styled-components'
import { formatCurrencies, shorten } from '../../helpers/numbersHelper'
import { useTranslation } from 'react-i18next'
import CrosschecksCarousel from 'components/CrosschecksCarousel'

const ChannelPricings = ({
	isLoading,
	data,
	hasTwitchChannel,
	talentOwnsChannel,
}) => {
	const { t } = useTranslation()

	const handleCustomizePricings = () => {
		window.location = `mailto:${
			process.env.REACT_APP_WIZDEO_EMAIL
		}?subject=${t('channel.cta.customize_pricings_email_subject')}&body=${
			t('channel.cta.customize_pricings_email_body') +
			' ' +
			data.display_name +
			' (#' +
			data.id +
			') ' +
			t('common.to') +
			' :'
		}`
	}

	return isLoading ? (
		<Loader />
	) : (
		<>
			{talentOwnsChannel ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<SectionTitle>
						{t('channel.sections.pricings')}
					</SectionTitle>

					<CustomButton
						type='primary'
						title={t('channel.cta.customize_pricings')}
						handleClick={() => handleCustomizePricings()}
					/>
				</div>
			) : (
				<SectionTitle>{t('channel.sections.pricings')}</SectionTitle>
			)}

			<Row spacing={2} className='mb-20'>
				<Col xs={12} xl={8} className='pt-0'>
					<PricingCard $bgColor='#aa181e'>
						<PricingCardContent>
							<span>{t('common.youtube')}</span>

							<Row spacing={2} style={{ textAlign: 'right' }}>
								<Col xs={6}>
									<dl>
										<dt>{t('channel.kpis.cpm')}</dt>
										<dd>
											{talentOwnsChannel ? (
												<>
													{data?.cpm
														? formatCurrencies(
																data.cpm
														  )
														: '-'}
												</>
											) : data?.cpm_min &&
											  data?.cpm_max ? (
												<>
													{t('common.between') + ' '}
													{formatCurrencies(
														data.cpm_min
													)}{' '}
													{t('common.and') + ' '}
													{formatCurrencies(
														data?.cpm_max
													)}
												</>
											) : (
												'-'
											)}
											{}
										</dd>
									</dl>
								</Col>
								<Col xs={6}>
									<dl>
										<dt>
											{t(
												'channel.kpis.sponsored_videos_6m_percentage'
											)}
										</dt>
										<dd>
											{data?.sponsored_videos_6m_percentage
												? shorten(
														data.sponsored_videos_6m_percentage,
														0
												  ) + '%'
												: '-'}
										</dd>
									</dl>
								</Col>
							</Row>
							<PricingCardIcon>
								<FaYoutube />
							</PricingCardIcon>
						</PricingCardContent>
					</PricingCard>
				</Col>

				{hasTwitchChannel && (
					<Col xs={12} xl={4} className='pt-0'>
						<PricingCard $bgColor='#6441a4'>
							<PricingCardContent>
								<span>{t('common.twitch')}</span>

								<Row spacing={2} style={{ textAlign: 'right' }}>
									<Col xs={6}></Col>
									<Col xs={6}>
										<dl>
											<dt>
												{t(
													'twitch.kpis.sponsored_sessions_percentage'
												)}
											</dt>
											<dd>
												{data?.sponsored_sessions_6m_percentage
													? shorten(
															data.sponsored_sessions_6m_percentage,
															0
													  ) + '%'
													: '-'}
											</dd>
										</dl>
									</Col>
								</Row>
								<PricingCardIcon>
									<FaTwitch />
								</PricingCardIcon>
							</PricingCardContent>
						</PricingCard>
					</Col>
				)}
			</Row>

			<SectionTitle>
				{t('channel.sections.youtube_crosschecks')}
			</SectionTitle>
			<div className='mb-20'>
				<CrosschecksCarousel
					closeChannels={data?.youtube_crosschecks}
				/>
			</div>

			{hasTwitchChannel && (
				<>
					<SectionTitle>
						{t('channel.sections.twitch_crosschecks')}
					</SectionTitle>
					<CrosschecksCarousel
						type='twitch'
						closeChannels={data?.twitch_crosschecks}
					/>
				</>
			)}
		</>
	)
}

export default ChannelPricings

const SectionTitle = styled(Typography)`
	font-weight: bold !important;
	display: flex;
	align-items: center;
	min-height: 40px;
`

const PricingCard = styled(Card)`
	color: white;
	background-color: ${props => props?.$bgColor};
`

const PricingCardContent = styled(CardContent)`
	position: relative;

	& span {
		font-size: 1em;
	}

	& dd {
		font-size: 1.3em;
	}
`

const PricingCardIcon = styled.div`
	position: absolute;
	font-size: 8rem;
	opacity: 0.2;
	top: -20px;
	left: -20px;
`
