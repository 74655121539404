import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { formatCurrencies, shorten } from '../../../helpers/numbersHelper'
import classes from '../styles/ChannelCard.module.scss'
import emptyImg from 'assets/img/empty-img.png'
import { FaTwitch, FaYoutube } from 'react-icons/fa'
import styled from 'styled-components'

const ChannelCard = ({ channel }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const handleClick = () => history.push('analytics/mychannel', { channel })

	return (
		<div className={`${classes.container}`}>
			<div
				className={`clickable ${classes.header}`}
				onClick={handleClick}
				style={{
					backgroundImage: `linear-gradient(#1A1A1A99, #1A1A1A99), url('${channel.banner}')`,
				}}
			>
				<div className={classes.infos}>
					<img
						src={channel.logo}
						className={classes.logo}
						alt=''
						onError={e => {
							e.target.onerror = null
							e.target.src = emptyImg
						}}
					/>
					<TitleWrapper>
						<p className={classes.name}>{channel.display_name}</p>
						{channel.handle ? <Handle>{'@' + channel.handle}</Handle> : null}
					</TitleWrapper>
				</div>
			</div>
			<dl className={classes.kpis} style={{ flexWrap: 'wrap' }}>
				<dl>
					<KpiTitle>
						<YoutubeIcon size={20} color='#aa181e' />
						{t('channel.kpis.youtube_subscribers')}
					</KpiTitle>
					<dd className={classes.value}>
						{shorten(channel.subscribers, 1)}
					</dd>
				</dl>

				{channel?.twitch_channel && (
					<dl>
						<KpiTitle>
							<TwitchIcon size={20} color='#6441a4' />
							{t('twitch.kpis.twitch_followers')}
						</KpiTitle>
						<dd className={classes.value}>
							{shorten(channel.twitch_channel.followers, 1)}
						</dd>
					</dl>
				)}

				<dl>
					<dt>{t('channel.kpis.cpm')}</dt>
					<dd className={classes.value}>
						{channel?.cpm_min && channel?.cpm_max ? (
							<>
								{t('common.between') + ' '}
								{formatCurrencies(channel.cpm_min)}{' '}
								{t('common.and') + ' '}
								{formatCurrencies(channel?.cpm_max)}
							</>
						) : (
							'-'
						)}
					</dd>
				</dl>
			</dl>
		</div>
	)
}

export default ChannelCard

const KpiTitle = styled.dt`
	display: flex !important;
	align-items: center !important;
	white-space: nowrap;
`

const YoutubeIcon = styled(FaYoutube)`
	margin-right: 0.2rem;
`

const TwitchIcon = styled(FaTwitch)`
	margin-right: 0.2em;
`

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const Handle = styled.div`
	font-size: 14px;
	color: #9e9e9e;
`
