import {
	Avatar,
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	CardMedia,
	Paper,
} from '@material-ui/core'
import styled from 'styled-components'

const ChannelCard = ({
	channel,
	subheader,
	handleClick,
	cardcontent,
	hideBanner,
	flat,
}) => {
	const maincontent = (
		<CardActionArea onClick={handleClick}>
			{!hideBanner && (
				<Banner image={channel.banner} title={channel.display_name} />
			)}
			<CardHeader
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				title={channel.display_name}
				subheader={subheader}
				avatar={<Avatar src={channel.logo} />}
			/>
			{cardcontent ? (
				<CardContentCustom>{cardcontent}</CardContentCustom>
			) : null}
		</CardActionArea>
	)

	return flat ? (
		<Card variant='outlined'>{maincontent}</Card>
	) : (
		<Card component={Paper}>{maincontent}</Card>
	)
}

export default ChannelCard

const Banner = styled(CardMedia)`
	height: 150px;
`
const CardContentCustom = styled(CardContent)`
	padding-top: 0;
`
