import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { useTitle } from 'react-use'
import { AUTH } from '../../store/reducers/talentReducer'
import Loader from '../UI/Loader'
import UnauthorizedPage from '../../pages/UnauthorizedPage'
import LogoutPage from '../../pages/auth/LogoutPage'

const CustomComponent = ({
    component: Component,
    authStatus,
    title,
    ...props
}) => {
    useTitle(title)
    const { data: talent } = useSelector((state) => state.talent)

    if (authStatus === AUTH.AUTHENTICATING) return <Loader />

    if (authStatus === AUTH.UNAUTHORIZED)
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: {
                        redirect: props.location,
                    },
                }}
            />
        )

    //if (talent?.status !== 'authorized' && Component !== LogoutPage) return <UnauthorizedPage />

    return <Component {...props} />
}

const PrivateRoute = ({ component, title = 'WizApp', ...rest }) => {
    const { status } = useSelector((state) => state.talent)

    return (
        <Route {...rest}>
            <CustomComponent
                authStatus={status}
                component={component}
                title={title}
                {...rest}
            />
        </Route>
    )
}

export default PrivateRoute
