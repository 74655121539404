import React, { useLayoutEffect } from 'react'
import { Container, Grid, useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import DealCard from '../../components/deals/DealCard'
import Header from '../../components/layout/Header'
import TasksList from '../../components/tasks/TasksList'
import Thread from '../../components/threads/Thread'
import Loader from '../../components/UI/Loader'
import { getDeal } from '../../store/reducers/dealsReducer'

const DealPage = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    
    const {[id]: deal} = useSelector((state) => state.deals.data)

    const isXXLScreen = useMediaQuery('(min-width:1920px)')

    const load = () => {
        if (!deal || deal.loading) {
            dispatch(getDeal({ id }))
        }
    }

    // Load the deal on mount or reload when the id changes
    useLayoutEffect(load, [id])

    // Show a loader if the deal or the thread are loading
    if (!deal || deal.loading) {
        return <Loader />
    }

    return (
        <>
            <Header title={deal.campaign?.title} subtitle='Deal' />
            <ScrollContainer>
                <StyledContainer disableGutters maxWidth={isXXLScreen ? 'xl' : 'lg'}>
                    <Row container spacing={3}>
                        <Item item full xs={12} sm={6} md={8}>
                            <DealCard full deal={deal} />
                        </Item>
                        <Item item full xs={12} sm={6} md={4}>
                            <Thread id={deal.thread_id} />
                        </Item>
                        <Item item xs={12} sm={6} md={8}>
                            <TasksList
                                dealStatus={deal.status}
                                tasks={deal.deal_tasks}
                            />
                        </Item>
                    </Row>
                </StyledContainer>
            </ScrollContainer>
        </>
    )
}

export default DealPage

const ScrollContainer = styled.div`
    flex-grow: 1;
    overflow: auto;
`

const StyledContainer = styled(Container)`
    height: 100%;
    display: flex !important;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
`

const Row = styled(Grid)`
    padding: 16px 16px;
    height: 100%;
`

const Item = styled(Grid)`
    height: ${props => props.full ? '100%' : 'auto'};
`
