import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import Strike from '../../model/Strike'
import VideoInfos from '../UI/VideoInfos'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StrikeRow = ({ data }) => {
    const { t } = useTranslation()
    const strike = new Strike(data)

    return (
        <TableRow style={{ backgroundColor: strike.backgroundColor, fontSize: 14 }}>
            <Cell>
                <VideoInfos video={strike.video} />
            </Cell>
            <Cell align='center'>{strike._type}</Cell>
            <Cell align='center'>{strike._nature}</Cell>
            <Cell align='center'>{strike.date.format('LL')}</Cell>
            <Cell align='center'>
                {strike.solve_date.format('LL')}
            </Cell>
            <Cell align='center'>
                {Strike.LABELS.solve_types[strike.solve_type]}
            </Cell>
            <Cell align='center'>{`${strike.solve_length} ${
                t('common.day') + (strike.solve_length > 1 ? 's' : '')
            }`}</Cell>
            <Cell align='center'>{strike._negociable}</Cell>
            <Cell align='right'>{strike._status}</Cell>
        </TableRow>
    )
}

export default StrikeRow

const Cell = styled(TableCell)`
    font-size: 14px;
`
