import React, { useRef, useState } from 'react'
import {
    MainContainer,
    ChatContainer,
    MessageList,
    MessageInput,
} from '@chatscope/chat-ui-kit-react'
import './styles/Thread.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'
import {
    clearNewMessages,
    getNextPage,
    getThread,
    sendMessage,
} from '../../store/reducers/threadsReducer'
import ThreadMessage from './ThreadMessage'

const Thread = ({ id }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { [id]: data } = useSelector((state) => state.threads)
    const { data: talent } = useSelector(state => state.talent)

    const [message, setMessage] = useState('')
    const [newMessageBadges, setNewMessageBadges] = useState({})
    const inputRef = useRef()

    const load = () => {
        if (!data) dispatch(getThread({ id }))
    }

    const clear = () => {
        if (data) {
            const { thread, messages = [] } = data
            for (const message of messages) {
                if (message.is_new) {
                    setNewMessageBadges(prev => ({...prev, [message.id]: true }))
                }
            }
            if (thread?.new_messages) {
                dispatch(clearNewMessages({ threadId: data?.thread.id }))
            }
        }
    }

    React.useEffect(load, [])

    React.useEffect(clear, [data])

    const handleReachEnd = () => {
        if (data?.updating) return
    }

    const handleReachStart = () => {
        const { nextPage, page, id: threadId } = data.thread
        if (nextPage && !data?.updating) {
            const payload = { page: page + 1, threadId }
            dispatch(getNextPage(payload))
        }
    }

    const handleSend = () => {
        setMessage('')
        const payload = {
            threadId: data?.thread.id,
            threadMessage: message,
            talent,
        }
        dispatch(sendMessage(payload))
    }

    const handleFocus = () => {
        setNewMessageBadges({})
    }

    let content

    if (data?.messages) {
        const { messages = [] } = data;
        if (messages.length) {
            const messagesCopy = messages.slice()
            const reversedMessages = messagesCopy.reverse()
            content = reversedMessages.map((message, index) => <ThreadMessage showBadge={newMessageBadges[message.id]} message={message} key={index} />)
        } else {
            content = <ThreadMessage message={t('threads.empty')} notification />
        }
    }

    return (
        <Wrapper>
            <MainContainer>
                <ChatContainer>
                    <MessageList
                        loading={data?.loading}
                        onYReachStart={handleReachStart}
                        onYReachEnd={handleReachEnd}
                        autoScrollToBottomOnMount={true}
                        loadingMore={data?.updating}
                        loadingMorePosition='top'
					     >
							 	<MessageList.Content>
									{content}
								</MessageList.Content>
                    </MessageList>
                    <MessageInput
                        onFocus={handleFocus}
                        ref={inputRef}
                        sendOnReturnDisabled={true}
                        value={message}
                        onSend={handleSend}
                        onChange={(text) => setMessage(text)}
                        attachButton={false}
                        placeholder={t('threads.placeholder')}
                    />
                </ChatContainer>
            </MainContainer>
        </Wrapper>
    )
}

export default Thread

const Wrapper = styled(Paper)`
    height: 100%;
`
