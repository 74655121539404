import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { getClaimsAction } from '../actions/claimsActions'

export const getClaims = createAsyncThunk('claims/get', getClaimsAction)

const initialState = {
    data: {},
    pagination: {},
    loading: false,
}

const claimsReducer = createReducer(initialState, {
    [getClaims.pending]: (state) => {
        state.loading = true
    },
    [getClaims.fulfilled]: (state, action) => {
        const { page } = action.meta.arg
        const { claims, pagination } = action.payload.data

        state.data[page] = claims
        state.pagination = pagination
        state.loading = false
    },
    [getClaims.rejected]: (state) => {
        state.loading = false
    },
})

export default claimsReducer
