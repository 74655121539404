import { Card, CardContent, Divider, Paper } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Recharts from 'recharts'
import styled from 'styled-components'
import {
	faPersonSimple,
	faPersonDressSimple,
	faPeople,
} from '@fortawesome/pro-solid-svg-icons'
import { Col, Row } from 'components/Layout'
import { formatPercentage } from '../../helpers/numbersHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NoData from 'components/UI/NoData'
import { FaChartBar } from 'react-icons/fa'

const ChannelDemoChart = ({ data }) => {
	const { t, i18n } = useTranslation()
	const getWidth = value => formatPercentage(value).raw

	const labels = {
		man: t('common.man'),
		woman: t('common.woman'),
	}

	const formatLegend = value => {
		return <span>{labels[value]}</span>
	}

	const renderTooltip = ({ active, payload, label }) => {
		if (active && payload?.length) {
			const locale = i18n.language === 'fr' ? 'fr-FR' : 'en-US'

			return (
				<CustomToolTip square elevation={0}>
					{payload.map(({ color, name, value }) => (
						<TooltipValue
							key={name}
							color={color}
							variant='subtitle2'
						>
							{labels[name]}
							{' : '}
							{Intl.NumberFormat(locale).format(value)}%
						</TooltipValue>
					))}
				</CustomToolTip>
			)
		}
		return null
	}

	return (
		<>
			<Card style={{ marginBottom: 12 }}>
				<CardContent>
					{data?.man && data?.woman ? (
						<>
							<Row>
								<Col xs={6}>
									<div style={{ color: '#3790f6' }}>
										<FontAwesomeIcon
											icon={faPersonSimple}
										/>
										{' ' + labels['man']}
									</div>
								</Col>
								<Col xs={6} style={{ textAlign: 'right' }}>
									<div style={{ color: '#cf004c' }}>
										<FontAwesomeIcon
											icon={faPersonDressSimple}
										/>
										{' ' + labels['woman']}
									</div>
								</Col>
							</Row>
							<ProgressWrapper className='mb-10'>
								<Progress
									background='#3790f6'
									width={getWidth(data?.man)}
								>
									{getWidth(data?.man)}%
								</Progress>
								<Progress
									background='#cf004c'
									width={getWidth(data?.woman)}
								>
									{getWidth(data?.woman)}%
								</Progress>
							</ProgressWrapper>
						</>
					) : (
						<NoData icon={<FontAwesomeIcon icon={faPeople} />} />
					)}
				</CardContent>
			</Card>

			<Card>
				<CardContent>
					{data?.series ? (
						<Recharts.ResponsiveContainer width='100%' height={300}>
							<Recharts.BarChart
								margin={{
									top: 0,
									right: 0,
									bottom: 0,
									left: 0,
								}}
								data={data?.series}
							>
								<Recharts.Tooltip content={renderTooltip} />
								<Recharts.XAxis dataKey='name' />
								<Recharts.Legend formatter={formatLegend} />
								<Recharts.Bar dataKey='man' fill='#3790f6' />
								<Recharts.Bar dataKey='woman' fill='#cf004c' />
							</Recharts.BarChart>
						</Recharts.ResponsiveContainer>
					) : (
						<NoData icon={<FaChartBar />} />
					)}
				</CardContent>
			</Card>
		</>
	)
}

export default ChannelDemoChart

const ProgressWrapper = styled.div`
	display: flex;
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
`

const Progress = styled.div`
	color: white;
	font-size: 14px;
	text-align: center;
	background: ${props => props.background};
	width: ${props => props.width}%;
`

const CustomToolTip = styled(Paper)`
	padding: 8px 12px;
	background-color: rgba(50, 50, 50, 0.75);
	border-radius: 10px;
`

const TooltipValue = styled.div`
	font-size: 15px;
	color: ${props => props.color || 'white'};
`
