import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { getRevenuesAction, getRevenuesDetailsAction } from '../actions/revenuesActions'

export const getRevenues = createAsyncThunk('revenues/get', getRevenuesAction)
export const getRevenuesDetails = createAsyncThunk('revenues/details/get', getRevenuesDetailsAction)

const initialState = {
    data: {
        current: null
    },
    loading: false,
}

const revenuesReducer = createReducer(initialState, {
    [getRevenues.pending]: (state, action) => {

        state.loading = true
    },
    [getRevenues.fulfilled]: (state, action) => {

        const {month} = action.meta.arg
        const {zohoAccountRevenues: revenues} = action.payload.data

        if (month === 'current') {
            state.data[month] = revenues
        }

        if (Object.keys(revenues).length) {
            Object.keys(revenues).forEach(month => {
                state.data[month] = revenues[month]
                Object.keys(state.data[month].zoho_accounts).forEach(id => {
                    state.data[month].zoho_accounts[id].loading = false
                    state.data[month].zoho_accounts[id].details = null
                })
            })
        } else {
            state.data[month] = []
        }

        state.loading = false
    },
    [getRevenues.rejected]: (state, action) => {

        state.loading = false
    },
    [getRevenuesDetails.pending]: (state, action) => {

        const {month, id} = action.meta.arg
        state.data[month].zoho_accounts[id].loading = true
    },
    [getRevenuesDetails.fulfilled]: (state, action) => {

        const {month, id} = action.meta.arg
        state.data[month].zoho_accounts[id].details = action.payload.data.zohoAccountRevenue
        state.data[month].zoho_accounts[id].loading = false
    },
    [getRevenuesDetails.rejected]: (state, action) => {

        const {month, id} = action.meta.arg
        state.data[month].zoho_accounts[id].loading = false
    },
})

export default revenuesReducer
