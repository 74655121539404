import React, { useEffect } from 'react'
import {
	FaChartLine,
	FaCog,
	FaDollarSign,
	FaGlobe,
	FaHome,
	FaExclamationTriangle,
	FaMoneyBill,
	FaRegCalendarAlt,
	FaRegCheckSquare,
	FaRegFileAlt,
	FaSignOutAlt,
	FaYoutube,
	FaTv,
	FaComments,
	FaTimes,
	FaShieldAlt,
	FaCopyright,
	FaChevronRight,
	FaHandshake,
} from 'react-icons/fa'
import {
	ProSidebar,
	SidebarContent,
	Menu,
	MenuItem,
	SubMenu,
	SidebarHeader,
	SidebarFooter,
} from 'react-pro-sidebar'
import { Link, matchPath, useLocation } from 'react-router-dom'
import TalentSection from './TalentSection'

import './styles/Sidebar.scss'
import { useDispatch, useSelector } from 'react-redux'
import CustomBadge from '../UI/CustomBadge'
import { useTranslation } from 'react-i18next'
import { useCookie } from 'react-use'
import {
	getTalentActivity,
	TALENT_ACCESS_TYPE,
	talentChannels,
} from '../../helpers/talentHelper'
import { getServices } from 'store/reducers/servicesReducer.js'
import { Skeleton } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { config } from '@fortawesome/fontawesome-svg-core'
import store from 'store/store'
// import { services } from 'helpers/servicesHelper'
import { useMatomo } from '@jonkoops/matomo-tracker-react'

const Sidebar = ({ toggled, collapsed, handleToggle }) => {
	const location = useLocation()
	const { t, i18n } = useTranslation()
	const [languageCookie, updateLanguageCookie] = useCookie('lang')

	const services = useSelector(state => state.services)
	const dispatch = useDispatch()

	const { deals } = useSelector(state => state.badges)
	const { thread_id } = useSelector(state => state.talent.data)

	const channels = talentChannels()

	const { data: talent } = useSelector((state) => state.talent)

	const { trackPageView, pushInstruction } = useMatomo()

	const {
		talentHasBC,
		talentHasStrikes,
		talentHasMcn,
		talentHasClaims,
		talentHasRoyalties,
		talentHasChannels,
		numberOfChannels,
		talentHasCopyrights,
		talentAccessType,
		talentHasGm
	} = getTalentActivity()

	const handleLanguageClick = language => {
		updateLanguageCookie(language)
		window.location.reload()
	}

	const load = () => {
		if (!services.data.length && !services.loading) {
			dispatch(getServices({ lang: i18n.language }))
		}
	}

	React.useLayoutEffect(load, [])

	// Story #2550 Matomo integration
	useEffect(() => {
		let pageView = {
			name: location.pathname,
			customDimensions: [
				{
					id: 1,
					value: languageCookie
				},
				{
					id: 2,
					value : process.env.REACT_APP_VERSION
				}
			]
		}

		pushInstruction('setUserId', talent.id)
		trackPageView(pageView)
	}, [location])

	const CustomMenuItem = ({
		icon,
		title,
		link,
		extralinks = [],
		onClick = handleToggle,
		state = {},
		suffix = null,
	}) => {
		const { pathname } = location
		let active = matchPath(pathname, link)

		for (const extralink of extralinks) {
			if (matchPath(pathname, extralink)) active = true
		}

		return (
			<MenuItem
				active={active}
				onClick={onClick}
				icon={icon}
				suffix={suffix}
			>
				{title}
				{link ? (
					<Link
						to={{
							pathname: link,
							state: state,
						}}
					/>
				) : null}
			</MenuItem>
		)
	}

	return (
		<ProSidebar
			toggled={toggled}
			collapsed={collapsed}
			onToggle={handleToggle}
			breakPoint='md'
		>
			<SidebarHeader>
				<TalentSection />
			</SidebarHeader>
			<SidebarContent>
				<Menu>
					<CustomMenuItem
						link='/home'
						title={t('pages.home')}
						icon={<FaHome />}
					/>
					<>
						{talentAccessType === TALENT_ACCESS_TYPE.ADVANCED && (
							<CustomMenuItem
								link='/revenues'
								title={t('pages.revenues')}
								icon={<FaDollarSign />}
							/>
						)}
						{thread_id &&
							(talentAccessType === TALENT_ACCESS_TYPE.ADVANCED ||
								talentAccessType ===
									TALENT_ACCESS_TYPE.BASIC) && (
								<CustomMenuItem
									link='/chat'
									title={t('pages.chat')}
									icon={<FaComments />}
								/>
							)}

						{talentAccessType === TALENT_ACCESS_TYPE.ADVANCED &&
							talentHasBC && (
								<>
									<span className='section_title'>
										{t('menu.sections.brand_content')}
									</span>

									<SubMenu
										title={t('pages.deals')}
										icon={<FaRegFileAlt />}
									>
										<CustomMenuItem
											title={t(
												'deals.status.short.sent_plural'
											)}
											link={'/deals/sent'}
											suffix={
												<CustomBadge
													value={deals.sent}
												/>
											}
										/>
										<CustomMenuItem
											title={t(
												'deals.status.short.approved'
											)}
											link={'/deals/approved'}
											suffix={
												<CustomBadge
													value={deals.approved}
												/>
											}
										/>
										<CustomMenuItem
											title={t(
												'deals.status.short.in_progress'
											)}
											link={'/deals/in_progress'}
											suffix={
												<CustomBadge
													value={deals.in_progress}
												/>
											}
										/>
										<CustomMenuItem
											title={t(
												'deals.status.short.historic'
											)}
											link={'/deals/done'}
										/>
									</SubMenu>

									<CustomMenuItem
										icon={<FaRegCheckSquare />}
										title={t('pages.tasks')}
										link={'/tasks'}
									/>

									<CustomMenuItem
										icon={<FaRegCalendarAlt />}
										title={t('pages.agenda')}
										link={'/agenda'}
									/>

									{talentHasGm && (
										<CustomMenuItem
											icon={<FaChartLine />}
											title={t('pages.gm')}
											link={'/gm/summary'}
											extralinks={['/gm/deals']}
										/>
									)}
								</>
							)}

						{talentAccessType === TALENT_ACCESS_TYPE.ADVANCED &&
							talentHasRoyalties && (
								<>
									<span className='section_title'>
										{t('menu.sections.royalties')}
									</span>
									<CustomMenuItem
										icon={<FaMoneyBill />}
										title={t('pages.royalties')}
										link={'/royalties'}
										state={{ index: 0 }}
									/>
								</>
							)}

						{talentAccessType === TALENT_ACCESS_TYPE.ADVANCED &&
							talentHasMcn && (
								<>
									<span className='section_title'>MCN</span>
									{talentHasClaims && (
										<CustomMenuItem
											icon={<FaExclamationTriangle />}
											title={t('pages.claims')}
											link={'/claims'}
										/>
									)}

									{talentHasStrikes && (
										<CustomMenuItem
											icon={<FaTimes />}
											title={t('pages.strikes')}
											link={'/strikes'}
										/>
									)}

									{talentHasCopyrights && (
										<CustomMenuItem
											icon={<FaShieldAlt />}
											title={t('pages.copyrights')}
											link={'/copyrights'}
										/>
									)}
								</>
							)}
					</>

					<span className='section_title'>
						{t('menu.sections.channels')}
					</span>
					{talentHasChannels && (
						<CustomMenuItem
							icon={<FaTv />}
							title={t(
								`pages.analytics.my_channel${
									channels.length > 1 ? 's' : ''
								}`
							)}
							link={'/analytics/mychannel'}
							state={{ channel: channels[0] }}
						/>
					)}
					<CustomMenuItem
						icon={<FaYoutube />}
						title={t('pages.channels_search')}
						link={'/analytics/search'}
					/>

					<span className='section_title'>
						{t('menu.sections.brands')}
					</span>
					<CustomMenuItem
						icon={<FaCopyright />}
						title={t('pages.brands.search')}
						link={'/brands/search'}
					/>

					{numberOfChannels && (
						<CustomMenuItem
							icon={<FaHandshake />}
							title={t('pages.brands.suggested')}
							link={'/brands/suggested'}
						/>
					)}

					{services.loading ? (
						<div style={{ padding: '0 20px 0 10px' }}>
							<Typography variant='h4'>
								<Skeleton animation='wave' width='70%' />
							</Typography>
							<div style={{ padding: '0 0 0 20px' }}>
								<Skeleton animation='wave' width='45%' />
								<Skeleton animation='wave' width='45%' />
								<Skeleton animation='wave' width='45%' />
							</div>
						</div>
					) : (
						<>
							{services.data.length && (
								<>
									<span className='section_title'>
										{t('menu.sections.services')}
									</span>
									{services.data.map((service, index) => (
										<CustomMenuItem
											key={service.id}
											icon={<FaChevronRight />}
											title={service.name}
											link={'/service/' + service.id}
											state={{ service: service }}
										/>
									))}
								</>
							)}
						</>
					)}
				</Menu>
			</SidebarContent>
			<SidebarFooter>
				<Menu>
					<div className='lang_select'>
						<SubMenu
							title={t(`lang.${languageCookie}`)}
							icon={<FaGlobe />}
						>
							{i18n.languages.map(language => (
								<CustomMenuItem
									key={language}
									title={t(`lang.${language}`)}
									onClick={() =>
										handleLanguageClick(language)
									}
								/>
							))}
						</SubMenu>
					</div>
					<CustomMenuItem
						title={t('pages.settings')}
						link={'/settings'}
						icon={<FaCog />}
					/>
					<CustomMenuItem
						title={t('auth.logout')}
						link={'/logout'}
						icon={<FaSignOutAlt />}
					/>
				</Menu>
			</SidebarFooter>
		</ProSidebar>
	)
}

export default Sidebar
