import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from '../components/Layout'
import Header from '../components/layout/Header'
import CustomSelect from '../components/UI/CustomSelect'
import CustomTable from '../components/UI/CustomTable'
import Filters from '../components/UI/Filters'
import Loader from '../components/UI/Loader'
import Note from '../components/UI/Note'
import { getFormattedRevenues, getMonthsList } from '../helpers/revenuesHelper'
import { getRevenues } from '../store/reducers/revenuesReducer'

const RevenuesPage = () => {
    const [month, setMonth] = useState('current')
    const dispatch = useDispatch()
    const {
        data: { [month]: data },
        loading,
    } = useSelector((state) => state.revenues)
    const { t } = useTranslation()

    let content

    const load = () => {
        if (!data) {
            dispatch(getRevenues({ month }))
        }
    }

    useLayoutEffect(load, [month])

    if (!data || loading) {
        content = <Loader />
    } else {
        if (!Object.keys(data).length) {
            content = (
                <Col xs={12}>
                    <Note
                        message={t('revenues.empty_month', { month })}
                        type='warning'
                    />
                </Col>
            )
        } else {
            if (month === 'current') {
                content = Object.keys(data).map((month, index) => {
                    const { rows, header } = getFormattedRevenues(
                        data[month],
                        month
                    )

                    return (
                        <Col key={index} xs={12}>
                            <CustomTable
                                rows={rows}
                                canExpand
                                header={header}
                            />
                        </Col>
                    )
                })
            } else {
                const { rows, header } = getFormattedRevenues(data, month)

                content = (
                    <Col xs={12}>
                        <CustomTable rows={rows} canExpand header={header} />
                    </Col>
                )
            }
        }
    }

    return (
        <>
            <Header title={t('pages.revenues')} />
            <Container>
                <Filters>
                    <CustomSelect
                        options={getMonthsList()}
                        value={month}
                        defaultValue='current'
                        onChange={(item) => setMonth(item.value)}
                    />
                </Filters>
                <Row spacing={3}>
                    {content}
                </Row>
            </Container>
        </>
    )
}

export default RevenuesPage
