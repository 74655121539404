import React, { memo, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Header from '../../components/layout/Header'
import { getDeals } from '../../store/reducers/dealsReducer'
import SearchBar from '../../components/UI/SearchBar'
import DealsList from '../../components/deals/DealsList'
import { useTranslation } from 'react-i18next'
import CustomSelect from '../../components/UI/CustomSelect'
import { useCookie, useSearchParam, useUpdateEffect } from 'react-use'
import CustomButton from '../../components/UI/CustomButton'
import { FaTh, FaThList } from 'react-icons/fa'
import { Grid, useMediaQuery } from '@material-ui/core'
import Paginator from '../../components/UI/Paginator'
import Filters from '../../components/UI/Filters'
import { Container, Row } from '../../components/Layout'
import QueryString from 'qs'
import moment from 'moment'

const DealsPage = ({ status }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const history = useHistory()

    // Get others URL query params
    const params = {
        search: useSearchParam('search'),
        page: useSearchParam('page'),
        filter: useSearchParam('filter'),
        year: useSearchParam('year'),
        month: useSearchParam('month'),
    }

    const updateQueryParams = params => {
        const currentParams = QueryString.parse(
            history.location.search.replace('?', '')
        )
        const updatedParams = { ...currentParams }

        Object.entries(params).forEach(([key, value]) => {
            if (!value && key in updatedParams) delete updatedParams[key]
            if (value) updatedParams[key] = value
        })

        history.replace({ search: QueryString.stringify(updatedParams) })
    }

    // Handle display cookie (list or cards)
    const [displayCookie, updateDisplayCookie] = useCookie('deals_display')
    const [display, setDisplay] = useState(displayCookie ?? 'list')
    const updateDisplay = value => {
        updateDisplayCookie(value)
        setDisplay(value)
    }

    // Media queries
    const forceCardDisplay = useMediaQuery(theme =>
        theme.breakpoints.down('md')
    )

    const fullWidthInputs = useMediaQuery(theme => theme.breakpoints.down('sm'))

    // Local state
    const [search, setSearch] = useState(params.search || '')
    const [page, setPage] = useState(params.page || 1)
    const [filter, setFilter] = useState(params.filter)
    const [year, setYear] = useState(params.year || null)
    const [month, setMonth] = useState(params.month || null)

    // Get data from the store
    const {
        [status]: { results, pagination, loading, error },
    } = useSelector(state => state.deals.search)

    // Load deals
    const load = (reload = false, forcePage = page) => {
        const payload = {
            reload,
            status,
            filter,
            search,
            year,
            month,
            page: forcePage,
        }

        updateQueryParams({ filter, search, page: forcePage, year, month })

        if ((!results[page] || reload) && !loading) {
            setPage(forcePage)
            dispatch(getDeals(payload))
        }
    }

    // Load deals on first mount & everytime the status changes
    useLayoutEffect(() => {
        load()
    }, [status])

    // Change deals display based on media queries
    useEffect(() => {
        if (forceCardDisplay) updateDisplay('card')
    }, [forceCardDisplay])

    // Update deals when the page changes
    useUpdateEffect(() => {
        load()
    }, [page])

    useUpdateEffect(() => {
        if (year === null) {
            setMonth(null)
        }
    }, [year])

    // Update deals when the filter changes
    useUpdateEffect(() => {
        load(true, 1)
    }, [filter, year, month])

    // Update deals when the user clicks on another page
    const handlePageChange = (event, value) => setPage(value)

    const paginator =
        pagination.pageCount > 1 ? (
            <Paginator
                page={page}
                count={pagination.pageCount}
                onPageChange={handlePageChange}
            />
        ) : null

    const displayButtons = () => {
        if (forceCardDisplay) return null
        const color = 'rgba(0, 0, 0, 0.71)'
        return (
            <div className='btn-group'>
                <CustomButton
                    handleClick={() => updateDisplay('cards')}
                    type='icon'
                    icon={FaTh}
                    color={color}
                    active={display === 'cards'}
                />
                <CustomButton
                    handleClick={() => updateDisplay('list')}
                    type='icon'
                    icon={FaThList}
                    color={color}
                    active={display === 'list'}
                />
            </div>
        )
    }

    const filterOptions = [
        { label: t('deals.status.done_all'), value: 'all' },
        { label: t('deals.status.done_paid'), value: 'paid' },
        { label: t('deals.status.done_payment_in_progress'), value: 'payment_in_progress' },
        { label: t('deals.status.done_lost'), value: 'lost' },
        { label: t('deals.status.done_declined'), value: 'declined' },
    ]

    const yearOptions = () => {
        const minDate = moment('20190101')
        let current = minDate
        const maxDate = moment()
        let years = [{ label: t('filters.all_years'), value: null }]
        while (current.isSameOrBefore(maxDate)) {
            years.push({
                label: current.year().toString(),
                value: current.year().toString(),
            })
            current.add(1, 'year')
        }
        return years
    }

    let monthOptions = moment.months().map((month, index) => ({
        label: month,
        value: index < 9 ? `0${index + 1}` : (index + 1).toString(),
    }))

    monthOptions.unshift({ label: t('filters.all_months'), value: null })

    const handleFilterChange = item => setFilter(item.value)
    const handleYearChange = item => setYear(item.value)
    const handleMonthChange = item => setMonth(item.value)

    const handleSearchChange = e => setSearch(e.target.value)

    const handleSearchReset = e => {
        e.preventDefault()
        setSearch('')
    }

    const handleSearchSubmit = e => {
        e.preventDefault()
        load(true, 1)
    }

    let subtitleKey = `deals.status.short.${status}`

    if (status === 'sent' || status === 'done') subtitleKey += '_plural'

    return (
        <>
            <Header title={t('pages.deals')} subtitle={t(subtitleKey)} />
            <Container>
                <Filters>
                    <Grid container>
                                <Grid xs={12} md={3} item>
                            <SearchBar
                                disabled={loading}
                                full={fullWidthInputs}
                                value={search}
                                onChange={handleSearchChange}
                                onReset={handleSearchReset}
                                onSubmit={handleSearchSubmit}
                            />
                        </Grid>
                        {status === 'done' && (
                            <>
                                <Grid xs={12} md={3} item>
                                    <CustomSelect
                                        disabled={loading}
                                        full={fullWidthInputs}
                                        options={yearOptions()}
                                        defaultValue={year}
                                        value={year}
                                        onChange={handleYearChange}
                                    />
                                </Grid>
                                <Grid xs={12} md={3} item>
                                    <CustomSelect
                                        disabled={loading || !year}
                                        full={fullWidthInputs}
                                        options={monthOptions}
                                        defaultValue={month}
                                        value={month}
                                        onChange={handleMonthChange}
                                    />
                                </Grid>
                                <Grid xs={12} md={3} item>
                                    <CustomSelect
                                        disabled={loading}
                                        full={fullWidthInputs}
                                        options={filterOptions}
                                        defaultValue={'all'}
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Filters>
                <Row
                    justifyContent='space-between'
                    alignItems='center'
                    marginBottom={12}
                >
                    {displayButtons()}
                    {paginator}
                </Row>
                <DealsList
                    compact={status === 'done'}
                    error={error}
                    display={display}
                    deals={results[page]}
                    loading={loading}
                />
                <Row
                    justifyContent='flex-end'
                    alignItems='center'
                    marginTop={12}
                >
                    {paginator}
                </Row>
            </Container>
        </>
    )
}

export default memo(DealsPage)
