import { API, handleError, handleResponse } from '../../api'

export const getBrandYoutubeDashboardAction = (payload, thunk) => {
	const options = {
		params: {
			brandId: payload.brandId,
			sponsoredPeriod: '3m',
		},
	}

	return API.get('sponso-brands/youtube_dashboard.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getBrandYouTubeChannelsAction = (payload, thunk) => {
	const { id: brandId, page = 1 } = payload

	const options = {
		params: {
			brandId,
			page,
			sponsoredPeriod: '3m',
		},
	}

	return API.get('sponso-brands/youtube_channels.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getBrandYouTubeVideosAction = (payload, thunk) => {
	const { id: brandId, page = 1 } = payload

	const options = {
		params: {
			brandId,
			page,
		},
	}

	return API.get('sponso-brands/youtube_videos.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getBrandTwitchDashboardAction = (payload, thunk) => {
	const options = {
		params: {
			brandId: payload.brandId,
			sponsoredPeriod: '3m',
		},
	}

	return API.get('sponso-brands/twitch_dashboard.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getBrandTwitchChannelsAction = (payload, thunk) => {
	const { id: brandId, page = 1 } = payload

	const options = {
		params: {
			brandId,
			page,
			sponsoredPeriod: '3m',
		},
	}

	return API.get('sponso-brands/twitch_channels.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const getBrandTwitchSessionsAction = (payload, thunk) => {
	const { id: brandId, page = 1 } = payload

	const options = {
		params: {
			brandId,
			page,
		},
	}

	return API.get('sponso-brands/twitch_sessions.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const searchBrandsAction = (payload, thunk) => {
	const { keywords, page = 1 } = payload

	const options = {
		params: {
			keywords,
			page,
		},
	}

	return API.get('sponso-brands/search.json', options)
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}

export const suggestedBrandsAction = (payload, thunk) => {
	return API.get('sponso-brands/suggested-v2/' + payload.id + '.json')
		.then(res => handleResponse(res, thunk))
		.catch(handleError)
}
