import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
} from '@material-ui/core'
import { Container, Header } from 'components/Layout'
import CustomButton from 'components/UI/CustomButton'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import emptyImg from '../assets/img/empty-img.png'
import { useDispatch, useSelector } from 'react-redux'
import { getServices } from 'store/reducers/servicesReducer'
import { useTranslation } from 'react-i18next'
import { Loader } from '@chatscope/chat-ui-kit-react'
import { useParams } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'

const ServicePage = ({ location }) => {
	const { i18n } = useTranslation()

	const { name } = useParams()

	const services = useSelector(state => state.services)
	const dispatch = useDispatch()

	let currentService = location.state?.service ?? {}

	const load = () => {
		if (!services.data.length && !services.loading) {
			dispatch(getServices({ lang: i18n.language }))
		}
	}

	if (!currentService?.name && !services.loading && services.data.length) {
		services.data.forEach(service => {
			if (service.id === name) currentService = service
		})
	}

	useLayoutEffect(load, [])

	const handleCTA = link => {
		window.open(link, '_blank', 'noopener, noreferrer')
	}

	return (
		<>
			<Header
				title={
					currentService?.name ?? (
						<Skeleton width='20%' height='50px' />
					)
				}
			/>
			<Container>
				<Card>
					{services.loading ? (
						<CardContent
							style={{
								display: 'flex',
								height: '150px',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Loader />
						</CardContent>
					) : (
						<>
							<CardMedia
								component='img'
								alt={currentService.name}
								height='400'
								image={currentService.logo ?? emptyImg}
							/>
							<CardContent>
								<Typography gutterBottom variant='h6'>
									{currentService.short_desc}
								</Typography>
								<Typography variant='body2'>
									{currentService.desc}
								</Typography>
							</CardContent>
							<Actions>
								<CustomButton
									title={currentService.cta}
									handleClick={() =>
										handleCTA(currentService.link)
									}
								/>
							</Actions>
						</>
					)}
				</Card>
			</Container>
		</>
	)
}

export default ServicePage

const Actions = styled(CardActions)`
	justify-content: flex-end;
	height: 50px;
`
