import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { getServicesAction } from 'store/actions/servicesActions'

export const getServices = createAsyncThunk('services/get', getServicesAction)

const initialState = {
	data: [],
	loading: false,
}

const servicesReducer = createReducer(initialState, {
	[getServices.pending]: (state, action) => {
		state.loading = true
	},
	[getServices.fulfilled]: (state, action) => {
		state.data = action.payload.data.services
		state.loading = false
	},
	[getServices.rejected]: (state, action) => {
		state.loading = false
	},
})

export default servicesReducer
