import styled from 'styled-components'
import * as MUI from '@material-ui/core'

/* GRID COMPONENTS */

export const Container = ({ children, ...rest }) => {
	const isXLScreen = MUI.useMediaQuery('(min-width: 1600px)')

	return (
		<StyledContainer maxWidth={isXLScreen ? 'xl' : 'lg'} {...rest}>
			{children}
		</StyledContainer>
	)
}

const StyledContainer = styled(MUI.Container)`
	height: ${props => props.height || 'auto'};
	${props => (props.overflow ? `overflow: ${props.overflow};` : null)}
	${props =>
		props.center === true &&
		`
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `}
    padding-top: 12px;
	padding-bottom: 12px;
`

export const Row = ({ children, ...rest }) => {
	return (
		<StyledRow className='mini-scroll' container {...rest}>
			{children}
		</StyledRow>
	)
}

const StyledRow = styled(MUI.Grid)`
	${props => (props.full ? 'overflow: visible;' : null)}
	${props => (props.flexWrap ? `flex-wrap: ${props.flexWrap};` : null)}
    height: ${props => (props.full ? '100%' : 'auto')};
	margin-top: ${props => props.marginTop ?? 0}px;
	margin-bottom: ${props => props.marginBottom ?? 0}px;
`

export const Col = ({ children, ...rest }) => {
	return (
		<StyledCol item {...rest}>
			{children}
		</StyledCol>
	)
}

const StyledCol = styled(MUI.Grid)`
	${props =>
		props.flexGrow
			? `
    flex-grow: ${props.flexGrow} !important;`
			: null}

	${props =>
		props.flexBasis
			? `
    flex-basis: ${props.flexBasis} !important;`
			: null}
`

/* UI COMPONENTS */

export const Header = ({ title, subtitle, bgColor }) => {
	const small = MUI.useMediaQuery(theme => theme.breakpoints.down('xs'))

	return (
		<Wrapper small={small} $bgColor={bgColor}>
			<Title small={small}>{title}</Title>
			{subtitle ? <Subtitle small={small}> {subtitle}</Subtitle> : null}
		</Wrapper>
	)
}

const Wrapper = styled.header`
	padding: ${props => (props.small ? 24 : 32)}px;
	transition: 0.5s;
	${props => (props.$bgColor ? 'background-color: ' + props.$bgColor : null)}
`

const Title = styled.span`
	font-size: ${props => (props.small ? 24 : 32)}px;
	color: white;
`

const Subtitle = styled.span`
	font-size: ${props => (props.small ? 16 : 24)}px;
	color: #d9d9d9;
`
