import React, { useState } from 'react'
import { MenuItem, Select, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Avatar from '../../components/UI/Avatar'
import {
	getChannelGeneral,
	getChannelPricings,
	getChannelYoutube,
	getChannelTwitch,
} from '../../store/reducers/analyticsReducer'
import { useHistory } from 'react-router'
import { talentChannels, talentOwnsChannel } from '../../helpers/talentHelper'
import FavoriteChannel from 'components/UI/FavoriteChannel'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { FaYoutube, FaTwitch, FaChartLine, FaDollarSign } from 'react-icons/fa'
import ChannelGeneral from 'components/analytics/ChannelGeneral'
import ChannelPricings from 'components/analytics/ChannelPricings'
import ChannelYoutube from 'components/analytics/ChannelYoutube'
import ChannelTwitch from 'components/analytics/ChannelTwitch'
import ShareWithBrand from 'components/UI/ShareWithBrand'
import { Container } from 'components/Layout'

const useStyles = makeStyles({
	select: {
		'&:before': {
			borderColor: 'transparent',
		},
		'&:after': {
			borderColor: 'transparent',
		},
		'&:not(.Mui-disabled):hover::before': {
			borderColor: 'transparent',
		},
	},
	option: {
		fontSize: 14,
	},
	icon: {
		fill: 'white',
	},
	root: {
		color: 'white',
	},
})

const ChannelPage = ({ location }) => {
	const { id, twitch_channel_id, display_name, banner, logo, handle } =
		location.state.channel
	const classes = useStyles()
	const { t } = useTranslation()
	const history = useHistory()

	const dispatch = useDispatch()
	const { data: talent } = useSelector(state => state.talent)
	const { loading, data: general } = useSelector(
		state => state.analytics.channels.general
	)

	const { loading: pricingsLoading, data: pricings } = useSelector(
		state => state.analytics.channels.pricings
	)

	const { loading: youtubeLoading, data: youtube } = useSelector(
		state => state.analytics.channels.youtube
	)

	const { loading: twitchLoading, data: twitch } = useSelector(
		state => state.analytics.channels.twitch
	)

	const [headerBgColor, setHeaderBgColor] = useState('#1976D2')

	const load = () => {
		if (!general[id] && !loading) {
			dispatch(getChannelGeneral({ channelId: id }))
		}

		if (!pricings[id] && !pricingsLoading) {
			dispatch(getChannelPricings({ channelId: id }))
		}

		if (!youtube[id] && !youtubeLoading) {
			dispatch(getChannelYoutube({ channelId: id }))
		}

		if (twitch_channel_id && !youtube[id] && !youtubeLoading) {
			dispatch(getChannelTwitch({ channelId: id }))
		}
	}

	React.useLayoutEffect(load, [id])

	const handleChannelChange = e => {
		const channel = talentChannels().find(
			channel => channel.id === e.target.value
		)
		history.push('/analytics/mychannel', { channel })
	}

	const renderCurrentChannel = id => {
		const channel = talentChannels().find(channel => channel.id === id)
		return (
			<OptionWrapper>
				<Avatar size={50} src={channel.logo} />
				<TitleWrapper>
					<Title $light>{channel.display_name}</Title>
					{channel.handle ? (<Handle>{'@'+channel.handle}</Handle>) : null}
				</TitleWrapper>
			</OptionWrapper>
		)
	}

	const handleTabChange = color => {
		setHeaderBgColor(color)
	}

	return (
		<>
			<Banner background={banner}>
				{(talentOwnsChannel(talent, id) ||
					talentChannels().filter(channel => channel.id === id)
						.length > 0) &&
				talentChannels().length > 1 ? (
					<>
						<Select
							className={classes.select}
							inputProps={{
								classes: {
									icon: classes.icon,
									root: classes.root,
								},
							}}
							value={id}
							onChange={handleChannelChange}
							renderValue={renderCurrentChannel}
						>
							{talentChannels().map(channel => (
								<ChannelOption
									value={channel.id}
									key={channel.id}
								>
									<OptionWrapper>
										<Avatar size={30} src={channel.logo} />
										<Title $small>
											{channel.display_name}
										</Title>
									</OptionWrapper>
								</ChannelOption>
							))}
						</Select>
						{talentOwnsChannel(talent, id) ? (
							// <ShareWithBrand
							// 	id={id}
							// 	displayName={display_name}
							// />
							<></>
						) : (
							<FavoriteChannel
								talentId={talent.id}
								channelId={id}
							/>
						)}
					</>
				) : (
					<OptionWrapper>
						<Avatar size={50} src={logo} />
						<TitleWrapper>
								<Title $light>{display_name}</Title>
								{handle ? (<Handle>{'@'+handle}</Handle>) : null}
						</TitleWrapper>
						<FavoriteChannel talentId={talent.id} channelId={id} />
					</OptionWrapper>
				)}
			</Banner>

			{/* TABS */}
			<Tabs>
				<TabListCustom $bgColor={headerBgColor}>
					<Tab
						value='general'
						onClick={() => handleTabChange('#1976d2')}
					>
						<TabIconTitle>
							<FaChartLine />
							<span>{t('channel.tabs.general')}</span>
						</TabIconTitle>
					</Tab>
					<Tab
						value='pricings'
						onClick={() => handleTabChange('#1976d2')}
					>
						<TabIconTitle>
							<FaDollarSign />
							<span>{t('channel.tabs.pricings')}</span>
						</TabIconTitle>
					</Tab>
					<Tab
						value='youtube'
						onClick={() => handleTabChange('#aa181e')}
					>
						<TabIconTitle>
							<FaYoutube />
							<span>{t('channel.tabs.youtube')}</span>
						</TabIconTitle>
					</Tab>
					{twitch_channel_id && (
						<Tab
							value='twitch'
							onClick={() => handleTabChange('#6441a4')}
						>
							<TabIconTitle>
								<FaTwitch />
								<span>{t('channel.tabs.twitch')}</span>
							</TabIconTitle>
						</Tab>
					)}
				</TabListCustom>

				<Container>
					<TabPanel value='general'>
						<ChannelGeneral
							isLoading={loading}
							data={general[id]?.general}
							talentOwnsChannel={talentOwnsChannel(talent, id)}
						/>
					</TabPanel>
					<TabPanel value='pricings'>
						<ChannelPricings
							isLoading={pricingsLoading}
							data={pricings[id]?.pricings}
							hasTwitchChannel={twitch_channel_id}
							talentOwnsChannel={talentOwnsChannel(talent, id)}
						/>
					</TabPanel>
					<TabPanel value='youtube'>
						<ChannelYoutube
							isLoading={youtubeLoading}
							data={youtube[id]?.youtube}
							talentOwnsChannel={talentOwnsChannel(talent, id)}
						/>
					</TabPanel>
					{twitch_channel_id && (
						<TabPanel value='twitch'>
							<ChannelTwitch
								isLoading={twitchLoading}
								data={twitch[id]?.twitch}
							/>
						</TabPanel>
					)}
				</Container>
			</Tabs>
		</>
	)
}

export default ChannelPage

const Banner = styled.div`
	display: flex;
	padding: 32px;
	justify-content: flex-start;
	align-items: center;
	background-image: linear-gradient(
			180deg,
			rgba(20, 20, 20, 0) 0%,
			rgba(20, 20, 20, 0.75) 0.01%
		),
		url('${props => props.background}');
	background-position: center;
	background-size: cover;
`

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const Title = styled(Typography)`
	padding: 0 18px;
	font-size: ${props => (props.$small ? 18 : 24)}px !important;
	font-weight: 500;
	${props => (props.$light ? 'color: white;' : null)};
`

const Handle = styled.div`
	padding: 0 18px;
	font-size: 14px;
	color: #9e9e9e;
`

const ChannelOption = styled(MenuItem)`
	display: flex;
`

const OptionWrapper = styled.div`
	display: flex;
	align-items: center;
`

const TabListCustom = styled(TabList)`
	background-color: ${props => (props.$bgColor ? props.$bgColor : '#1976D2')};
	margin: 0;
	padding: 0;
	transition: 0.5s;

	@media (max-width: 450px) {
		display: flex;
		justify-content: space-around;

		& .react-tabs__tab {
			width: 100%;
		}
	}
`

const TabIconTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;

	@media (max-width: 450px) {
		justify-content: center;

		& span {
			display: none;
		}
	}
`
