import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import {
    getDealsAction,
    getDealAction,
    manageDealAction,
} from '../actions/dealsActions'

export const getDeals = createAsyncThunk('deals/get', getDealsAction)
export const getDeal = createAsyncThunk('deal/get', getDealAction)
export const manageDeal = createAsyncThunk('deal/manage', manageDealAction)

const initialState = {
    search: {
        sent: {
            error: null,
            loading: false,
            pagination: {
                page: null,
                nextPage: null,
                pageCount: null,
            },
            results: {},
        },
        approved: {
            error: null,
            loading: false,
            pagination: {
                page: null,
                nextPage: null,
                pageCount: null,
            },
            results: {},
        },
        in_progress: {
            error: null,
            loading: false,
            pagination: {
                page: null,
                nextPage: null,
                pageCount: null,
            },
            results: {},
        },
        done: {
            error: null,
            loading: false,
            pagination: {
                page: null,
                nextPage: null,
                pageCount: null,
            },
            results: {},
        },
    },
    data: {},
}

const dealsReducer = createReducer(initialState, {
    [getDeals.pending]: (state, action) => {
        const { status, reload } = action.meta.arg
        state.search[status].error = null
        state.search[status].loading = true
        if (reload) state.search[status].results = {}
    },
    [getDeals.fulfilled]: (state, action) => {
        // Récupération des paramètres passés lors du dispatch de getDeals
        const { status, page = 1 } = action.meta.arg

        try {
            // Récupération des données dans la réponse de l'API
            const {
                deals,
                params
            } = action.payload.data
    
            // On stocke chaque deal state.data avec comme propriété l'id du deal
            for (const deal of deals) {
                state.data[deal.id] = deal
            }
    
            // On stocke les résultats de recherche dans l'objet correspondant au statut et dans la bonne page
            state.search[status].results[page] = deals
            state.search[status].pagination = {
                page,
                nextPage: params?.nextPage,
                pageCount: params?.pageCount,
            }
        } catch (error) {
            console.log(`error.message`, error.message)
            state.search[status].error = error    
        }

        state.search[status].loading = false
    },
    [getDeals.rejected]: (state, action) => {
        const { status } = action.meta.arg
        state.search[status].loading = false
    },

    [getDeal.pending]: (state, action) => {
        const { id } = action.meta.arg
        state.data[id] = {
            loading: true
        }
    },
    [getDeal.fulfilled]: (state, action) => {
        const { id } = action.meta.arg
        const { deal } = action.payload
        state.data[id] = {
            ...deal,
            loading: false
        }
    },
    [getDeal.rejected]: (state, action) => {
        const { id } = action.meta.arg
        state.data[id].loading = false
    },
})

export default dealsReducer
