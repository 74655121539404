import moment from 'moment'
import { translate } from '../i18n/i18n'
import {
    FaCheck,
    FaFile,
    FaQuestion,
    FaRegCopyright,
    FaTimes,
} from 'react-icons/fa'
import { IconButton, Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import CustomBadge from '../components/UI/CustomBadge'

export default class Strike {
    constructor(strike) {
        this.video = {
            title: strike.video_title,
            url: strike.url_video,
            thumbnail: strike.video_thumbnail,
        }
        this.video_url = strike.url_video
        this.type = strike.type
        this.nature = strike.strike_nature
        this.solve_type = strike.solve_type
        this.date = moment(strike.strike_date)
        this.solve_date = moment(strike.solve_date)
        this.negociable = !strike.not_negotiable
    }

    static STATUS_RESOLVED = 'resolved'
    static STATUS_RECEIVED = 'received'
    static STATUS_IN_PROGRESS = 'in_progress'

    static BACKGROUND_IN_PROGRESS = '#b3e5fc'
    static BACKGROUND_RESOLVED = '#c8e6c9'
    static BACKGROUND_RECEIVED = '#ffecb3'

    static COLOR_IN_PROGRESS = '#03a9f4'
    static COLOR_RESOLVED = '#4caf50'
    static COLOR_RECEIVED = '#ffc107'

    static TYPE_COPYRIGHT = 'copyright'
    static TYPE_COMMUNITY_GUIDELINES = 'community_guidelines'

    static NATURE_LEGIT = 'legit'
    static NATURE_FAIR_USE = 'fair_use'
    static NATURE_ILLEGIT = 'illegit'
    static NATURE_SPECIAL_CASE = 'special_case'

    static SOLVE_TYPE_AUTO = 'auto'
    static SOLVE_TYPE_COMPANY = 'company'
    static SOLVE_TYPE_PARTNER = 'partner'
    static SOLVE_TYPE_COMPANY_DETACHMENT = 'company_detachment'
    static SOLVE_TYPE_YT_REMOVAL = 'yt_removal'
    static SOLVE_TYPE_YT_DISSOCIATION = 'yt_dissociation'

    static LABELS = {
        status: {
            [this.STATUS_IN_PROGRESS]: translate('strikes.status.in_progress'),
            [this.STATUS_RECEIVED]: translate('strikes.status.received'),
            [this.STATUS_RESOLVED]: translate('strikes.status.resolved'),
        },
        types: {
            [this.TYPE_COPYRIGHT]: translate('strikes.types.copyright'),
            [this.TYPE_COMMUNITY_GUIDELINES]: translate(
                'strikes.types.community_guidelines'
            ),
        },
        natures: {
            [this.NATURE_LEGIT]: translate('strikes.natures.legit'),
            [this.NATURE_FAIR_USE]: translate('strikes.natures.fair_use'),
            [this.NATURE_ILLEGIT]: translate('strikes.natures.illegit'),
            [this.NATURE_SPECIAL_CASE]: translate('strikes.natures.special_case'),
        },
        solve_types: {
            [this.SOLVE_TYPE_AUTO]: translate('strikes.solve_types.auto'),
            [this.SOLVE_TYPE_COMPANY]: translate('strikes.solve_types.company'),
            [this.SOLVE_TYPE_PARTNER]: translate('strikes.solve_types.partner'),
            [this.SOLVE_TYPE_COMPANY_DETACHMENT]: translate(
                'strikes.solve_types.company_detachment'
            ),
            [this.SOLVE_TYPE_YT_REMOVAL]: translate(
                'strikes.solve_types.yt_removal'
            ),
            [this.SOLVE_TYPE_YT_DISSOCIATION]: translate(
                'strikes.solve_types.yt_dissociation'
            ),
        },
    }

    static COLUMNS = [
        translate('strikes.table.video'),
        translate('strikes.table.type'),
        translate('strikes.table.nature'),
        translate('strikes.table.date'),
        translate('strikes.table.solve_date'),
        translate('strikes.table.solve_type'),
        translate('strikes.table.solve_length'),
        translate('strikes.table.negociable'),
        translate('strikes.table.status'),
    ]

    get solve_length() {
        return this.solve_date.diff(this.date, 'days')
    }

    get status() {
        if (moment(this.solve_date).isValid()) {
            const today = moment()
            if (today.isAfter(this.solve_date)) {
                return this.constructor.STATUS_RESOLVED
            } else {
                return this.constructor.STATUS_IN_PROGRESS
            }
        } else {
            return this.constructor.STATUS_RECEIVED
        }
    }

    get backgroundColor() {
        switch (this.status) {
            case this.constructor.STATUS_IN_PROGRESS:
                return this.constructor.BACKGROUND_IN_PROGRESS
            case this.constructor.STATUS_RECEIVED:
                return this.constructor.BACKGROUND_RECEIVED
            case this.constructor.STATUS_RESOLVED:
                return this.constructor.BACKGROUND_RESOLVED
            default:
                return 'white'
        }
    }

    get _type() {
        let Icon = FaRegCopyright

        if (this.type === this.constructor.TYPE_COMMUNITY_GUIDELINES)
            Icon = FaFile

        return (
            <StyledTooltip arrow title={this.constructor.LABELS.types[this.type]}>
                <IconButton aria-label='delete'>
                    <Icon color='red' size={18} />
                </IconButton>
            </StyledTooltip>
        )
    }

    get _nature() {
        if (!this.nature) return null

        let color = 'black'
        let Icon = FaCheck

        switch (this.nature) {
            case this.constructor.NATURE_LEGIT:
                color = 'green'
                break
            case this.constructor.NATURE_FAIR_USE:
                color = 'orange'
                break
            case this.constructor.NATURE_ILLEGIT:
                Icon = FaTimes
                color = 'red'
                break
            case this.constructor.NATURE_SPECIAL_CASE:
                Icon = FaQuestion
                color = 'black'
                break
			  default:
        }

        return (
            <StyledTooltip arrow title={this.constructor.LABELS.natures[this.nature]}>
                <IconButton aria-label='delete'>
                    <Icon color={color} size={18} />
                </IconButton>
            </StyledTooltip>
        )
    }

    get _solve_type() {
        return this.constructor.LABELS.solve_types[this.solve_type]
    }

    get _negociable() {
        return this.negociable ? translate('common.yes') : translate('common.no')
    }

    get _status() {
        let backgroundColor

        switch (this.status) {
            case this.constructor.STATUS_IN_PROGRESS:
                backgroundColor = this.constructor.COLOR_IN_PROGRESS
                break
            case this.constructor.STATUS_RECEIVED:
                backgroundColor = this.constructor.COLOR_RECEIVED
                break
            case this.constructor.STATUS_RESOLVED:
                backgroundColor = this.constructor.COLOR_RESOLVED
                break
            default:
                backgroundColor = 'grey'
        }

        return (
            <CustomBadge
                backgroundColor={backgroundColor}
                value={this.constructor.LABELS.status[this.status]}
            />
        )
    }

    get _video() {
        return (
            <a target='_blank' href={this.video_url} rel="noreferrer">
                {this.video}
            </a>
        )
    }

    get row() {
        return {
            style: {
                backgroundColor: this.backgroundColor,
            },
            cells: [
                this._video,
                this._type,
                this._nature,
                this.date.format('LL'),
                this.solve_date.format('LL'),
                this._solve_type,
                `${this.solve_length} ${translate('common.days')}`,
                this._negociable,
                this._status,
            ],
        }
    }
}

const StyledTooltip = styled(Tooltip)`
    cursor: default;
`
