import {
	Card,
	CardContent,
	IconButton,
	LinearProgress,
	Tooltip,
	Typography,
} from '@material-ui/core'
import Loader from 'components/UI/Loader'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import VideosCarousel from './VideosCarousel'
import BrandsCarousel from 'components/brands/BrandsCarousel'
import { Col, Row } from 'components/Layout'
import WordCloud from './WordCloud'
import { FaInfoCircle } from 'react-icons/fa'
import NoData from 'components/UI/NoData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudWord, faGamepadModern } from '@fortawesome/pro-solid-svg-icons'
import { shorten } from '../../helpers/numbersHelper'
import Engagement from 'components/UI/Engagement'
import emptyImg from '../../assets/img/empty-img.png'

const ChannelYoutube = ({ isLoading, data, talentOwnsChannel }) => {
	const { t } = useTranslation()

	const handleBecomeAmbassador = () => {
		window.location = `mailto:${
			process.env.REACT_APP_WIZDEO_EMAIL
		}?subject=${t('channel.cta.become_ambassador_email_subject')}&body=${
			t('channel.cta.become_ambassador_email_body') +
			' ' +
			data.display_name +
			' (#' +
			data.id +
			') ' +
			t('common.to') +
			' :'
		}`
	}

	const getEngagementColSize = () => {
		return data && data['average_30d_future_views_shorts'] ? 4 : 6;
	}

	return isLoading ? (
		<Loader />
	) : (
		<>
			<SectionTitle>{t('channel.sections.collaborations')}</SectionTitle>
			<Typography variant='subtitle2'>
				{t('channel.sections.latest_sponsored_videos')}
			</Typography>
			<div className='mb-20'>
				<VideosCarousel videos={data?.sponsored_videos} />
			</div>

			{/* {!talentOwnsChannel && data?.brands ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Typography variant='subtitle2'>
						{t('channel.sections.brands')}
					</Typography>

					<CustomButton
						type='primary'
						title={t('channel.cta.become_ambassador')}
						handleClick={() => handleBecomeAmbassador()}
					/>
				</div>
			) : ( */}
			<Typography variant='subtitle2'>
				{t('channel.sections.brands')}
			</Typography>
			{/* )} */}

			<div className='mb-20'>
				<BrandsCarousel brands={data?.brands} />
			</div>

			<Row spacing={2}>
				<Col xs={12} xl={6}>
					<SectionTitle>
						{t('channel.sections.engagement')}
					</SectionTitle>
					<Card className='mb-20' style={{ minHeight: '115px' }}>
						<CardContent>
							<Row spacing={2} style={{ textAlign: 'right' }}>
								<Col xs={getEngagementColSize()} xl={getEngagementColSize()} style={{ padding: 0 }}>
									<dl>
										<dt>
											{t(
												'channel.kpis.30d_best_engagement_rate'
											)}
											<Tooltip
												title={t(
													'channel.kpis.30d_best_engagement_rate_tooltip'
												)}
												arrow
											>
												<sup>
													<FaInfoCircle />
												</sup>
											</Tooltip>
										</dt>
										<dd>
											<Engagement
												engagement={
													data &&
													data[
														'30d_best_engagement_rate'
													]
														? data[
																'30d_best_engagement_rate'
														  ]
														: null
												}
											/>
										</dd>
									</dl>
								</Col>
								<Col xs={getEngagementColSize()} xl={getEngagementColSize()} style={{ padding: 0 }}>
									<dl className='mb-10'>
										<dt>
											{t(
												'channel.kpis.average_30d_future_views'
											)}
											<Tooltip
												title={t(
													'channel.kpis.average_30d_future_views_tooltip'
												)}
												arrow
											>
												<sup>
													<FaInfoCircle />
												</sup>
											</Tooltip>
										</dt>
										<dd>
											{data &&
											data['average_30d_future_views']
												? shorten(
														data[
															'average_30d_future_views'
														],
														1
												  )
												: '-'}
										</dd>
									</dl>
								</Col>

								{data && data['average_30d_future_views_shorts'] ? (
									<Col xs={4} xl={4} style={{ padding: 0 }}>
										<dl className='mb-10'>
											<dt>
												{t(
													'channel.kpis.average_30d_future_views_shorts'
												)}
												<Tooltip
													title={t(
														'channel.kpis.average_30d_future_views_shorts_tooltip'
													)}
													arrow
												>
													<sup>
														<FaInfoCircle />
													</sup>
												</Tooltip>
											</dt>
											<dd>
												{data &&
												data['average_30d_future_views_shorts']
													? shorten(
															data[
																'average_30d_future_views_shorts'
															],
															1
													)
													: '-'}
											</dd>
										</dl>
									</Col>
								) : null}
							</Row>
						</CardContent>
					</Card>

					<SectionTitleWrapper noMargin>
						<SectionTitle>
							{t('channel.sections.wordcloud')}
							<Tooltip
								arrow
								title={t('channel.tooltips.wordcloud')}
							>
								<IconButton aria-label='delete'>
									<FaInfoCircle size={14} />
								</IconButton>
							</Tooltip>
						</SectionTitle>
					</SectionTitleWrapper>
					{data?.themes && data.themes.length ? (
						<WordCloud words={data.themes} />
					) : (
						<Card>
							<CardContent>
								<NoData
									icon={
										<FontAwesomeIcon icon={faCloudWord} />
									}
								/>
							</CardContent>
						</Card>
					)}
				</Col>
				<Col xs={12} xl={6}>
					<SectionTitle>{t('channel.sections.games')}</SectionTitle>
					{data?.games && data.games.length ? (
						<Card>
							<GamesContainer>
								{data.games.map(game => (
									<GameWrapper key={game.id}>
										<GameNameWrapper xs={8}>
											<GameLogoWrapper>
												<GameLogo
													src={game.logo}
													alt={game.name}
													onError={e => {
														e.target.onerror = null
														e.target.src = emptyImg
													}}
												/>
											</GameLogoWrapper>
											<GameName>{game.name}</GameName>
										</GameNameWrapper>
										<GamePercentage xs={4}>
											{shorten(game.percentage, 1)}%
										</GamePercentage>
										<LinearProgress
											style={{
												position: 'absolute',
												left: '0',
												bottom: '0',
												width: '100%',
											}}
											variant='determinate'
											value={shorten(game.percentage)}
										/>
									</GameWrapper>
								))}
							</GamesContainer>
						</Card>
					) : (
						<GamesContainer $empty>
							<CardContent>
								<NoData
									icon={
										<FontAwesomeIcon
											icon={faGamepadModern}
										/>
									}
								/>
							</CardContent>
						</GamesContainer>
					)}
				</Col>
			</Row>
		</>
	)
}

export default ChannelYoutube

const SectionTitleWrapper = styled.div`
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const SectionTitle = styled(Typography)`
	font-weight: bold !important;
	display: flex;
	align-items: center;
	min-height: 40px;
`

const GamesContainer = styled(Card)`
	overflow: auto;
	max-height: 375px;

	${props =>
		props.$empty &&
		'display: flex; align-items: center; justify-content: center;'}
`

const GameWrapper = styled(Col)`
	position: relative;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 6px 12px 9px 12px;
`

const GameNameWrapper = styled(Col)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

const GameLogoWrapper = styled.div`
	height: 30px;
	width: 30px;
	margin-right: 5px;
`

const GameLogo = styled.img`
	height: 30px;
	width: 30px;
	border-radius: 50%;
`

const GameName = styled.div`
	white-space: nowrap;
	font-size: 0.9em;
	overflow: hidden;
	text-overflow: ellipsis;
`

const GamePercentage = styled(Col)`
	font-weight: bold;
	text-align: right;
`
