import React from 'react'
import styled from 'styled-components'
import { shorten } from '../../helpers/numbersHelper'
import { Card, CardContent, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	FaFacebook,
	FaInstagram,
	FaTwitch,
	FaTwitter,
	FaYoutube,
} from 'react-icons/fa'

const SocialNetWorkCard = ({ name, data }) => {
	const { t } = useTranslation()

	let iconContent = null,
		bgColor = null,
		helpText = null

	switch (name) {
		case 'facebook':
			name = t('common.facebook')
			bgColor = '#3a589e'
			iconContent = <FaFacebook />
			helpText = t('channel.kpis.followers_facebook')
			break

		case 'instagram':
			name = t('common.instagram')
			bgColor = '#c2185b'
			iconContent = <FaInstagram />
			helpText = t('channel.kpis.followers_instagram')
			break

		case 'twitter':
			name = t('common.twitter')
			bgColor = '#2b84b4'
			iconContent = <FaTwitter />
			helpText = t('channel.kpis.followers_twitter')
			break

		case 'twitch':
			name = t('common.twitch')
			bgColor = '#6441a4'
			iconContent = <FaTwitch />
			helpText = t('channel.kpis.twitch_followers')
			break

		default:
			name = t('common.youtube')
			bgColor = '#aa181e'
			iconContent = <FaYoutube />
			helpText = t('channel.kpis.subscribers')
	}

	return (
		data !== null && (
			<SocialNetWorksCard $bgColor={bgColor}>
				<CardIcon>{iconContent}</CardIcon>
				<CardContent>
					<NetworkName>{name}</NetworkName>
					<p>
						{shorten(data, 1)}
						<br />
						<span>{helpText}</span>
					</p>
				</CardContent>
			</SocialNetWorksCard>
		)
	)
}

export default SocialNetWorkCard

const SocialNetWorksCard = styled(Card)`
	position: relative;
	color: white;
	background-color: ${props => props?.$bgColor};
	height: 150px;
	width: 20%;
	text-align: center;
	font-size: 2em;
	line-height: 1;
	font-weight: bold;
	&:not(:first-child) {
		margin-left: 20px;
	}
	& span {
		font-weight: normal;
		font-size: 0.7em;
	}
	@media (max-width: 1100px) {
		width: 48%;
		&:not(:first-child) {
			margin-left: 0;
			margin-top: 20px;
		}
		&:nth-child(2n) {
			margin-left: 20px;
		}
		&:nth-child(2) {
			margin-top: 0;
		}
	}
	@media (max-width: 531px) {
		width: 100%;
		&:not(:first-child) {
			margin-left: 0;
			margin-top: 20px;
		}
	}
`

const NetworkName = styled(Typography)`
	float: right;
	top: 0;
	right: 0;
	margin-top: -10px;
	font-size: 0.75rem;
	color: lightgrey;
`

const CardIcon = styled.div`
	position: absolute;
	font-size: 8rem;
	opacity: 0.2;
	top: -20px;
	left: -20px;
`
