import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Col, Row } from '../Layout'
import styled from 'styled-components'

const Filters = ({ children, justifyContent = 'flex-start' }) => {
    const { t } = useTranslation()
    const screenIsSmall = useMediaQuery((theme) => theme.breakpoints.down('md'))

    return (
        <Wrapper>
            <Row	justifyContent={screenIsSmall ? 'center' : justifyContent}
                	spacing={0} >
                {children}
            </Row>
        </Wrapper>
    )
}

export default Filters

const Wrapper = styled.div`
    margin-bottom: 12px;
`
