import { Divider, Typography } from '@material-ui/core'
import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Redux from 'react-redux'
import { useHistory } from 'react-router'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import ResultCard from '../../components/analytics/ResultCard'
import { Col, Container, Header, Row } from '../../components/Layout'
import Loader from '../../components/UI/Loader'
import Paginator from '../../components/UI/Paginator'
import SearchBar from '../../components/UI/SearchBar'
import { searchChannels } from '../../store/reducers/analyticsReducer'

const SearchPage = () => {
	const params = {
		q: useSearchParam('q'),
		page: useSearchParam('page'),
	}

	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = Redux.useDispatch()

	const {
		results,
		loading,
		pagination: { pageCount },
	} = Redux.useSelector(state => state.analytics.search)

	const [loaded, setLoaded] = React.useState(false)
	const [keywords, setKeywords] = React.useState(params.q ?? '')
	const [page, setPage] = React.useState(params.page)

	// Lancer une recherche
	const search = (page = 1, force = false) => {
		if ((!results[page] || force) && keywords && !loading) {
			setPage(page)
			updateParams(keywords, page)
			if (!loaded) setLoaded(true)
			dispatch(searchChannels({ keywords, page }))
		}
	}

	// Vérifie au chargement de la page si il y a un mot clé et lance une recherche si nécessaire
	React.useLayoutEffect(() => {
		if (keywords) search(page ?? 1, true)
	}, [])

	// Relance une recherche à chaque changement de page
	React.useEffect(() => {
		if (page) search(page)
	}, [page])

	const handleSubmit = () => search(1, true)
	const handleReset = () => setKeywords('')
	const handleChange = e => setKeywords(e.target.value)
	const handlePageChange = (e, value) => setPage(value)
	const updateParams = (q, page) =>
		history.replace({ search: `${qs.stringify({ q, page })}` })

	const paginator = Object.keys(results).length ? (
		<Col xs={12} md={6} lg={9}>
			<PaginatorWrapper>
				<Paginator
					count={pageCount}
					page={page}
					onPageChange={handlePageChange}
				/>
			</PaginatorWrapper>
		</Col>
	) : null

	let content

	if (results[page]) {
		content = (
			<Row spacing={2}>
				{results[page].map(result => (
					<Col xs={12} lg={6} xl={4} key={result.id}>
						<ResultCard channel={result} />
					</Col>
				))}
			</Row>
		)
	}

	if (!loading && !results[page]) {
		content = (
			<EmptyMessage variant='body2' align='center'>
				{loaded ? t('search.no_results') : t('search.message')}
			</EmptyMessage>
		)
	}

	if (loading) {
		content = <Loader />
	}

	return (
		<>
			<Header title='Recherche de chaînes' />
			<Container>
				<Row spacing={2}>
					<Col xs={12} md={6} lg={3}>
						<SearchBar
							value={keywords}
							onChange={handleChange}
							onReset={handleReset}
							onSubmit={handleSubmit}
							autoCompleteUrl='channels/autocomplete/1.json'
							type='channels'
						/>
					</Col>
					{paginator}
					<Col xs={12}>
						<Divider light orientation='horizontal' />
					</Col>
				</Row>
				{content}
				<Row spacing={2} justifyContent='flex-end'>
					{paginator}
				</Row>
			</Container>
		</>
	)
}

export default SearchPage

const PaginatorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`

const EmptyMessage = styled(Typography)`
	padding: 24px;
`
