import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardContent } from '@material-ui/core'
import { Col, Row } from 'components/Layout'
import NoData from 'components/UI/NoData'
import React from 'react'
import styled from 'styled-components'
import { shorten } from '../../helpers/numbersHelper'
import { faEarthEurope } from '@fortawesome/pro-solid-svg-icons'

const ChannelGeo = ({ data }) => {
	return data && Object.keys(data).length > 0 ? (
		<CountriesContainer>
			{data.map(details => (
				<CountryWrapper key={details.country_code}>
					<Row>
						<Col xs={8}>
							<CountryName>{details.country_name}</CountryName>
						</Col>
						<CountryPercentage xs={4}>
							{shorten(details.percentage, 1)}%
						</CountryPercentage>
					</Row>
				</CountryWrapper>
			))}
		</CountriesContainer>
	) : (
		<CountriesContainer $empty>
			<CardContent>
				<NoData icon={<FontAwesomeIcon icon={faEarthEurope} />} />
			</CardContent>
		</CountriesContainer>
	)
}

export default ChannelGeo

const CountriesContainer = styled(Card)`
	overflow: auto;
	// max-height: 428px;
	min-height: 242px;
	max-height: 444px;

	${props =>
		props.$empty &&
		'display: flex; align-items: center; justify-content: center;'}
`

const CountryWrapper = styled.div`
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 6px 12px;
`

const CountryName = styled.span`
	white-space: nowrap;
	font-size: 0.9em;
`

const CountryPercentage = styled(Col)`
	font-weight: bold;
	text-align: right;
`
