import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { useDispatch, useSelector } from 'react-redux'
import {
    getRefusalReasonsOptions,
    REFUSAL_REASONS,
} from '../../../helpers/dealsHelper'
import { manageDeal } from '../../../store/reducers/dealsReducer'
import CustomSelect from '../../UI/CustomSelect'
import { Button, TextField, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { useCookie } from 'react-use'

const DenyModal = ({ deal, isOpen, onRequestClose }) => {
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('lg'))
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { trackEvent, pushInstruction } = useMatomo()
	const { data: talent } = useSelector((state) => state.talent)
	const [languageCookie] = useCookie('lang')

	const [dealRefusedReason, setDealRefusedReason] = React.useState(null)
	const [dealRefusedReasonOther, setDealRefusedReasonOther] =
		React.useState('')

	const handleChange = (e) => setDealRefusedReasonOther(e.target.value)

	const declineDeal = () => {
		if (window.confirm(t('deals.modal.deny.prompt'))) {
			let payload = {
					dealAccept: false,
					dealId: deal.id,
					previousStatus: deal.status,
					newStatus: 'done',
					dealRefusedReason,
			}

			if (dealRefusedReason === REFUSAL_REASONS.other) {
					payload = { ...payload, dealRefusedReasonOther }
			}

			dispatch(manageDeal(payload))

			// Story #2550 Matomo integration
			let event = {
				category: 'Brand Content',
				action: 'Deal refused',
				name: deal.campaign.title,
				value: deal.id,
				customDimensions: [
					{
						id: 1,
						value: languageCookie
					},
					{
						id: 2,
						value : process.env.REACT_APP_VERSION
					}
				]
			}

			pushInstruction('setUserId', talent.id)
			trackEvent(event)
		}
	}

	const options = getRefusalReasonsOptions()

	const disabled =
		dealRefusedReason === REFUSAL_REASONS.other &&
		dealRefusedReasonOther === ''

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			title={t('deals.modal.deny.title', { campaign: deal?.campaign?.title })}
			description={t('deals.modal.deny.description')}
			actions={
					<Button
						color='primary'
						variant='contained'
						onClick={declineDeal}
						disabled={disabled}
					>
						{t('common.decline')}
					</Button>
			}
		>
			<CustomSelect
					full={isSmallScreen}
					fontSize={16}
					options={options}
					value={dealRefusedReason}
					onChange={(item) => setDealRefusedReason(item.value)}
					defaultValue={options[0]}
			/>
			{dealRefusedReason === REFUSAL_REASONS.other && (
					<Input
						value={dealRefusedReasonOther}
						onChange={handleChange}
						label={t('deals.modal.deny.other_label')}
						placeholder={t('deals.modal.deny.other_label')}
						multiline
					/>
			)}
		</Modal>
	)
}

export default DenyModal

const Input = styled(TextField)`
    width: 100%;
    margin: 16px 0 0;
`
