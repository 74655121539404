import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Header from '../../components/layout/Header'
import TasksView from '../../components/tasks/TasksView'

const TasksPage = () => {
    const { t } = useTranslation()
    return (
        <>
            <Header title='Tâches' />
            <Tabs>
                <TabList>
                    <Tab>{t('tasks.status.in_progress')}</Tab>
                    <Tab>{t('tasks.status.checking')}</Tab>
                    <Tab>{t('tasks.status.done')}</Tab>
                </TabList>
                <div className='container-large'>
                    <TabPanel>
                        <TasksView status='in_progress' />
                    </TabPanel>
                    <TabPanel>
                        <TasksView status='checking' />
                    </TabPanel>
                    <TabPanel>
                        <TasksView status='done' />
                    </TabPanel>
                </div>
            </Tabs>
        </>
    )
}

export default TasksPage
