import React from 'react'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import HomeCard from './HomeCard'
import CustomCarousel from 'components/UI/CustomCarousel'
import classes from './styles/ChannelCard.module.scss'
import emptyImg from 'assets/img/empty-img.png'
import { FaTicketAlt } from 'react-icons/fa'
import Loader from '../UI/Loader'
import NoData from 'components/UI/NoData'

const ServicesCarousel = ({ services, isLoading }) => {
	const history = useHistory()

	const handleClick = service => {
		history.push({
			pathname: `/service/${service.id}`,
			state: { service: service },
		})
	}

	const slides = services.length
		? services.map(service => (
				<div className={`${classes.container}`}>
					<div
						className={`clickable ${classes.header}`}
						onClick={() => handleClick(service)}
						style={{
							height: '400px',
							backgroundImage: `url('${
								service.logo ?? emptyImg
							}')`,
						}}
					></div>
					<div style={{ padding: '10px' }}>
						<Typography>{service.name}</Typography>
						<Typography variant='body2'>
							{service.short_desc}
						</Typography>
					</div>
				</div>
		  ))
		: null

	return (
		<HomeCard title='Services' icon={FaTicketAlt}>
			{isLoading ? (
				<div className={classes.container} style={{ height: '150px' }}>
					<Loader />
				</div>
			) : (
				<>
					{slides ? (
						<CustomCarousel auto infinite slides={slides} />
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								height: '150px',
							}}
						>
							<NoData icon={<FaTicketAlt />} />
						</div>
					)}
				</>
			)}
		</HomeCard>
	)
}

export default ServicesCarousel
